export enum GoogleEvents {
  IMPROVEMENT_CALC = 'improvement_calc',
  USE_IMPROVEMENT_CALC = 'use_improvement_calc',
  QUICK_QUESTION = 'quick_question',
  SEND_QUESTION = 'send_question',
  REQUEST_AVM = 'request_avm',
  SELL_TAB = 'sell_tab',
  REFI_TAB = 'refi_tab',
  EQUITY_TAB = 'equity_tab',
  REQUEST_FEES = 'request_fees',
  NO_FEES = 'no_fees',
  DOWNLOAD_FEES = 'download_fees',
  SETTINGS = 'settings',
  ACCOUNT_EDIT = 'account_edit',
  HOME_EDIT = 'home_edit',
  LOAN_ADD = 'loan_add',
  LOAN_UPDATE = 'loan_update',
  LOAN_DELETE = 'loan_delete',
  UNSUBSCRIBE = 'unsubscribe',
  SMS_UNSUBSCRIBE = 'sms_subscribe',
  UPDATES_POPUP = 'updates_popup',
  GET_UPDATES = 'get_updates',
  DOWNLOAD_PDF = 'download_pdf',
  NEW_HOME_TAB = 'new_home_tab',
  INVEST_TAB = 'invest_tab',
  SECOND_HOME_TAB = 'second_home_tab',
  AFFORDABILITY_CALC = 'affordability_calc',
  LONG_RENT_TAB = 'long_rent_tab',
  SHORT_RENT_TAB = 'short_rent_tab',
  LOAN_DETAILS = 'loan_details',
  USE_SAVINGS_CALC = 'use_savings_calc',
}
