<div class="modal-container">
  <div class="modal-content">
    <div class="modal-close">
      <svg-icon src="assets/icons/close.svg" [svgStyle]="svgStyle" (click)="close()"></svg-icon>
    </div>
    <div class="modal-body">
      <div class="message">{{ message }}</div>
    </div>
    <div class="modal-footer">
      <div class="btn-group">
        <iq-button class="m-r-16" type="button" color="light-blue" label="Cancel" size="40" (onClick)="close()"></iq-button>
        <iq-button type="button" color="red" label="Delete" size="40" (onClick)="confirm()"></iq-button>
      </div>
    </div>
  </div>
</div>
