import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { Dictionary } from 'asap-team/asap-tools';
import type { ConfirmModel } from './confirm.model';

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass'],
})
export class ConfirmDialogComponent extends SimpleModalComponent<ConfirmModel, boolean> implements ConfirmModel {

  title: string;

  message: string;

  svgStyle: Dictionary = { fill: '#fff' };

  confirm(): void {
    // we set modal result as true on click on confirm button,
    // then we can get modal result from caller code
    this.result = true;
    this.close();
  }

}
