import { range } from 'lodash-es';

export const ANNUAL_INCOME: number[] = [
  ...range(20000, 100000, 5000),
  ...range(100000, 210000, 10000),
  ...range(225000, 300000, 25000),
  ...range(300000, 500000, 50000),
  ...range(500000, 1000000, 100000),
  ...range(1000000, 2200000, 200000),
  2300000,
  2500000,
];
