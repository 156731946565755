// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum COMMON_TOAST {
  SUCCESS = 'Success',
  DONE = 'Done',
  SOMETHING_WRONG = 'Something wrong',
  EMAIL_SENT = 'Email sent',
  EMAIL_CONFIRM_SENT = 'Confirmation email sent',
  EMAIL_CONFIRMED = 'Email verified',
  SETTINGS_SAVED = 'Account settings saved',
  LINK_EXPIRED = 'Confirmation link expired',
  DATA_UPDATING = 'Updating data in progress. This may take some time...',
  DATA_UPDATED = 'Data successfully updated!',
}
