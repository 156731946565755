<div class="container">
  <div class="loading-dots loading-dots--lg" *ngIf="isLoading">
    <div class="loading-dots__item loading-dots__item--1"></div>
    <div class="loading-dots__item loading-dots__item--2"></div>
    <div class="loading-dots__item loading-dots__item--3"></div>
  </div>
  <div *ngIf="!isLoading">
    <div *ngIf="!isSubscribed">
      <h3>You have been unsubscribed</h3>
    </div>
    <div *ngIf="isSubscribed">
      <div class="unsubscribe-reason">
        <h3 class="subscribe__title subscribe__title-inside">Why do you want to unsubscribe?</h3>
        <form class="unsubscribe-reason__form" [formGroup]="form">
          <div class="unsubscribe-reason__radio" *ngFor="let item of unsubscribeReasons; let i=index">
            <input class="reason"
                   id="{{ 'reason' + i }}"
                   type="radio"
                   name="reason"
                   formControlName="reason"
                   [value]="UNSUBSCRIBE_REASON[item]"/>
            <label for="{{ 'reason' + i }}">{{ UNSUBSCRIBE_REASON[item] }}</label>
          </div>
          <button class="btn btn-default"
                  type="button"
                  (click)="unsubscribeFromDigest()">Unsubscribe me
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
