import { Component } from '@angular/core';

// Consts
import { environment } from 'environments/environment';

@Component({
  selector: 'invite-lender',
  templateUrl: './invite-lender.component.html',
  styleUrls: ['../shared/shared-styles.sass', './invite-lender.component.sass'],
})
export class InviteLenderComponent {

  get link(): string {
    return `${environment.soaUrl}/partnership`;
  }

}
