<div class="modal-container help">
  <div class="modal-content">
    <div class="modal-close" [inlineSVG]="'assets/icons/close_circle.svg'" (click)="close()"></div>
    <div class="modal-back" (click)="close()">
      <img src="/assets/components/my-loans/arrow_back.svg"/>
    </div>
    <div class="modal-body">
      <h2 class="m-t-40 m-b-48">What is the PMI?</h2>
      <h3 class="m-b-48"><b>Private Mortgage Insurance</b>, is an insurance policy that protects the holder against loss
        resulting from default on a mortgage loan, used <b>for Conventional loans</b>.</h3>
      <p class="m-b-48 f-20-normal f-c-gull-gray">Private mortgage insurance (PMI) is an insurance policy used in
        conventional loans that protects lenders from the risk of default and foreclosure and allows buyers who cannot
        make a significant down payment (or those who choose not to) to
        obtain mortgage financing at affordable rates.<br/><br/>[Important: If you purchase a home and put down less
        than 20 percent, your lender will minimize its risk by requiring you to buy insurance from a PMI company prior
        to signing off
        on the loan.]<br/><br/>The cost you pay for PMI varies depending on the size of the down payment and loan, but
        typically runs about 0.5 percent to 1 percent of the loan.<br/><br/>If you have monthly PMI (borrower paid), you
        make a premium
        payment every month until your PMI is either:<br/><br/>terminated (when your loan balance is scheduled to reach
        78 percent of the original value of your home)<br/>canceled at your request because your equity in the home
        reaches 20 percent
        of the purchase price or appraised value (your lender will approve a PMI cancelation only if you have adequate
        equity and have a good payment history)<br/>you reach the midpoint of the amortization period (a 30-year loan,
        for example,
        would reach the midpoint after 15 years)</p>
    </div>
  </div>
</div>
