import { Component, Input } from '@angular/core';

import type { Lead } from '@core/types';

// Configs
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent {

  @Input() lead: Lead;

  currentYear: Date = new Date();

  get isShowCopy(): boolean {
    return this.lead && !this.lead.skin_name;
  }

  get privacyPolicy(): string {
    return environment.privacy_policy_link;
  }

  get termsOfUse(): string {
    return environment.terms_conditions_link;
  }

}
