<div class="card card--user-info">
  <div class="user-info">
    <div class="user-info__description">
      <div class="user-info__image">
        <avatar-placeholder class="size-120" [user]="user"></avatar-placeholder>
      </div>
      <div>
        <h3 class="user-info__title">{{ user?.name }}</h3>
        <div class="user-info__subtitle" *ngIf="user?.job_title">
          <b>{{ user?.job_title }}&#32;&middot;&#32;</b>
          <span *ngIf="user?.role === USER_ROLE.agent">LIC#&#32;</span>
          <span *ngIf="user?.role === USER_ROLE.lender">NMLS&#32;</span>
          <span>{{ user?.license_number }}</span>
        </div>
        <div class="user-info__subtitle" *ngIf="!user?.job_title">
          <b>{{ user?.role }}&#32;&middot;&#32;</b>
          <span *ngIf="user?.role === USER_ROLE.agent">LIC#&#32;</span>
          <span *ngIf="user?.role === USER_ROLE.lender">NMLS&#32;</span>
          <span>{{ user?.license_number }}</span>
        </div>
        <div class="user-info__subtitle">
          <b>{{ user?.company_name}}&#32;&middot;&#32;</b>
          <span *ngIf="user?.role === USER_ROLE.agent">LIC#&#32;</span>
          <span *ngIf="user?.role === USER_ROLE.lender">NMLS&#32;</span>
          <span>{{ user?.company_license_number }}</span>
        </div>
        <div class="user-info__contact" *ngIf="user?.phone">
          <p class="user-info__contact--item"><a href="{{'tel: +1'+user?.phone}}">{{ user?.phone | phone }}</a></p>
          <p class="user-info__contact--item"><a href="{{'mailto: '+user?.email}}">{{ user?.email }}</a></p>

          <div class="socials__list" *ngIf="user?.socials">
            <a class="socials__list--link"
               *ngIf="user?.socials?.facebook_url"
               [href]="user?.socials?.facebook_url"
               target="_blank">
              <img src="assets/components/user-info/socials/facebook.svg" alt="facebook-link"/>
            </a>
            <a class="socials__list--link"
               *ngIf="user?.socials?.instagram_url"
               [href]="user?.socials?.instagram_url"
               target="_blank">
              <img src="assets/components/user-info/socials/instagram.svg" alt="instagram-link"/>
            </a>
            <a class="socials__list--link"
               *ngIf="user?.socials?.linkedin_url"
               [href]="user?.socials?.linkedin_url"
               target="_blank">
              <img src="assets/components/user-info/socials/linkedin.svg" alt="linkedin-link"/>
            </a>
            <a class="socials__list--link"
               *ngIf="user?.socials?.youtube_url"
               [href]="user?.socials?.youtube_url"
               target="_blank">
              <img src="assets/components/user-info/socials/youtube.svg" alt="youtube-link"/>
            </a>
            <a class="socials__list--link"
               *ngIf="user?.socials?.zillow_url"
               [href]="user?.socials?.zillow_url"
               target="_blank">
              <img src="assets/components/user-info/socials/zillow.svg" alt="zillow-link"/>
            </a>
            <a class="socials__list--link"
               *ngIf="user?.socials?.realtor_url"
               [href]="user?.socials?.realtor_url"
               target="_blank">
              <img src="assets/components/user-info/socials/realtor.svg" alt="realtor-link"/>
            </a>
            <a class="socials__list--link"
               *ngIf="user?.socials?.personal_website_url"
               [href]="user?.socials?.personal_website_url"
               target="_blank">
              <img src="assets/components/user-info/socials/website.svg" alt="website-link"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
