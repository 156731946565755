import { Component, OnInit, Input } from '@angular/core';

// Consts
import { environment } from 'environments/environment';
import { TRACKING } from '@consts/analytics';

// Services
import { TrackingService } from '@core/helpers/tracking/tracking.service';

@Component({
  selector: 'viral-activate',
  templateUrl: './viral-activate.component.html',
  styleUrls: ['./viral-activate.component.sass'],
})
export class ViralActivateComponent implements OnInit {

  @Input() link: string;

  token: string[];

  isUserActivate: boolean;

  constructor(
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.checkUserActive();
  }

  private checkUserActive(): void {
    this.token = this.link.split('&token=');

    if (this.token[1] === '') {
      this.isUserActivate = true;
      this.link = `${environment.soaUrl}/sign-up`;
    }
  }

  track(): void {
    if (!this.isUserActivate) {
      this.trackingService.track(TRACKING.viral_report_activated, true).subscribe();
    }
  }

}
