import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: './download-mortgage-checkup-certificate.component.html',
  styleUrls: ['./download-mortgage-checkup-certificate.component.sass'],
})
export class DownloadMortgageCheckupCertificateComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.save();
  }

  back(): void {
    this.location.back();
  }

  save(): void {
    if (!this.activatedRoute.snapshot.data?.certificate) { return; }

    const report: { file: Blob; name: string } = this.activatedRoute.snapshot.data?.certificate;

    saveAs(report.file, report.name);
  }

}
