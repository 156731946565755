<div class="modal-container help">
  <div class="modal-content">
    <div class="modal-close" [inlineSVG]="'assets/icons/close_circle.svg'" (click)="close()"></div>
    <div class="modal-back" (click)="close()">
      <img src="/assets/components/my-loans/arrow_back.svg"/></div>
    <div class="modal-body">
      <h2 class="m-t-40 m-b-48">What is the LTV?</h2>
      <p class="m-b-48 f-20-normal f-c-gull-gray">Loan to Value (LTV) ratio is an assessment of lending risk that
        financial institutions and other lenders examine before approving a mortgage.<br/><br/>Your actual LTV ratio
        calculated by formula:</p>
      <h3 class="m-b-48"><b>LTV = Remaining Loan Amount / Home value * 100%</b></h3>
      <p class="m-b-48 f-20-normal f-c-gull-gray">A loan with a high LTV ratio may require the borrower to purchase
        mortgage insurance to offset the risk to the lender. The LTV ratio is one of the influential factors that affect
        how long your MIP or PMI insurance will last.<br/><br/>Here
        is an example in what ranges you can count on the cancellation of the insurance payment, but provided that the
        necessary amount of time has been paid for the corresponding type of loan.<br/><br/><b class="f-c-firefly">LTV =
          80% - 100%</b> — mortgage insurance can’t be dropped. Even refinancing won't
        help.<br/><br/><b class="f-c-firefly">LTV = 78% - 80%</b> — you can request a cancellation of the insurance
        payment from your lender. There's a chance that it will be canceled.<br/><br/>
        <b class="f-c-firefly">LTV = 0 - 78%</b> – insurance must be canceled. If this is not the case, you have the
        right to request cancellation. And also you definitely will have no insurance in the case of refinancing.</p>
    </div>
  </div>
</div>
