import { isMobile } from 'mobile-device-detect';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  HostListener,
  ElementRef,
  ViewChild,
  Renderer2,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.sass'],
})
export class NotFoundComponent implements OnInit {

  @ViewChild('lama', { static: true }) lama: ElementRef<any>;

  @ViewChild('town', { static: true }) town: ElementRef<any>;

  errorText: string;

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const state: any = this.route.snapshot.data?.state;

    if (!state) {
      this.errorText = 'There was an error <br/> accessing your home';

      return;
    }

    if (state.status === 403) {
      this.errorText = 'Your report is not <br/> active anymore';

      return;
    }

    this.errorText = state.errorMessage;
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {

    const lamaPlax: number = 100;
    const townPlax: number = 300;

    if (!isMobile) {
      this.renderer.setStyle(this.lama.nativeElement, 'transform', `translateX(-${(event.x / lamaPlax)}%)`);
      this.renderer.setStyle(this.town.nativeElement, 'background-position-x', `${50 + (event.x / townPlax)}%`);
    }
  }

}
