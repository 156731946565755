import { ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { getFileName, BaseHttpService, Dictionary } from 'asap-team/asap-tools';

import * as RequestParams from '@core/types/request';
import type {
  Lead,
  ResponseFees,
  InterestAddsUp,
  IExtraPrincipal,
  Question,
  RentInfo,
  BuyAnotherHouse,
  AirbnbInfo,
  VerifyPhone,
  LoanTermInfoResponse,
  InsuranceInfo,
  HomeValueCalcData,
  HomeValueCalcResponse,
  Fees,
  DownPaymentData,
  MortgageInsuranceData,
  LeadFinanceHistory,
} from '@core/types';

// Consts
import { environment } from 'environments/environment';

// Services
import { HelpersService } from '@core/helpers/helpers.service';
import { UnsubscribeEmailType } from '@components/unsubscribe/enums/unsubscribe-email-type.enum';

@Injectable({ providedIn: 'root' })
export class LeadService {

  private lead: Subject<Lead> = new Subject<Lead>();

  private downPaymentData: BehaviorSubject<DownPaymentData> = new BehaviorSubject<DownPaymentData>(null);

  lead$: Observable<Lead> = this
    .lead
    .asObservable()
    .pipe(
      distinctUntilChanged(isEqual),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );

  downPaymentData$: Observable<DownPaymentData> = this.downPaymentData.asObservable();

  constructor(
    private http: BaseHttpService,
    private activatedRoute: ActivatedRoute,
    private helpersService: HelpersService,
  ) {}

  private buildUrl(uid: string, address_hash: string, suffix: string): string {
    return [
      'v1/seller_digest',
      this.isDigestTypeOfLead ? 'leads' : 'property_digests',
      `${uid}`,
      this.isDigestTypeOfLead ? null : `${address_hash}`,
      suffix || null,
    ]
      .filter(Boolean)
      .join('/');
  }

  get isDigestTypeOfLead(): boolean {
    return !this.activatedRoute.snapshot.queryParamMap.has('address_hash');
  }

  getLead({ uid, address_hash }: Dictionary): Observable<Lead> {
    return this
      .http
      .get(this.buildUrl(uid, address_hash, null))
      .pipe(
        tap((lead: Lead) => { this.emitLeadUpdate(lead); }),
      );
  }

  emitLeadUpdate(lead: Lead): void {
    this.lead.next(lead);
  }

  getFees({ uid, address_hash }: Dictionary): Observable<Fees> {
    return this
      .http
      .get(this.buildUrl(uid, address_hash, 'fees'))
      .pipe(
        map((fees: ResponseFees) => this.helpersService.getLeadReport(fees)),
      );
  }

  getInterestAddsUp({ uid, address_hash }: Dictionary): Observable<InterestAddsUp> {
    return this
      .http
      .get(this.buildUrl(uid, address_hash, 'interest_adds_up'));
  }

  getRentInfo({ uid, address_hash }: Dictionary): Observable<RentInfo> {
    return this
      .http
      .get(this.buildUrl(uid, address_hash, 'rent_info'))
      .pipe(
        map((response: RentInfo) => {
          response.lead_financing_histories = response.lead_financing_histories.filter((value: LeadFinanceHistory) => {
            if (!value.rent_amount) { return false; }

            return value;
          });

          return response;
        }),
      );
  }

  getAnotherHouseInfo({ uid, address_hash, params }: Dictionary<any>): Observable<BuyAnotherHouse> {
    return this.http.post(this.buildUrl(uid, address_hash, 'buy_another_house'), { ...params });
  }

  emitDownpaymentData(data: DownPaymentData): void {
    this.downPaymentData.next(data);
  }

  getAirbnbInfo({ uid, address_hash }: Dictionary): Observable<AirbnbInfo> {
    return this.http.get(this.buildUrl(uid, address_hash, 'airbnb'));
  }

  getExtraPrincipalInfo({ uid, address_hash }: Dictionary, value?: string): Observable<IExtraPrincipal> {
    return this
      .http
      .get(
        this.buildUrl(uid, address_hash, 'extra_principal'),
        { params: { additional_principal: value || '250' } },
      );
  }

  getLoanTermInfo({ uid, address_hash }: Dictionary): Observable<LoanTermInfoResponse> {
    return this.http.get(this.buildUrl(uid, address_hash, 'loan_term_graphic'));
  }

  getInsurance({ uid, address_hash }: Dictionary): Observable<InsuranceInfo> {
    return this.http.get(this.buildUrl(uid, address_hash, 'loan_term_graphic'));
  }

  calculateHomeValue({ uid, payload, address_hash }: {
    uid: string;
    payload: HomeValueCalcData;
    address_hash: string;
  }): Observable<HomeValueCalcResponse> {
    const params: HttpParams = new HttpParams({ fromObject: payload });

    return this.http.get(this.buildUrl(uid, address_hash, 'improvement_calculator'), { params });
  }

  subscribeToDigest(uid: string): Observable<void> {
    return this
      .http
      .patch(`v1/seller_digest/digests/${uid}/subscribe`, null, { observe: 'response' });
  }

  unsubscribeFromDigest(uid: string, params: any): Observable<Response> {
    return this
      .http
      .patch(`v1/seller_digest/digests/${uid}/unsubscribe`, params, { observe: 'response' });
  }

  unsubscribeByUpdateEmailSettings(uid: string, email_type: UnsubscribeEmailType): Observable<Response> {
    const formData: FormData = new FormData();

    formData.append('uid', uid);
    formData.append('email_type', email_type);
    formData.append('email_value', 'false');

    return this
      .http
      .patch('v2/email_settings', formData);
  }

  sendQuestion(data: Question, uid: string): Observable<void> {
    return this.http.post(`v1/seller_digest/digests/${uid}/ask_question`, {
      question_text: data.question_text,
      screen_title: data.screen_title,
    });
  }

  verifyHomeValue(uid: string): Observable<void> {
    return this.http.post(`v1/seller_digest/digests/${uid}/verify_home_value`, { uid });
  }

  updateDigestViews(uid: string): Observable<void> {
    return this.http.patch(`v1/seller_digest/leads/${uid}/update_digest_views`, {});
  }

  getReportFileLink({ uid, hash, type }: RequestParams.ReportFileLink): Observable<string> {
    return of(`${environment.api}/v1/seller_traffic/leads/${uid}/reports/${hash}?report_type=${type}`);
  }

  getMortgageCheckupCertificateFile({
    uid,
    loan_term,
  }: RequestParams.MortgageCheckupCertificateFile): Observable<{ file: Blob; name: string }> {
    const options: Dictionary = {
      observe: 'response',
      responseType: 'blob',
    };

    return this
      .http
      .get(`v1/seller_digest/mortgage_checkups/${uid}/download?loan_term=${loan_term}`, options)
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return {
            file: response.body,
            name: getFileName(response.headers) || 'quote.pdf',
          };
        }),
      );
  }

  verifyPhone({ uid, phone }: VerifyPhone): Observable<any> {
    return this.http.patch(`v1/seller_digest/leads/${uid}/verify_phone`, { phone });
  }

  verifyFees(uid: string): Observable<any> {
    return this.http.post(`v1/seller_digest/digests/${uid}/verify_fees`, {});
  }

  updateAccountSettings(uid: string, params: any): Observable<any> {
    return this.http.patch(`v1/seller_digest/leads/${uid}/update_account_settings`, params);
  }

  updateHomeDetails(uid: string, params: any): Observable<Lead> {
    return this
      .http
      .patch(`v1/seller_digest/leads/${uid}/home_details`, params);
  }

  changeHomeownerEmail(uid: string, params: any): Observable<any> {
    return this.http.patch(`v1/seller_digest/leads/${uid}/update_email`, params);
  }

  resendHomeownerEmailChangeConfirmation(uid: string): Observable<any> {
    return this.http.patch(`v1/seller_digest/leads/${uid}/resend_email_confirmation`, null);
  }

  confirmHomeownerEmail(email: string, token: string): Observable<Lead> {
    return this
      .http
      .post('v1/seller_digest/leads/confirm_email', { email, token });
  }

  getCancelMortgageInsurance(uid: string): Observable<MortgageInsuranceData> {
    return this
      .http
      .get(`v1/seller_digest/leads/${uid}/insurance_cancel`);
  }

}
