import { FormErrors } from 'asap-team/asap-tools';

export const homeDetailsErrors: FormErrors = {
  year_built: [
    {
      name: 'main',
      text: 'must be greater than or equal to 1900',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: `must be less than or equal to ${new Date().getFullYear()}`,
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  home_sq_ft: [
    {
      name: 'min',
      text: 'must be greater than or equal to 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'must be less than 500000',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  bedrooms: [
    {
      name: 'min',
      text: 'must be greater than or equal to 1',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'must be less than 1000',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  bathrooms: [
    {
      name: 'min',
      text: 'must be greater than or equal to 0.5',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'must be less than 1000',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  sale_price: [
    {
      name: 'min',
      text: 'must be greater than 0',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'max',
      text: 'must be less than 999999999',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
