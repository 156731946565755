<div class="card refinance-estimator">
  <div class="card__content text-center" [ngSwitch]="viewRestriction">
    <div class="refinance-estimator__content" [class.lender-required]="isRefinanceViewLimited">
      <h2>Interest you will end up paying</h2>
      <p class="refinance-estimator__value number number--huge color-primary" [counterAnimation]="data?.total_interest">
        0</p>
      <p class="refinance-estimator__descr text" *ngIf="lead?.loan_date">Based on the loan you took out
        in {{ lead?.loan_date | date:'MMMM y' }}</p>
      <button class="btn-link btn-link--primary" *ngIf="!isPreview" type="button" (click)="openSidebar()">View loan
        details
      </button>
      <h3 class="refinance-estimator__subtitle">Possible refinance opportunities available for you</h3>
      <p class="text">Based on current national rates.</p>
      <div class="refinance-estimator__tab">
        <div class="btn-tabs btn-tabs--interest-adds">
          <div class="btn-tabs__item" *ngFor="let item of data?.interest_adds_up">
            <div class="term">{{ item[0] }} Year</div>
            <div class="number color-primary"
                 [class.color-danger]="checkNegativeNumber(item[1].interest_saved)">{{ item[1].interest_saved | amount }}</div>
          </div>
        </div>
      </div>
      <div class="refinance-estimator__wrap" [class.blurred]="isRefinanceViewLimited">
        <div class="refinance-estimator__col" *ngFor="let item of data?.interest_adds_up">
          <div class="refinance-estimator__head">
            <h4>{{ item[0] }} Year</h4>
            <p class="refinance-estimator__rate caption-text">{{ item[1].interest_rate }}% RATE • {{ item[1].apr }}% APR<span
              class="refinance-estimator__rate--tooltip-info"
              [tooltip]="tooltip.DISCLAIMER_INFO"
              [options]="tooltipOptions">*</span></p>
            <p class="refinance-estimator__head-label f-w-600"
               [ngClass]="getLabelStyle(item)">{{ checkNegativeNumber(item[1].interest_saved) ? 'Overpay' : 'Save' }}</p>
            <div class="number number--lg color-primary"
                 [class.color-danger]="checkNegativeNumber(item[1].interest_saved)">{{ item[1].interest_saved | amount }}</div>
          </div>
          <div class="refinance-estimator__divider"></div>
          <ul class="refinance-estimator__list"></ul>
          <li class="refinance-estimator__list-item" [class.check]="item[1].interest_saved >= 0">
            <p class="text text-center">You will
              pay&#32;<span>{{ getAbsoluteValue(item[1].monthly_payment_up) | amount }}&#32;</span><span>{{ item[1].monthly_payment_up <= 0 ? 'less 👍 ' : 'more ' }}</span>each
              month</p>
          </li>
          <li class="refinance-estimator__list-item">
            <p class="text text-center">and your interest rate will
              go&#32;<span>{{ item[1].interest_rate_diff >= 0 ? 'down' : 'up' }}&#32;</span><span>{{ getAbsoluteValue(item[1].interest_rate_diff) }}
                %</span></p>
          </li>
        </div>
      </div>
    </div>
    <invite-lender *ngSwitchCase="'lender'"></invite-lender>
    <no-loan *ngSwitchCase="'loan'" [reportId]="data.id"></no-loan>
    <locked-refinance *ngSwitchCase="'locked'" [reportId]="data.id"></locked-refinance>
  </div>
  <ng-template hideInPreview>
    <send-question *ngIf="!isRefinanceViewLimited"
                   [userId]="lead.id"
                   [user]="lead.lender"
                   [eventCategory]="'refinance'"
                   [preparedQuestions]="preparedQuestions"
                   [title]="'interest_adds'">
      <div class="refinance-options" *ngIf="showRefinanceOptionsButton">
        <div class="refinance-options__title">Receive updated possible refinance options available for you now</div>
        <div class="refinance-options__button-block">
          <a class="btn-link refinance-options__button"
             target="_blank"
             [routerLink]="refinanceOptionsLink"
             [queryParams]="refinanceOptionsQueryParams">View your refinance options</a>
        </div>
      </div>
    </send-question>
  </ng-template>
</div>
