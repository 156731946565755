import { Dictionary } from 'asap-team/asap-tools';

export const PREPARED_QUESTIONS: Dictionary<string[]> = {
  home_value: [
    'How accurate is my home value?',
    'Is it a good time to sell my home right now?',
  ],
  interest_adds: [
    'How long will it take to complete a refinance?',
    'Are these actual rates you can offer today?',
  ],
  report_line_tab: [
    'How can I tap into my equity?',
    'Can I get some of my equity without a refi?',
  ],
  mortgage_insurance: [
    'What do I need to do to remove the mortgage insurance?',
    'Does the mortgage insurance automatically fall off?',
  ],
  interest_loan: [
    'What amount of my additional payment goes towards principal?',
    'Does it make sense to pay off the home sooner?',
  ],
  airbnb: [
    'How can I learn about real estate investing?',
    'How do I find the right tenant for my rental property?',
  ],
  buy_another_house: [
    'Can I qualify to purchase a second home?',
    'How accurate is the affordability calculator provided in this report?',
  ],
};
