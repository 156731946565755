import { Component, Input } from '@angular/core';

@Component({
  selector: 'idx-link-banner',
  templateUrl: './idx-link-banner.component.html',
  styleUrls: ['./idx-link-banner.component.sass'],
})
export class IdxLinkBannerComponent {

  @Input() idx_link: string;

}
