<div class="card home-report">
  <div class="card__content text-center p-b-50">
    <h2 class="home-report__title">Cash you may be able to put<br/>in your pocket if you</h2>
    <div class="btn-tabs btn-tabs__loading m-b-32">
      <button class="btn-tabs__item">Sell</button>
      <button class="btn-tabs__item">Refinance</button>
      <button class="btn-tabs__item">Home Equity</button>
    </div>
    <p class="home-report__value number number__large sk-load__text">$00,000</p>
    <div class="sk-load__bar center mw-400 m-t-20 m-b-18"></div>
    <div class="sk-load__bar center mw-340 m-b-40"></div>
    <h3>See below how this is calculated</h3>
    <div class="report__content">
      <div class="report__head">
        <div class="report__head--item">
          <div class="report__head--icon sk-loading"></div>
          <div class="report__head--value number number__small white sk-load__text sk-load__text--with-back">
            $000,000
          </div>
          <div class="report__head--title report__head--title-loading">Estimated<br/>Home Value</div>
        </div>
        <div class="report__head--item">
          <div class="report__head--icon sk-loading"></div>
          <div class="report__head--value number number__small white sk-load__text sk-load__text--with-back">
            $000,000
          </div>
          <div class="report__head--title report__head--title-loading">Estimated<br/>Loan Balance</div>
        </div>
        <div class="report__head--item">
          <div class="report__head--icon sk-loading"></div>
          <div class="report__head--value number number__small white sk-load__text sk-load__text--with-back">
            $000,000
          </div>
          <div class="report__head--title report__head--title-loading">Estimated<br/>Closign Fees</div>
        </div>
        <div class="report__head--item">
          <div class="report__head--icon sk-loading"></div>
          <div class="report__head--value number number__small white sk-load__text sk-load__text--with-back">
            $000,000
          </div>
          <div class="report__head--title report__head--title-loading">Estimated<br/>Net Equity</div>
        </div>
      </div>
      <div class="report__body">
        <h3 class="report__title">Estimated Closing Fees</h3>
        <div class="loading-dots loading-dots--lg loading-dots--content">
          <div class="loading-dots__item loading-dots__item--1"></div>
          <div class="loading-dots__item loading-dots__item--2"></div>
          <div class="loading-dots__item loading-dots__item--3"></div>
        </div>
      </div>
    </div>
    <div class="sk-load__bar sk-load__bar--round center mw-166 h-40 m-t-40"></div>
  </div>
</div>
