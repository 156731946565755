import { Subscription } from 'rxjs';
import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { fadeInOut, fadeOut } from 'asap-team/asap-tools';

import type { Lead } from '@core/types';

// Consts
import { SIDEBAR_TAB_NAME } from '@consts/consts';
import { LOANS_TOASTS } from '@consts/enums';

// Services
import { LoansService } from '@core/services/loans/loans.service';
import { RightSidebarService } from '@core/helpers/right-sidebar/right-sidebar.service';

@UntilDestroy()
@Component({
  selector: 'any-loans',
  templateUrl: './any-loans.component.html',
  styleUrls: ['./any-loans.component.sass'],
  animations: [fadeOut(), fadeInOut()],
})
export class AnyLoansComponent {

  @Input() lead: Lead;

  isShowBlock: boolean = true;

  action$: Subscription;

  constructor(
    private loansService: LoansService,
    private rightSidebarService: RightSidebarService,
    private toastr: ToastrService,
  ) {}

  verifyLoans(): void {
    this.action$ = this
      .loansService
      .verifyLoans(this.lead.id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.lead.loan_verified = true;
          this.isShowBlock = false;
          this.toastr.success(LOANS_TOASTS.VERIFY);
        },
      );
  }

  openSidebar(): void {
    this
      .rightSidebarService
      .openHeaderSidebar(SIDEBAR_TAB_NAME.LOANS, this.lead);
  }

}
