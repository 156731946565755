import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

import type { LoanYear } from '@core/types';

type LabelStyle = {
  left: string;
  visibility: string;
  opacity: string;
};

@Component({
  selector: 'lpt-labels',
  templateUrl: './lpt-labels.component.html',
  styleUrls: ['./lpt-labels.component.sass'],
})
export class LoanPaymentTrackerLabelsComponent implements OnInit {

  @ViewChild('labels', { static: false }) labelsElement: ElementRef;

  @Input() dataset: any[];

  @Input() chartElement: HTMLDivElement;

  chartElementBox: DOMRect;

  currentYear: number = new Date().getFullYear();

  ngOnInit(): void {}

  private isRectIntersected(first: DOMRect, second: DOMRect): boolean {
    return (first.left <= second.right
      && second.left <= first.right
      && first.top <= second.bottom
      && second.top <= first.bottom);
  }

  private getPosition(index: number): number {
    this.chartElementBox = this.chartElement.getBoundingClientRect() as DOMRect;

    const assignedBarElement: HTMLDivElement = this.chartElement.children[index] as HTMLDivElement;
    const assignedBarBox: DOMRect = assignedBarElement.getBoundingClientRect() as DOMRect;
    const relativePositionValue: number = assignedBarBox.left - this.chartElementBox.left;

    return relativePositionValue;
  }

  private isStart(index: number): boolean {
    return index === 0;
  }

  private isEnd(index: number, data: any[]): boolean {
    return index === data.length - 1;
  }

  private isCurrentYear(data: LoanYear): boolean {
    return data.year === this.currentYear;
  }

  private isShowLabel(
    currentSet: LoanYear,
    dataset: LoanYear[],
    currentIndex: number,
  ): boolean {
    if (this.isStart(currentIndex) || this.isEnd(currentIndex, dataset)) {
      return true;
    }

    const isNotIntersectsWithMarginals = (): boolean => {
      if (!this.labelsElement) { return false; }

      const currentElement: HTMLDivElement = this.labelsElement.nativeElement.children[currentIndex] as HTMLDivElement;
      const currentBox: DOMRect = currentElement.getBoundingClientRect() as DOMRect;

      return dataset
        .map((value: LoanYear, index: number) => {
          if (this.isStart(index) || this.isEnd(index, dataset)) {
            return index;
          }

          return null;
        })
        .filter((value: number) => value !== null)
        .map((index: number) => {
          const marginalElement: HTMLDivElement = this.labelsElement.nativeElement.children[index] as HTMLDivElement;
          const marginalBox: DOMRect = marginalElement.getBoundingClientRect() as DOMRect;

          return !this.isRectIntersected(currentBox, marginalBox);
        })
        .every((value: boolean) => value);
    };

    return this.isCurrentYear(currentSet) && isNotIntersectsWithMarginals();
  }

  getLabelStyle(currentSet: LoanYear, dataset: LoanYear[], index: number): LabelStyle {
    const isVisible: any = this.isShowLabel(currentSet, dataset, index);

    return {
      left: `${this.getPosition(index)}px`,
      visibility: isVisible ? 'visible' : 'hidden',
      opacity: isVisible ? '1' : '0',
    };
  }

}
