const EMPTY: string = '';
const ANY: string = '**';
const NOT_FOUND: string = 'not-found';

export const ROUTE: any = {
  EMPTY,
  ANY,
  name: { NOT_FOUND },
  alias: { NOT_FOUND: `/${NOT_FOUND}` },
};
