import { FormErrors } from 'asap-team/asap-tools';

export const changeEmailDialogErrors: FormErrors = {
  email: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'pattern',
      text: 'Email is invalid',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
