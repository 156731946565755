import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInOut } from 'asap-team/asap-tools';

import type { User } from '@core/types';

// Consts
import { INTRO_ASK } from '@consts/intro';

// Services
import { LeadService } from '@core/services/lead/lead.service';

// Components
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';
import { IntroDialogComponent } from '../dialogs/intro-dialog/intro-dialog.component';

@UntilDestroy()
@Component({
  selector: 'send-question',
  templateUrl: './send-question.component.html',
  styleUrls: ['./send-question.component.sass'],
  animations: [fadeInOut()],
})
export class SendQuestionComponent {

  @Input() userId: string;

  @Input() user: User;

  @Input() title: string;

  @Input() placeholder: string;

  @Input() preparedQuestions: string[];

  @Input() eventCategory: string = 'home_value';

  questionControl: FormControl<string> = new FormControl<string>('', Validators.maxLength(150));

  sendRequestAction$: Subscription;

  errorMessage: string = 'Maximum message length is 150 symbols';

  isQuestionSent: boolean = false;

  isFormExpanded: boolean = false;

  constructor(
    private leadService: LeadService,
    private simpleModalService: SimpleModalService,
    private gaService: GAService,
  ) {}

  sendQuestion(value?: string): void {
    this.gaService.trackSimpleEvent(value ? GoogleEvents.QUICK_QUESTION : GoogleEvents.SEND_QUESTION, this.eventCategory);

    if (!this.leadService.isDigestTypeOfLead) {
      this.simpleModalService.addModal(IntroDialogComponent, INTRO_ASK);

      return;
    }

    const payload: any = {
      question_text: value || this.questionControl.value,
      screen_title: this.title,
    };

    this.sendRequestAction$ = this
      .leadService
      .sendQuestion(payload, this.userId)
      .pipe(
        delay(1000),
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.questionControl.setValue('');
          this.isQuestionSent = true;
          this.isFormExpanded = false;
        },
      );
  }

}
