<div class="send-question">
  <div class="send-question__state state-default" [class.show]="!isQuestionSent">
    <h3 class="send-question__title f-w-600">Questions?</h3>
    <p class="send-question__subtitle">Click on a button below to submit your question</p>
    <ul class="send-question__list">
      <li class="send-question__list-item" *ngFor="let question of preparedQuestions">
        <iq-button type="button"
                   [style]="'message'"
                   size="40"
                   [label]="question"
                   color="blue"
                   [busy$]="sendRequestAction$"
                   (onClick)="sendQuestion(question)">{{ question }}</iq-button>
      </li>
    </ul>
    <form class="form-group">
      <div class="send-question__group" (clickOutside)="isFormExpanded = false" [class.expanded]="isFormExpanded"><input
        class="form-control"
        id="questionControl"
        type="text"
        name="question"
        autocomplete="off"
        (focus)="isFormExpanded = true"
        [formControl]="questionControl"
        [placeholder]="placeholder || 'or type your own question here...'"/>
        <avatar-placeholder class="send-question__avatar" [user]="user"></avatar-placeholder>
        <button class="send-question__submit"
                (click)="sendQuestion()"
                [disabled]="!questionControl.value"
                [promiseBtn]="sendRequestAction$"></button>
        <div class="invalid-tooltip" *ngIf="questionControl.invalid && isFormExpanded">
          {{ errorMessage }}
        </div>
      </div>
    </form>
  </div>
  <div class="send-question__state state-success" [class.show]="isQuestionSent">
    <div class="sent-success__title">
      <i class="sent-success__icon" [inlineSVG]="'assets/icons/check-green.svg'"></i>Your question has been sent to
    </div>
    <avatar-placeholder class="sent-success__avatar" [user]="user"></avatar-placeholder>
    <div class="sent-success__name">{{ user?.name }}</div>
    <div class="sent-success__contacts">{{ user?.phone | phone }} • {{ user?.email }}</div>
    <div class="sent-success__actions">
      <a class="m-r-8" [href]="'tel: +1' + user?.phone">
        <iq-button type="button" [style]="'outline'" size="32" label="Call me" color="blue"></iq-button>
      </a>
      <a class="m-r-8" [href]="'mailto: ' + user?.email">
        <iq-button type="button" [style]="'outline'" size="32" label="Email me" color="blue"></iq-button>
      </a>
      <iq-button type="button"
                 [style]="'outline'"
                 size="32"
                 label="Send question"
                 color="blue"
                 (onClick)="isQuestionSent = false"></iq-button>
    </div>
  </div>
  <ng-content></ng-content>
</div>
