import { Component, Input } from '@angular/core';

import type { AirbnbInfo } from '@core/types';

@Component({
  selector: 'airbnb-rent-estimator',
  templateUrl: './airbnb-rent-estimator.component.html',
  styleUrls: ['./airbnb-rent-estimator.component.sass'],
})
export class AirbnbRentEstimatorComponent {

  @Input() airbnbInfo: AirbnbInfo;

  formatOccupancy(value: number): number {
    if (!value) { return 0; }

    return Math.round(value * 100) / 100;
  }

}
