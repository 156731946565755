import { Dictionary } from 'asap-team/asap-tools';

type TrackEvent = {
  event: string;
  source: string;
  campaign_name: string;
  tag: string;
};

function createEvent(event: string, source: string = 'home_iq_report', campaign_name: string = 'homeIQ', tag?: string): TrackEvent {
  if (!event) { return null; }

  return {
    event,
    source,
    campaign_name,
    tag,
  };
}

export const TRACKING: Dictionary<TrackEvent> = {
  // triggers when a lead clicks on Refinance tab
  client_tab_refinance_clicked: createEvent('client_tab_refinance_clicked'),

  // triggers when a lead clicks on Sell Home tab
  client_tab_sell_house_clicked: createEvent('client_tab_sell_house_clicked'),

  // triggers when a lead clicks on Line of Equity tab
  client_tab_line_of_equity_clicked: createEvent('client_tab_line_of_equity_clicked'),

  // triggers when a lead clicks on Update Loan Link
  client_update_loan_clicked: createEvent('client_update_loan_clicked'),

  // triggers when a lead downloading report
  client_download_pdf_clicked: createEvent('client_download_pdf_clicked'),

  // triggers when a lead clicks on Learn More link
  client_learn_more_how_fees_calculated_clicked: createEvent('client_learn_more_how_fees_calculated_clicked'),
  client_learn_more_interest_clicked: createEvent('client_learn_more_interest_clicked'),
  client_learn_more_pay_more_clicked: createEvent('client_learn_more_pay_more_clicked'),
  client_learn_more_rent_clicked: createEvent('client_learn_more_rent_clicked'),
  client_learn_more_airbnb_clicked: createEvent('client_learn_more_airbnb_clicked'),

  // triggers when a lead opens viral report page
  viral_report_open: createEvent('viral_report_open', 'viral_emails'),

  // triggers when a viral report fully loaded
  viral_report_viewed: createEvent('viral_report_viewed', 'viral_emails'),

  // triggers when a lead activates viral report
  viral_report_activated: createEvent('viral_report_activated', 'viral_emails'),

  // triggers when record does not loaded because error
  report_load_error: createEvent('report_load_error', 'errors'),
};
