import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseHttpService } from 'asap-team/asap-tools';

import type { LoanFormPayload, Lead } from '@core/types';

@Injectable({ providedIn: 'root' })
export class LoansService {

  private insuranceEditAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  insuranceEditAction$: Observable<boolean> = this.insuranceEditAction.asObservable();

  constructor(
    private http: BaseHttpService,
  ) {}

  emitInsuranceEdit(event: boolean): void {
    this.insuranceEditAction.next(event);
  }

  addNewLoan(uid: string, payload: LoanFormPayload): Observable<Lead> {
    return this
      .http
      .post(`v1/seller_digest/leads/${uid}/loans`, { loan_data: { ...payload } });
  }

  updateLoan(uid: string, loan_uid: string, payload: LoanFormPayload): Observable<Lead> {
    return this
      .http
      .patch(`v1/seller_digest/leads/${uid}/loans/${loan_uid}`, { loan_data: { ...payload } });
  }

  deleteLoan(uid: string, loan_uid: string): Observable<Lead> {
    return this
      .http
      .delete(`v1/seller_digest/leads/${uid}/loans/${loan_uid}`);
  }

  verifyLoans(uid: string): Observable<any> {
    return this
      .http
      .patch(`v1/seller_digest/leads/${uid}/verify_loans`, null);
  }

  setPrimaryLoan(uid: string, loan_uid: string): Observable<Lead> {
    return this
      .http
      .patch(`v1/seller_digest/leads/${uid}/loans/${loan_uid}/mark_as_primary`, null);
  }

}
