<div class="card">
  <div class="card__content text-center sms-subscribe__content">
    <div class="sms-subscribe__icon" [inlineSVG]="'assets/icons/smartphone.svg'"></div>
    <h2 class="sms-subscribe__title">Get notified when your home value updates</h2>
    <iq-button color="orange" label="Get updates" (onClick)="verifyNumber('block')"></iq-button>
  </div>
</div>
<div class="sms-subscribe__bar">
  <div class="sms-subscribe__bar--title">
    <h4 (click)="verifyNumber('sticky_bar')">Get notified when your home value updates</h4>
  </div>
  <iq-button class="m-l-16" color="orange" label="Get updates" (onClick)="verifyNumber('sticky_bar')"></iq-button>
</div>
