import { Angulartics2Module } from 'angulartics2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgModule, ErrorHandler } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import {
  APIInterceptor,
  API_INTECEPTOR_CONFIG,
  AsapCommonModule,
  BaseHttpService,
  JSONApiDeserializerInterceptor,
  SentinelErrorHandler,
  SENTINEL_CONFIG,
} from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';

// Components
import { AirbnbRentEstimatorComponent } from '@components/main/rent-estimator/airbnb-rent-estimator/airbnb-rent-estimator.component';
import { AnyLoansComponent } from '@components/main/any-loans/any-loans.component';
import { BuyAnotherHouseComponent } from '@components/main/buy-another-house/buy-another-house.component';
import { BuyAnotherHouseInfoComponent } from '@components/main/buy-another-house/buy-another-house-info/buy-another-house-info.component';
import { BuyerQualificationCertificateComponent } from '@components/main/buy-another-house/buyer-qualification-certificate/buyer-qualification-certificate.component';
import { CalcCountItemComponent } from '@components/main/equity-tracker/home-value-calculator/calc-count-item/calc-count-item.component';
import { ConfirmHomeownerEmailComponent } from '@components/confirm-homeowner-email/confirm-homeowner-email.component';
import { DownloadMortgageCheckupCertificateComponent } from '@components/download-mortgage-checkup-certificate/download-mortgage-checkup-certificate.component';
import { DownloadReportComponent } from '@components/download-report/download-report.component';
import { DownpaymentSliderComponent } from '@components/main/buy-another-house/downpayment-slider/downpayment-slider.component';
import { EquityTrackerComponent } from '@components/main/equity-tracker/equity-tracker.component';
import { FooterComponent } from '@commons/footer/footer.component';
import { HeaderComponent } from '@commons/header/header.component';
import { HomeReportComponent } from '@components/main/transaction-estimator/transaction-estimator.component';
import { HomeValueCalculatorComponent } from '@components/main/equity-tracker/home-value-calculator/home-value-calculator.component';
import { HomeValueChartComponent } from '@components/main/equity-tracker/home-value-chart/home-value-chart.component';
import { IdxLinkBannerComponent } from '@components/main/idx-link-banner/idx-link-banner.component';
import { InviteLenderComponent } from '@components/main/refinance-estimator/invite-lender/invite-lender.component';
import { LoadingScreenComponent } from '@components/main/loading-screen/loading-screen.component';
import { LoanPaymentTrackerComponent } from '@components/main/loan-payment-tracker/loan-payment-tracker.component';
import { LoanPaymentTrackerGraphComponent } from '@components/main/loan-payment-tracker/lpt-graph/lpt-graph.component';
import { LoanPaymentTrackerLabelsComponent } from '@components/main/loan-payment-tracker/lpt-graph/lpt-labels/lpt-labels.component';
import { LockedRefinanceComponent } from '@components/main/refinance-estimator/locked-refinance/locked-refinance.component';
import { MainComponent } from '@components/main/main.component';
import { MonthlyRentEstimatorComponent } from '@components/main/rent-estimator/monthly-rent-estimator/monthly-rent-estimator.component';
import { MortgageInsuranceComponent } from '@components/main/mortgage-insurance/mortgage-insurance.component';
import { NoLoanComponent } from '@components/main/refinance-estimator/no-loan/no-loan.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { PayMoreRangeComponent } from '@components/main/loan-payment-tracker/principal-paydown-forecaster/pay-more-range/pay-more-range.component';
import { PrimaryLoansComponent } from '@components/main/primary-loans/primary-loans.component';
import { PrincipalPaydownForecasterComponent } from '@components/main/loan-payment-tracker/principal-paydown-forecaster/principal-paydown-forecaster.component';
import { RefinanceEstimatorComponent } from '@components/main/refinance-estimator/refinance-estimator.component';
import { RentEstimatorComponent } from '@components/main/rent-estimator/rent-estimator.component';
import { RentInfoComponent } from '@commons/rent-info/rent-info.component';
import { ReportLoadingComponent } from '@components/main/loading-screen/report-loading/report-loading.component';
import { SendQuestionComponent } from '@commons/send-question/send-question.component';
import { SendReportComponent } from '@components/main/send-report/send-report.component';
import { SmsSubscribeComponent } from '@components/main/sms-subscribe/sms-subscribe.component';
import { SubscribeToDigestComponent } from '@components/subscribe-to-digest/subscribe-to-digest.component';
import { UnsubscribeComponent } from '@components/unsubscribe/unsubscribe.component';
import { MonthlyDigestUnsubscribeComponent } from '@components/unsubscribe/monthly-digest-unsubscribe/monthly-digest-unsubscribe.component';
import { UserDisclaimerComponent } from '@components/main/user-disclaimer/user-disclaimer.component';
import { UserInfoComponent } from '@components/main/user-info/user-info.component';
import { ViralActivateComponent } from '@components/main/viral-activate/viral-activate.component';

// Dialogs
import { ConfirmDialogComponent } from '@commons/dialogs/confirm-dialog/confirm-dialog.component';
import { IntroDialogComponent } from '@commons/dialogs/intro-dialog/intro-dialog.component';
import { RightSidebarComponent } from '@commons/dialogs/right-sidebar/right-sidebar.component';
import { VerifyPhoneDialogComponent } from '@commons/dialogs/verify-phone-dialog/verify-phone-dialog.component';
import { VideoDialogComponent } from '@commons/dialogs/video-dialog/video-dialog.component';

// Directives
import { HideInPreviewModeDirective } from '@core/directives/hide-in-preview-mode/hide-in-preview-mode.directive';

// Resolvers
import { HomeResolve } from '@core/resolvers/home/home.resolve';
import { LeadResolve } from '@core/resolvers/lead/lead.resolve';
import { LogoResolve } from '@core/resolvers/logo/logo.resolve';
import { ReportResolve } from '@core/resolvers/report/report.resolve';
import { MortgageCheckupCertificateResolve } from '@core/resolvers/mortgage-checkup-certificate/mortgage-checkup-certificate.resolve';

// Services
import { DigestDataService } from '@core/helpers/digest-data/digest-data.service';
import { EnvProviderService } from '@core/helpers/env-provider/env-provider.service';
import { LeadService } from '@core/services/lead/lead.service';
import { CommonsModule } from '@commons/commons.module';
import { ThemeService } from '@core/helpers/theme/theme.service';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const imports: any = [
  AsapCommonModule,
  Angulartics2Module.forRoot(),
  provideFirebaseApp(() => initializeApp(environment.firebase)),
  provideDatabase(() => getDatabase()),
  AppRoutingModule,
  BrowserAnimationsModule,
  BrowserModule,
  CommonsModule,
  HttpClientModule,
  ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  GoogleMapsModule,
];

const declarations: any = [
  AirbnbRentEstimatorComponent,
  AnyLoansComponent,
  AppComponent,
  BuyAnotherHouseComponent,
  BuyAnotherHouseInfoComponent,
  BuyerQualificationCertificateComponent,
  CalcCountItemComponent,
  ConfirmHomeownerEmailComponent,
  DownloadMortgageCheckupCertificateComponent,
  DownloadReportComponent,
  DownpaymentSliderComponent,
  EquityTrackerComponent,
  FooterComponent,
  HeaderComponent,
  HomeReportComponent,
  HomeValueCalculatorComponent,
  HomeValueChartComponent,
  IdxLinkBannerComponent,
  InviteLenderComponent,
  LoadingScreenComponent,
  LoanPaymentTrackerComponent,
  LoanPaymentTrackerGraphComponent,
  LoanPaymentTrackerLabelsComponent,
  LockedRefinanceComponent,
  MainComponent,
  MonthlyRentEstimatorComponent,
  MortgageInsuranceComponent,
  NoLoanComponent,
  NotFoundComponent,
  PayMoreRangeComponent,
  PrimaryLoansComponent,
  PrincipalPaydownForecasterComponent,
  RefinanceEstimatorComponent,
  RentEstimatorComponent,
  RentInfoComponent,
  ReportLoadingComponent,
  SendQuestionComponent,
  SendReportComponent,
  SmsSubscribeComponent,
  SubscribeToDigestComponent,
  UnsubscribeComponent,
  MonthlyDigestUnsubscribeComponent,
  UserDisclaimerComponent,
  UserInfoComponent,
  ViralActivateComponent,
];

const dialogs: any = [
  ConfirmDialogComponent,
  IntroDialogComponent,
  RightSidebarComponent,
  VerifyPhoneDialogComponent,
  VideoDialogComponent,
];

const directives: any = [
  HideInPreviewModeDirective,
];

const resolvers: any = [
  HomeResolve,
  LeadResolve,
  LogoResolve,
  ReportResolve,
  MortgageCheckupCertificateResolve,
];

const providers: any = [
  BaseHttpService,
  DigestDataService,
  EnvProviderService,
  LeadService,
  ThemeService,
  {
    provide: API_INTECEPTOR_CONFIG,
    useValue: {
      api: environment.api,
      bypass: [
        'assets',
        'tracking',
      ],
    },
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: APIInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JSONApiDeserializerInterceptor,
    multi: true,
  },
  {
    provide: SENTINEL_CONFIG,
    useValue: { environment, logErrors: true },
  },
  {
    provide: ErrorHandler,
    useClass: SentinelErrorHandler,
  },
];

@NgModule({
  imports: [
    imports,
  ],
  declarations: [
    ...declarations,
    ...dialogs,
    ...directives,
  ],
  providers: [
    ...resolvers,
    ...providers,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
