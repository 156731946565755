import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInOut } from 'asap-team/asap-tools';

import type {
  IExtraPrincipal, InsuranceInfo, Lead, LoanTermInfoResponse, LoanYear,
} from '@core/types';

// Consts
import { QUERY_PARAM, SIDEBAR_TAB_NAME } from '@consts/consts';
import { PREPARED_QUESTIONS } from '@consts/prepared-questions';
import { BLOCK, GoogleEvents, REFINANCE_OPTIONS_RESTRICTIONS } from '@consts/enums';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { DigestDataService } from '@core/helpers/digest-data/digest-data.service';
import { RightSidebarService } from '@core/helpers/right-sidebar/right-sidebar.service';
import { GAService } from '@core/helpers/ga/ga.service';

@UntilDestroy()
@Component({
  selector: 'loan-payment-tracker',
  templateUrl: './loan-payment-tracker.component.html',
  styleUrls: ['./loan-payment-tracker.component.sass'],
  animations: [fadeInOut()],
})
export class LoanPaymentTrackerComponent implements OnInit {

  @Input() lead: Lead;

  @Input() payData: IExtraPrincipal;

  @Input() insuranceInfo: InsuranceInfo;

  dataset: LoanYear[] = null;

  mortgageInfo: LoanTermInfoResponse;

  preparedQuestions: string[] = PREPARED_QUESTIONS.interest_loan;

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadService,
    private digestDataService: DigestDataService,
    private rightSidebarService: RightSidebarService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.getLoanTermInfo();

    this
      .digestDataService
      .report$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.getLoanTermInfo();
      });
  }

  get isPreview(): boolean {
    return this.route.snapshot.queryParamMap.has(QUERY_PARAM.PREVIEW);
  }

  private getLoanTermInfo(): void {
    this
      .leadService
      .getLoanTermInfo({
        uid: this.route.snapshot.params.uid,
        address_hash: this.lead.address_hash,
      })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((response: LoanTermInfoResponse) => {
        this.dataset = response.graphic_data;
        this.mortgageInfo = response;
      });
  }

  openSidebar(): void {
    if (this.isPreview) { return; }

    this.rightSidebarService.openHeaderSidebar(SIDEBAR_TAB_NAME.LOANS, this.lead);
    this.gaService.trackSimpleEvent(GoogleEvents.LOAN_DETAILS, 'loan');
  }

  private checkRefinanceRestriction(restriction: string): boolean {
    const isRequired: boolean | undefined = this.lead.blocks[
      BLOCK.EXTRA_PRINCIPAL
    ]?.requirements?.some(
      (checkedRestriction: string) => checkedRestriction === restriction,
    );

    return !!isRequired;
  }

  get isLoanRequired(): boolean {
    return this.checkRefinanceRestriction(
      REFINANCE_OPTIONS_RESTRICTIONS.LOAN_REQUIRED,
    );
  }

}
