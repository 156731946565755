import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import type { BuyAnotherHouse, Lead } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@Component({
  selector: 'buyer-qualification-certificate',
  templateUrl: './buyer-qualification-certificate.component.html',
  styleUrls: ['./buyer-qualification-certificate.component.sass'],
})
export class BuyerQualificationCertificateComponent implements OnChanges {

  @Input() houseInfo: BuyAnotherHouse;

  @Input() lead: Lead;

  buyerIQLink: any = null;

  downloadLink: any = null;

  constructor(
    private gaService: GAService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.houseInfo?.currentValue) {
      this.downloadLink = changes.houseInfo.currentValue.download_certificate_url;
    }

    if (changes?.lead) {
      this.buyerIQLink = `${environment.buyerIQReportUrl}/${changes.lead.currentValue.lender_username}/stepper?seller_lead_uid=${changes.lead.currentValue.id}`;
    }
  }

  downloadButtonClicked(event: MouseEvent & { target: { href: string }; preventDefault: () => void }): void {
    event.preventDefault();

    this.gaService.trackSimpleEvent(GoogleEvents.DOWNLOAD_PDF, 'affordability');

    const url: string = event.target.href;

    setTimeout(() => {
      window.location.href = url;
    }, 300);
  }

}
