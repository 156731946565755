<div class="modal-container video">
  <div class="modal-content">
    <div class="modal-close">
      <svg-icon src="assets/icons/close-outside.svg" [svgStyle]="svgStyle" (click)="close()"></svg-icon>
    </div>
    <div class="modal-body">
      <div class="video-container">
        <iframe src="https://player.vimeo.com/video/328077730?autoplay=1&amp;title=0&amp;byline=0&amp;portrait=0"
                width="100%"
                height="100%"
                frameborder="0"
                allow="autoplay"
                allowfullscreen="allowfullscreen"></iframe>
      </div>
    </div>
  </div>
</div>
