import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SimpleModalService } from 'ngx-simple-modal';

import type { Dictionary } from 'asap-team/asap-tools';
import type {
  Lead,
  MortgageInsuranceData,
  CancelMortgageState,
  CancelMortgageStateConfigItem,
  Loan,
} from '@core/types';
import {
  BLOCK,
  REFINANCE_OPTIONS_RESTRICTIONS,
} from '@consts/enums';

// Consts
import { QUERY_PARAM, SIDEBAR_TAB_NAME } from '@consts/consts';
import { CANCEL_MORTGAGE_STATE } from '@consts/mortgage-insurance';
import { PREPARED_QUESTIONS } from '@consts/prepared-questions';

// Components
import { MortgageHelpDialogComponent } from '@commons/dialogs/mortgage-help-dialog/mortgage-help-dialog.component';

// Services
import { RightSidebarService } from '@core/helpers/right-sidebar/right-sidebar.service';
import { LeadService } from '@core/services/lead/lead.service';
import { LoansService } from '@core/services/loans/loans.service';

@UntilDestroy()
@Component({
  selector: 'mortgage-insurance',
  templateUrl: './mortgage-insurance.component.html',
  styleUrls: ['./mortgage-insurance.component.sass'],
})
export class MortgageInsuranceComponent implements OnChanges {

  @Input() lead: Lead;

  insuranceData: MortgageInsuranceData;

  state: CancelMortgageState;

  stateConfig: CancelMortgageStateConfigItem;

  preparedQuestions: string[] = PREPARED_QUESTIONS.mortgage_insurance;

  constructor(
    private route: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    private rightSidebarService: RightSidebarService,
    private leadServices: LeadService,
    private loansService: LoansService,
  ) {}

  ngOnChanges(): void {
    if (this.lead?.id) {
      this
        .leadServices
        .getCancelMortgageInsurance(this.lead.id)
        .pipe(
          untilDestroyed(this),
        )
        .subscribe(
          (response: MortgageInsuranceData) => {
            this.insuranceData = response;
            this.setState(this.insuranceData);
          },
        );
    }
  }

  get isPreview(): boolean {
    return this.route.snapshot.queryParamMap.has(QUERY_PARAM.PREVIEW);
  }

  get isShowVerifyHomeValue(): boolean {
    return this.state === 'near' || this.state === 'above';
  }

  get isShowPmiEdit(): boolean {
    return !this.isPreview && this.lead?.loan_verified && (this.insuranceData?.loan_to_value < 78);
  }

  get sliderLimitStyle(): Dictionary {
    return { left: this.stateConfig?.limit_position };
  }

  get sliderProgressStyle(): Dictionary {
    return { 'background-image': this.stateConfig?.progress_style, width: `${this.insuranceData?.loan_to_value}%` };
  }

  private setState(data: MortgageInsuranceData): void {
    const ltv: number = data.loan_to_value;

    if (ltv < 78) {
      this.state = 'below';
    } else if (ltv < 80) {
      this.state = 'near';
    } else {
      this.state = 'above';
    }

    this.stateConfig = CANCEL_MORTGAGE_STATE[this.state];
  }

  openDialog(): void {
    this.simpleModalService.addModal(MortgageHelpDialogComponent);
  }

  openSidebar(): void {
    if (this.isPreview) { return; }

    this.rightSidebarService.openHeaderSidebar(SIDEBAR_TAB_NAME.LOANS, this.lead);
  }

  pmiEdit(): void {
    if (this.isPreview) { return; }

    const loan: Loan = this.lead.transactions.find((item: Loan) => item.primary);

    this.rightSidebarService.openHeaderSidebar(SIDEBAR_TAB_NAME.LOANS, this.lead, { loan, type: 'edit' });
    this.loansService.emitInsuranceEdit(true);
  }

  private checkRefinanceRestriction(restriction: string): boolean {
    const isRequired: boolean | undefined = this.lead.blocks[
      BLOCK.MORTGAGE_INSURANCE
    ]?.requirements?.some(
      (checkedRestriction: string) => checkedRestriction === restriction,
    );

    return !!isRequired;
  }

  get isLoanRequired(): boolean {
    return this.checkRefinanceRestriction(
      REFINANCE_OPTIONS_RESTRICTIONS.LOAN_CONVENTIONAL_REQUIRED,
    );
  }

}
