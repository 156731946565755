<div class="rent__info">
  <p class="rent__info--text text--lg text-center">
    <ng-container *ngIf="rentInfo?.interest_savings !== 0; else mortgagePayment">
      You can save<b>&#32;{{ rentInfo?.interest_savings | amount }}&#32;</b>on interest and pay off your home&#32;
      <b *ngIf="years">{{ years > 1 ? years + ' years' : years + ' year' }}&#32;</b>
      <span *ngIf="years && months">and&#32;</span>
      <b *ngIf="months">{{ months > 1 ? months + ' months' : months + ' month' }}&#32;</b>sooner 👍 if you applied this
      amount towards your payment
    </ng-container>
    <ng-template #mortgagePayment>
      If you paid that money towards your mortgage payment, you'll be
      short&nbsp;<b>{{ rentInfo?.decreased_monthly_payment | amount }}&nbsp;</b>on the monthly basis.
    </ng-template>
    <b class="tooltip-info" [tooltip]="tooltip.DISCLAIMER_INFO" [options]="tooltipOptions">*</b></p>
</div>
