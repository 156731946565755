<p class="text--lg text-center">
  Renting your home or purchasing a rental property can be a great way to build long term wealth
</p>
<div class="monthly-rent-estimator__value">
  <div class="monthly-rent-estimator__value--low text-left">
    <div class="number number--sm">{{ rentInfo?.rent_low | amount }}</div>
    <div class="legend-text">low</div>
  </div>
  <div class="monthly-rent-estimator__value--amount">
    <div class="number number--huge color-primary value">{{ rentInfo?.rent_amount | amount }}</div>
    <div class="legend-text label">month</div>
  </div>
  <div class="monthly-rent-estimator__value--high text-right">
    <div class="number number--sm">{{ rentInfo?.rent_high | amount }}</div>
    <div class="legend-text">high</div>
  </div>
</div>
<div class="div" *ngIf="rentInfo?.lead_financing_histories?.length > 1">
  <ng-container #chartContainer=""></ng-container>
</div>
<rent-info [rentInfo]="rentInfo"></rent-info>
