<div class="card loan-payment-tracker">
  <div class="card__content text-center">
    <div class="loan-payment-tracker__content" [class.restricted]="isLoanRequired">
      <div class="loan-payment-tracker__cost" *ngIf="mortgageInfo">
        <h2>What you have paid <br/> towards your mortgage so far</h2>
        <div class="number number--huge color-primary value" [counterAnimation]="mortgageInfo.current_loan_true_cost">
          0
        </div>
        <p class="text--lg">This includes
          <b>{{ mortgageInfo.current_principal | amount }}</b> you’ve paid towards principal and
          <b>{{ mortgageInfo.current_interest | amount }}</b> towards interest.
        </p>
        <p class="text--lg">So far you have<b> paid off {{ mortgageInfo.pay_off }}%</b> of your loan balance</p>
      </div>
      <lpt-graph *ngIf="!isLoanRequired" [dataset]="dataset"></lpt-graph>
      <div class="loan-payment-tracker__will-cost" *ngIf="mortgageInfo">
        <h3>Your home may end up costing you</h3>
        <div class="value number number--huge" [counterAnimation]="mortgageInfo?.loan_true_cost">0</div>
        <p class="text">Based on the loan you took out in {{ mortgageInfo?.loan_out_in }}</p>
        <button class="btn-link btn-link--primary" *ngIf="!isPreview" type="button" (click)="openSidebar()">
          View loan details
        </button>
      </div>
      <principal-paydown-forecaster [lead]="lead"
                                    [payData]="payData"></principal-paydown-forecaster>
    </div>
    <no-loan *ngIf="isLoanRequired" [reportId]="payData.id"></no-loan>
  </div>
  <ng-template hideInPreview>
    <send-question *ngIf="!isLoanRequired"
                   [userId]="lead.id"
                   [user]="lead.owner"
                   [eventCategory]="'loan'"
                   [preparedQuestions]="preparedQuestions"
                   [title]="'interest_loan'"></send-question>
  </ng-template>
</div>
