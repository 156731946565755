<div class="card transaction-estimator">
  <div class="card__content text-center">
    <h2 class="transaction-estimator__title">Cash you may be able to put in your pocket if you</h2>
    <div class="btn-tabs">
      <button class="btn-tabs__item"
              type="button"
              [class.active]="ofType(REPORT_TYPE.SELLING)"
              (click)="switchReportTypeTo(REPORT_TYPE.SELLING); trackTabClick(REPORT_TYPE.SELLING)">
        Sell your home
      </button>
      <button class="btn-tabs__item"
              type="button"
              [class.active]="ofType(REPORT_TYPE.REFINANCE)"
              (click)="switchReportTypeTo(REPORT_TYPE.REFINANCE); trackTabClick(REPORT_TYPE.REFINANCE)">
        Cash-out refi
      </button>
      <button class="btn-tabs__item"
              type="button"
              [class.active]="ofType(REPORT_TYPE.LINE)"
              (click)="switchReportTypeTo(REPORT_TYPE.LINE); trackTabClick(REPORT_TYPE.LINE)">
        Line
      </button>
    </div>
    <div
      class="transaction-estimator__value number number--huge color-primary"
      *ngIf="!isNegativeNetEquity"
      [counterAnimation]="report?.net_equity">0
    </div>
    <ng-container *ngIf="ofType(REPORT_TYPE.SELLING)">
      <ng-container *ngIf="!isNegativeNetEquity">
        <p class="text transaction-estimator__text">If you sold your home today, this is how much you could potentialy
          walk away with 😉</p>
      </ng-container>
      <ng-container *ngIf="isNegativeNetEquity">
        <div class="negative-equity-block">
          <div class="negative-equity-block__emoji">😳</div>
          <div class="negative-equity-block__text">Based on the estimated home value – if you decide to sell your<br/>home
            today, you may need to come up with
            approximately<br/><span>{{ (-report?.net_equity | amount) + ' ' }}</span>out of pocket to cover all the
            closing fees.
          </div>
          <br/>
          <div
            class="negative-equity-block__text">This information is based on your home value. You can request<br/>an
            updated home value report from {{ lead.owner.name}}</div>
          <verify-home-value [leadDetails]="lead"></verify-home-value>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="ofType(REPORT_TYPE.REFINANCE)">
      <ng-container *ngIf="!isNegativeNetEquity">
        <p class="text transaction-estimator__text">If you refinance your home today, this is how much you could
          potentially walk away with 😉</p>
      </ng-container>
      <ng-container *ngIf="isNegativeNetEquity">
        <div class="negative-equity-block">
          <div class="negative-equity-block__emoji">😐</div>
          <div class="negative-equity-block__text">In order for you to qualify for a cash-out-refinance, your<br/>
            loan-to-value ratio needs to fall below 80%. Unfortunately, at<br/>this time you do not have enough equity
            in your home.
          </div>
          <br/>
          <div class="negative-equity-block__text">This information is based on your home value. You can request<br/>an
            updated home value report from {{ lead.owner.name}}</div>
          <verify-home-value [leadDetails]="lead"></verify-home-value>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="ofType(REPORT_TYPE.LINE)">
      <p class="text transaction-estimator__text">If you take equity out of your home today, this is how much you could
        potentially walk away with 😉</p>
    </ng-container>
    <h3>See below how this is calculated</h3>
    <div class="report__content">
      <div class="report__head" [class.type_line]="ofType(REPORT_TYPE.LINE)">
        <div class="report__head--item">
          <div class="report__head--icon"><img [src]="imgFolder + '/icon-home.svg'"/></div>
          <div class="report__head--value number number--sm font-weight-600">
            <span class="bracket m-r-10" *ngIf="ofType(REPORT_TYPE.REFINANCE) || ofType(REPORT_TYPE.LINE)">(&nbsp;</span>
            {{ report?.home_value | amount }}
          </div>
          <div class="report__head--title legend-text">Estimated <br/> Home Value</div>
        </div>
        <div class="report__head--item symbol multiply" *ngIf="ofType(REPORT_TYPE.REFINANCE) || ofType(REPORT_TYPE.LINE)">×</div>
        <div class="report__head--item" *ngIf="ofType(REPORT_TYPE.REFINANCE) || ofType(REPORT_TYPE.LINE)">
          <div class="report__head--icon"><img [src]="imgFolder + '/icon-report.svg'"/></div>
          <div class="report__head--value number number--sm font-weight-600">
            80%
            <span class="bracket m-l-10" *ngIf="ofType(REPORT_TYPE.REFINANCE) || ofType(REPORT_TYPE.LINE)">&nbsp;)</span>
          </div>
          <div class="report__head--symbol multiply">×</div>
          <div class="report__head--title legend-text">Limit</div>
        </div>
        <div class="report__head--item symbol minus color-danger">—</div>
        <div class="report__head--item">
          <div class="report__head--icon"><img [src]="imgFolder + '/icon-loan.svg'"/></div>
          <div class="report__head--value number number--sm font-weight-600 color-danger">{{ report?.loan_balance | amount }}</div>
          <div class="report__head--symbol minus color-danger">—</div>
          <div class="report__head--title legend-text-wrap">
            <div class="report__head--title legend-text">Estimated <br/> Loan Balance</div>
            <div class="report__head--title legend-text" *ngIf="ofType(REPORT_TYPE.REFINANCE)">& Closing Fees</div>
          </div>
        </div>
        <div class="report__head--item symbol minus color-danger" *ngIf="ofType(REPORT_TYPE.SELLING)">—</div>
        <div class="report__head--item" *ngIf="ofType(REPORT_TYPE.SELLING)">
          <div class="report__head--icon">
            <img [src]="imgFolder + '/icon-report.svg'"/>
          </div>
          <div class="report__head--value number number--sm font-weight-600 color-danger">{{ report?.closing_fees_amount | amount }}</div>
          <div class="report__head--symbol minus color-danger">—</div>
          <div class="report__head--title legend-text">Estimated <br/> Closing Fees</div>
        </div>
        <div class="report__head--item symbol equal">=</div>
        <div class="report__head--item">
          <div class="report__head--icon">
            <img [src]="imgFolder + '/icon-cash.svg'"/>
          </div>
          <div class="report__head--value number number--sm font-weight-600 equity"
               [ngClass]="{'color-danger': isNegativeNetEquity}">{{ report?.net_equity | amount }}</div>
          <div class="report__head--symbol equal">=</div>
          <div class="report__head--title legend-text">Estimated <br/> Net Equity</div>
        </div>
      </div>
      <div class="report__body" *ngIf="!ofType(REPORT_TYPE.LINE)">
        <h3 class="report__title">Estimated Closing Fees</h3>
        <div class="report__empty" *ngIf="!report?.lead_fees">
          <ng-template hideInPreview>
            <div class="report__empty-fees">
              <h4 class="report__empty-title">Title fees and settlement fees are not calculated automatically.&#32; All
                fees are estimated, please contact {{ lead.lender?.name }} for actual fees.</h4>
              <button class="report__empty-btn" type="button" (click)="askFees()" [promiseBtn]="action">
                Request calculation
              </button>
            </div>
          </ng-template>
        </div>
        <div class="report__empty" *ngIf="report?.lead_fees">
          <p class="report__empty-fees-present__title">
            * All fees are estimated.
            <span *ngIf="lead.lender">
              Please contact {{ lead.lender?.name }}
              <span *ngIf="lead.lender?.company_name">at {{ lead.lender?.company_name }} </span>for actual fees.
            </span>
            <span *ngIf="lead.lender == null">
              Please contact {{ lead.agent?.name }}
              <span *ngIf="lead.agent?.company_name">at {{ lead.agent?.company_name }} </span>
              for actual fees.
            </span>
          </p>
          <button class="report__empty-fees-present__btn" type="button" (click)="askFees()" [promiseBtn]="action">
            Request calculation
          </button>
        </div>
        <div>
          <div class="report__body--group" *ngFor="let items of report?.lead_fees; let i = index">
            <div class="item--line title">
              <h4 class="name">{{ items?.group }}:</h4>
              <ng-container *ngIf="!i">
                <p class="other legend-text" *ngIf="ofType(REPORT_TYPE.SELLING)">OTHER</p>
                <p class="legend-text"
                   *ngIf="!ofType(REPORT_TYPE.REFINANCE)"
                   [class.buyer]="ofType(REPORT_TYPE.SELLING)"
                   [class.seller]="ofType(REPORT_TYPE.REFINANCE)">BUYER</p>
                <p class="seller legend-text" *ngIf="ofType(REPORT_TYPE.SELLING)">SELLER</p>
              </ng-container>
            </div>
            <div class="item--line" *ngFor="let item of items?.fees">
              <span class="name">{{ item.name }}</span>
              <span class="other" *ngIf="ofType(REPORT_TYPE.SELLING)">{{ item.other_amount | amount }}</span>
              <span [class.buyer]="ofType(REPORT_TYPE.SELLING)"
                    [class.seller]="ofType(REPORT_TYPE.REFINANCE)">{{ +item.buyer_amount | amount }}</span>
              <span class="seller" *ngIf="ofType(REPORT_TYPE.SELLING)">{{ item.seller_amount | amount }}</span>
            </div>
            <div class="item--line" *ngIf="i == report?.lead_fees.length - 1">
              <span class="text--lg name">Subtotal:</span>
              <span class="other" *ngIf="ofType(REPORT_TYPE.SELLING)">{{ total?.sumOther | amount }}</span>
              <span [class.buyer]="ofType(REPORT_TYPE.SELLING)" [class.seller]="ofType(REPORT_TYPE.REFINANCE)">
                {{ total?.sumBuyer | amount }}
              </span>
              <span class="seller" *ngIf="ofType(REPORT_TYPE.SELLING)">{{ total?.sumSeller | amount }}</span>
            </div>
          </div>
          <div class="report__body--group" *ngIf="report?.user_fees">
            <div class="item--line title">
              <h4 class="name">{{ report?.user_fees?.group }}:</h4>
            </div>
            <div class="item--line" *ngFor="let item of report?.user_fees?.commissions">
              <span class="name">{{ item.name }}</span>
              <span class="seller"> {{ item.value | amount }}</span>
            </div>
            <div class="item--line">
              <span class="text--lg name">Subtotal:</span>
              <span class="seller">{{ total?.sumUserFees | amount }}</span>
            </div>
          </div>
          <div class="report__body--group" *ngIf="isShowDownloadLink(currentReportType)">
            <div class="item--line last table-last">
              <div class="transaction-estimator__download">
                <a class="btn-link btn-link--primary" (click)="downloadButtonClicked($any($event))" [href]="getDownloadLink(currentReportType)">
                  <svg-icon src="assets/components/transaction-estimator/icon-download.svg"></svg-icon>
                  Download Estimated Closing
                </a>
              </div>
              <div class="transaction-estimator__footer text-center" *ngIf="!ofType(REPORT_TYPE.LINE)">
                <div class="disclaimer" *ngIf="report?.disclaimer">
                  <div class="disclaimer-text">
                    DISCLAIMER:  {{ report?.disclaimer | slice:0:countChart }}
                    <span *ngIf="!isShowDisclaimer">...</span>
                    <span class="disclaimer__btn--show m-l-5"
                          *ngIf="!isShowDisclaimer"
                          (click)="showDisclaimer(report)">
                      Read more.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template hideInPreview>
    <send-question class="report m-t-40"
                   [userId]="lead.id"
                   [user]="currentReportType === 'selling' ? lead.owner : lead.lender"
                   [title]="'report_' + currentReportType"
                   [eventCategory]="'net-sheet'"
                   [preparedQuestions]="preparedQuestions">
    </send-question>
  </ng-template>
</div>
