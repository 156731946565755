import { Component, Input } from '@angular/core';

// Consts
import { environment } from 'environments/environment';

@Component({
  selector: 'locked-refinance',
  templateUrl: './locked-refinance.component.html',
  styleUrls: ['../shared/shared-styles.sass'],
})
export class LockedRefinanceComponent {

  @Input() reportId: string;

  get link(): string {
    return `${environment.soaUrl}/homeowners/lead/${this.reportId}/net-sheet?edit_loan_info=true`;
  }

}
