import { Component } from '@angular/core';

@Component({
  selector: 'report-loading',
  templateUrl: './report-loading.component.html',
  styleUrls: [
    '../loading-screen.component.sass',
    '../../transaction-estimator/transaction-estimator.component.sass',
  ],
})
export class ReportLoadingComponent {}
