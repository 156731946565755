import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

// Services
import { ThemeService } from '@core/helpers/theme/theme.service';

@Injectable()
export class LogoResolve implements Resolve<string> {

  constructor(
    private themeService: ThemeService,
  ) {}

  resolve(): Observable<string> {
    return this.themeService.srcLogo.pipe(take(1));
  }

}
