import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';

import type { Dictionary } from 'asap-team/asap-tools';
import type { RentInfo, AirbnbInfo } from '@core/types';

// Consts
import { TOOLTIP, TOOLTIP_CONFIG } from '@consts/consts';

@Component({
  selector: 'rent-info',
  templateUrl: './rent-info.component.html',
  styleUrls: ['./rent-info.component.sass'],
})
export class RentInfoComponent implements OnChanges {

  @Input() rentInfo: RentInfo | AirbnbInfo;

  years: number;

  months: number;

  tooltip: Dictionary<string> = TOOLTIP;

  tooltipOptions: Dictionary<any> = TOOLTIP_CONFIG;

  ngOnChanges(changes: SimpleChanges): void {
    const savedRent: SimpleChange = changes.rentInfo;

    if (savedRent && savedRent.currentValue) {
      this.years = Math.floor(this.rentInfo?.months_savings / 12);
      this.months = this.rentInfo?.months_savings % 12;
    }
  }

}
