<div class="card equity-tracker">
  <div class="card__header text-center">
    <div class="card__header-user">
      <avatar-placeholder class="size-80" [user]="lead?.owner"></avatar-placeholder>
      <img class="logo" [src]="lead.report_logo || 'assets/components/header/home_iq/logo.svg'"/>
    </div>
    <p class="equity-tracker__header-title text text-italic">
      Hello {{ lead?.owner_name }}! Below is an updated <i>my</i> HomeIQ report
      for {{ getCorrectAddress(lead?.full_address) }}
    </p>
  </div>
  <div class="card__content text-center">
    <h2 class="card__content-title">The estimated equity in your home is</h2>
    <p class="number number--huge color-primary" [counterAnimation]="info?.gross_equity">0</p>
    <div class="diagram__stats">
      <p class="text" *ngIf="lead?.growth_equity !== 0">
        Looks like you have
        <b><span *ngIf="!isLess(lead?.growth_equity)">{{ 'gained' }}&nbsp;</span><span *ngIf="isLess(lead?.growth_equity)">{{ 'lost' }} </span>{{ lead?.growth_equity | formatNumber: '0' :true }}
        </b> since your purchase<i class="icon" *ngIf="!isLess(lead?.growth_equity)">👍</i>
      </p>
    </div>
    <section class="equity-tracker__preview">
      <div class="equity-tracker__preview-container">
        <div class="diagram text-center" *ngIf="isShowFinancingDiagram && checkPreviewState('diagram')">
          <ng-container #chartContainer></ng-container>
        </div>
        <div *ngIf="checkPreviewState('image')" class="equity-tracker__preview-image">
          <img *ngIf="lead.image" [src]="lead.image"/>
          <google-map *ngIf="!lead.image && gmAPIReady"
                      width="100%"
                      height="115%"
                      [center]="{ lat: lead.lat, lng: lead.lng }"
                      [mapTypeId]="gmMapTypeId"
                      [options]="{ disableDefaultUI: true, disableDoubleClickZoom: true, gestureHandling: 'none' }"
                      [zoom]="19">
            <map-marker [options]="gmMarkerOptions" [position]="{ lat: lead.lat, lng: lead.lng }"></map-marker>
          </google-map>
        </div>
      </div>
      <div class="equity-tracker__preview-controls" *ngIf="isShowFinancingDiagram">
        <button class="equity-tracker__preview-btn"
                type="button"
                (click)="setPreviewState('diagram')"
                [class.active]="checkPreviewState('diagram')">
          <img src="assets/components/equity-tracker/chart-icon.svg"/></button>
        <button class="equity-tracker__preview-btn"
                type="button"
                (click)="setPreviewState('image')"
                [class.active]="checkPreviewState('image')">
          <img [src]="lead.image ? lead.image : 'assets/components/equity-tracker/home-icon.svg'"/></button>
      </div>
    </section>
    <div class="estimate">
      <h3 class="estimate__title">We estimate your home to be worth</h3>
      <div class="estimate__calc">
        <div class="number number--huge amount" [counterAnimation]="info?.home_value">0</div>
        <div class="estimate__calc-percent" *ngIf="!!info?.current_diff_valuation" [ngClass]="getTrendClass(info)">
          <div class="icon icon-arrow-down"
               *ngIf="isLess(info?.current_diff_valuation)"
               [inlineSVG]="'assets/icons/arrow_down.svg'"></div>
          <div class="icon icon-arrow-up"
               *ngIf="!isLess(info?.current_diff_valuation)"
               [inlineSVG]="'assets/icons/arrow_up.svg'"></div>
        </div>
      </div>
      <h3 class="estimate__subtitle" *ngIf="info?.current_diff_valuation !== 0">
        That is a <span>{{info?.current_diff_valuation }}
        % {{ isLess(info?.current_diff_valuation) ? 'decrease' : 'increase' }}</span> since your
        purchase {{ isLess(info?.current_diff_valuation) ? '' : '👍' }}
      </h3>
      <home-value-calculator *ngIf="lead.blocks.home_improvement.enabled" [lead]="lead"></home-value-calculator>
    </div>
  </div>
  <ng-template hideInPreview>
    <send-question [userId]="lead.id"
                   [user]="lead.owner"
                   [eventCategory]="'home-value'"
                   [preparedQuestions]="preparedQuestions"
                   [title]="'estimated_equity'">
      <verify-home-value [leadDetails]="lead"
                         [title]="'You can request an updated home value report from ' + lead.owner.name"></verify-home-value>
    </send-question>
  </ng-template>
</div>
