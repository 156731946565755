<div class="not-found">
  <div class="not-found__logo">
    <svg-icon src="assets/components/header/home_iq/logo.svg"></svg-icon>
  </div>
  <div class="not-found__title" [innerHTML]="errorText"></div>
  <div class="not-found__bg">
    <div class="not-found__bg--town">
      <div class="not-found__bg--town-image" #town></div>
    </div>
    <div class="not-found__bg--footer"></div>
  </div>
  <div class="not-found__lama" #lama></div>
</div>
