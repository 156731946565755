import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { get, forEach } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

// Consts
import { THEME } from '@consts/theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {

  srcLogo: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  private setTheme(theme: {}): void {
    forEach(theme, (value: string, key: string) => this.document.documentElement.style.setProperty(`--${key}`, value));
  }

  set(): void {
    this.setTheme(get(THEME, 'home_iq'));
    this.srcLogo.next('assets/components/header/home_iq/logo.svg');
  }

}
