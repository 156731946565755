/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */

import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment: any = {
  production: false,
  name: 'dev',
  api: 'https://api.dev.myhomeiq.report/api',
  soaUrl: 'https://app.dev.myhomeiq.report',
  reportLandingUrl: 'https://dev.myhomeiq.com',
  buyerIQReportUrl: 'https://dev.buyerprequalify.com',
  mapsApiKey: 'AIzaSyCyPyVFYCWyES6Nu5FlNOJbL_4nJb0KWiE',
  sentryDSN: 'https://3cb58419c4174b059a60e315038026e4@sentry.io/1832854',
  sentryWhitelist: [
    'http://dev.myhomeiq.report',
    'https://dev.myhomeiq.report',
  ],
  privacy_policy_link: 'https://dev.myhomeiq.com/privacy-policy/',
  terms_conditions_link: 'https://dev.myhomeiq.com/terms-of-use/',
  firebase: {
    apiKey: 'AIzaSyCnM_Di4IDDV31H4BduI1hinMJBLSrHoco',
    authDomain: 'analog-arbor-213518.firebaseapp.com',
    databaseURL: 'https://analog-arbor-213518.firebaseio.com',
    projectId: 'analog-arbor-213518',
    storageBucket: 'analog-arbor-213518.appspot.com',
    messagingSenderId: '43232078976',
  },
  firebaseCollection: 'dev',
  analytics: {
    bypassTracking: false,
    env: 'dev',
    gtm: 'GTM-P753L4VF',
  },
};
