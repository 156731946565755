import { Component, Input, OnInit } from '@angular/core';

import { PREPARED_QUESTIONS } from '@consts/prepared-questions';

import type { AirbnbInfo, Lead, RentInfo } from '@core/types';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

type RentState = 'monthly' | 'airbnb';

@Component({
  selector: 'rent-estimator',
  templateUrl: './rent-estimator.component.html',
  styleUrls: ['./rent-estimator.component.sass'],
})
export class RentEstimatorComponent implements OnInit {

  @Input() lead: Lead;

  @Input() isShowRentInfo: boolean;

  @Input() isShowAirbnbInfo: boolean;

  @Input() rentInfo: RentInfo;

  @Input() airbnbInfo: AirbnbInfo;

  rentState: RentState;

  preparedQuestions: string[] = PREPARED_QUESTIONS.airbnb;

  constructor(
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    if (this.isShowRentInfo) {
      this.rentState = 'monthly';

      return;
    }

    if (this.isShowAirbnbInfo) {
      this.rentState = 'airbnb';

      return;
    }

    this.rentState = 'monthly';
  }

  checkState(state: RentState): boolean {
    return this.rentState === state;
  }

  setState(state: RentState): void {
    this.sendEvent(state);

    if (this.rentState === state) { return; }

    this.rentState = state;
  }

  sendEvent(state: RentState): void {
    this.gaService.trackSimpleEvent(state === 'monthly' ? GoogleEvents.LONG_RENT_TAB : GoogleEvents.SHORT_RENT_TAB, 'rent');
  }

}
