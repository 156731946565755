import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dictionary, getInitials } from 'asap-team/asap-tools';

import type { Lead } from '@core/types';

// Consts
import { QUERY_PARAM, SIDEBAR_TAB_NAME } from '@consts/consts';

// Utils
import { getCorrectAddress } from '@core/utils/utils';

// Services
import { RightSidebarService } from '@core/helpers/right-sidebar/right-sidebar.service';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent {

  @Input() lead: Lead;

  getCorrectAddress: (address: string) => string = getCorrectAddress;

  SIDEBAR_TAB_NAME: Dictionary = SIDEBAR_TAB_NAME;

  constructor(
    private rightSidebarService: RightSidebarService,
    private route: ActivatedRoute,
    private gaService: GAService,
  ) {}

  get isPreview(): boolean {
    return this.route.snapshot.queryParamMap.has(QUERY_PARAM.PREVIEW);
  }

  getInitials(name: string): string {
    return getInitials(name);
  }

  openSidebar(): void {
    this.gaService.trackSimpleEvent(GoogleEvents.SETTINGS, 'settings');

    if (this.isPreview) { return; }

    this.rightSidebarService.openHeaderSidebar(SIDEBAR_TAB_NAME.SETTINGS, this.lead);
  }

}
