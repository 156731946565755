import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary } from 'asap-team/asap-tools';

import type { Lead, HomeValueCalcData, HomeValueCalcResponse } from '@core/types';

// Consts
import { DEFAULT_HOME_VALUE_CALC_DATA } from '@consts/consts';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@UntilDestroy()
@Component({
  selector: 'home-value-calculator',
  templateUrl: './home-value-calculator.component.html',
  styleUrls: ['./home-value-calculator.component.sass'],
})
export class HomeValueCalculatorComponent implements OnInit {

  @Input() lead: Lead;

  updates$: BehaviorSubject<HomeValueCalcData>;

  range: FormControl<number> = new FormControl<number>(null);

  lineStyle: string;

  uid: string;

  calcData: HomeValueCalcData = DEFAULT_HOME_VALUE_CALC_DATA;

  home_value: number = 0;

  home_value_shift: number = 0;

  isOpen: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private leadService: LeadService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.initDefaultValues();
    this.subscribes();
  }

  private subscribes(): void {
    this
      .updates$
      .pipe(
        untilDestroyed(this),
        debounceTime(800),
        switchMap((payload: HomeValueCalcData) => this.leadService.calculateHomeValue({
          payload, uid: this.uid, address_hash: this.lead.address_hash,
        })),
      )
      .subscribe(
        (result: HomeValueCalcResponse) => {
          const { adjusted_home_value, adjusted_home_value_shift } = result;

          this.home_value = adjusted_home_value;
          this.home_value_shift = adjusted_home_value_shift;
        },
      );

    this
      .range
      .valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
      )
      .subscribe(
        (result: number) => {
          let conditions: string;

          switch (result) {
            case 0: {
              conditions = 'poor';
              break;
            }
            case 1: {
              conditions = 'average';
              break;
            }
            case 2: {
              conditions = 'superior';
              break;
            }
            default: {
              break;
            }
          }

          this.updateValues({ key: 'conditions', value: conditions });
        },
      );
  }

  private initDefaultValues(): void {
    this.uid = this.activatedRoute.snapshot.params.uid;
    this.home_value = this.lead.home_value;
    this.range.patchValue(1);
    this.setLineStyle(1);
    this.updates$ = new BehaviorSubject<HomeValueCalcData>(this.calcData);
  }

  get getHomeValueShift(): string {
    if (this.home_value_shift === 0) { return ''; }

    const value: number = this.home_value_shift / 1000;

    if (value < 1 && value > -1) {
      return `${value}`;
    }

    return `${Math.round(value)}`;
  }

  get rangeInputStyle(): Dictionary {
    return { background: this.lineStyle };
  }

  setLineStyle(count: number): void {
    // 50 = 100% / max steps of range input (2)
    const value: number = count * 50;

    this.lineStyle = `linear-gradient(to right, #021656 0%, #125dc3 ${value}%, #d3e1eb ${value}%, #d3e1eb 100%)`;
  }

  getRangeHeaderClass(index: number): Dictionary<boolean> {
    return { active: this.range.value === index };
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
    this.gaService.trackSimpleEvent(GoogleEvents.IMPROVEMENT_CALC, 'home_value');
  }

  updateValues(newValue: { key: string; value: string }): void {
    this.calcData[newValue.key] = newValue.value;
    this.updates$.next(this.calcData);
  }

  rangeInputClicked(): void {
    this.gaService.trackSimpleEvent(GoogleEvents.USE_IMPROVEMENT_CALC, 'home_value');
  }

}
