<div class="modal-container intro">
  <div class="modal-content">
    <div class="modal-close" [inlineSVG]="'assets/icons/close_circle.svg'" (click)="close()"></div>
    <div class="modal-body">
      <h2 class="intro__title">Change email address</h2>
      <form [formGroup]="form">
        <div class="form-group">
          <label class="form-label-text">New email address*</label>
          <input class="form-control" type="text" formControlName="email" />
          <form-error class="input-message error" name="email" [model]="formErrors.email"></form-error>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <iq-button class="block"
                 type="button"
                 label="Update email"
                 color="orange"
                 [disabled]="!form.valid"
                 [promiseBtn]="action$"
                 (click)="save()"></iq-button>
    </div>
  </div>
</div>
