import { FormErrors } from 'asap-team/asap-tools';

export const loanFormErrors: FormErrors = {
  loan_type: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  loan_amount: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  interest_rate: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  loan_term: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  insurance_amount: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
