<div class="header">
  <div class="header__logo">
    <div class="sk-load__bar mw-106 h-24"></div>
  </div>
  <div class="header__address">
    <div class="sk-load__bar w-166"></div>
  </div>
</div>
<div class="container">
  <div class="card home-value">
    <div class="card__header text-center">
      <div class="card__header-user">
        <div class="sk-load__spinner">
          <div class="sk-load__spinner-icon"><img src="/assets/components/loading-screens/spinner.svg"/></div>
          <div class="sk-load__spinner-counter">
            <div class="loading-dots">
              <div class="loading-dots__item loading-dots__item--1"></div>
              <div class="loading-dots__item loading-dots__item--2"></div>
              <div class="loading-dots__item loading-dots__item--3"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card__header-title">
        <div class="loading__title">{{ title }}
          <div class="loading__title-animation" [style.maxWidth]="progress + '%'">{{ title }}</div>
        </div>
        <div class="loading__subtitle">DO NOT CLOSE THE PAGE</div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="card__content text-center">
      <h2 class="card__content-title">Estimated equity in your home</h2>
      <p class="number number__large sk-load__text">$00,000</p>
      <div class="sk-load__bar center mw-260"></div>
      <div class="sk-load__chart-image"><img src="/assets/components/loading-screens/chart-1.svg"/></div>
      <h3>The estimated value in your home</h3>
      <p class="number number__large sk-load__text m-t-18 m-b-18">$000,000</p>
      <div class="sk-load__bar center mw-260 m-b-54"></div>
      <div class="sk-load__bar center mw-400 m-b-18"></div>
      <div class="sk-load__bar center mw-340 m-b-32"></div>
      <div class="sk-load__bar sk-load__bar--round center mw-205 h-56"></div>
    </div>
  </div>
  <report-loading></report-loading>
  <div class="card insurance">
    <div class="card__content">
      <h2 class="insurance__title text-center">True cost of your mortgage</h2>
      <p class="number number__large sk-load__text text-center m-b-18">$000,000</p>
      <div class="sk-load__bar center mw-400 m-b-18"></div>
      <div class="sk-load__bar center mw-340 m-b-56"></div>
      <div class="sk-load__chart-image"><img src="/assets/components/loading-screens/chart.svg"/></div>
      <h3 class="text-center m-t-72 m-b-15">Your home may end up costing you</h3>
      <p class="number number__large sk-load__text text-center m-b-15">$000,000</p>
      <div class="sk-load__bar center mw-260 m-b-18"></div>
      <div class="sk-load__bar center mw-205 m-b-56"></div>
      <div class="divider"></div>
      <h2 class="insurance__title text-center m-t-45 m-b-32">See how much you can save if you paid a little more each
        month</h2>
      <div class="sk-load__bar center mw-400 m-b-18"></div>
      <div class="sk-load__bar center mw-430 m-b-18"></div>
      <div class="sk-load__bar center mw-340 m-b-18"></div>
    </div>
  </div>
  <div class="card interest">
    <div class="card__content text-center">
      <h2 class="interest__title m-b-25">Interest you will end up paying</h2>
      <div class="interest__descr">
        <div class="sk-load__bar center mw-400 m-b-15"></div>
        <div class="sk-load__bar center mw-400 m-b-15"></div>
        <div class="sk-load__bar center mw-300 m-b-56"></div>
      </div>
      <h3 class="interest__subtitle m-b-10">Possible refinance opportunities available for you</h3>
      <div class="interest__subdescr">Based on current national rates.
        <div class="interest__wrap">
          <div class="interest__col" *ngFor="let item of [15, 30]">
            <div class="interest__head p-l-40 p-r-40">
              <h3 class="interest__col--head-text">{{ item }} Year</h3>
              <div class="sk-load__bar center mw-130 m-t-15 m-b-5"></div>
              <div class="number sk-load__text interest__col--number m-b-10">$00,000</div>
            </div>
            <ul class="interest__list p-t-10 p-l-30 p-r-30">
              <div class="sk-load__bar center mw-154 m-b-15"></div>
              <div class="sk-load__bar center mw-154 m-b-15"></div>
              <div class="sk-load__bar center mw-114"></div>
            </ul>
          </div>
        </div>
      </div>
      <div class="sk-load__bar sk-load__bar--round center mw-166 h-40"></div>
    </div>
  </div>
  <div class="card zillow">
    <div class="card__content">
      <div class="text-center m-b-25" [style.background]="'#fff'">
        <h2 class="zillow__title">What you can rent your home for</h2>
      </div>
      <p class="number number__large sk-load__text text-center m-b-40">$000,000</p>
      <div class="sk-load__bar center mw-400 m-b-18"></div>
      <div class="sk-load__bar center mw-340 m-b-40"></div>
      <div class="sk-load__bar sk-load__bar--round center mw-166 h-40 m-t-30"></div>
    </div>
  </div>
  <div class="loading-agent">
    <div class="loading-agent__container">
      <div class="loading-agent__description text-center">
        <div class="sk-load__circle"></div>
        <div class="sk-load__bar mw-106 h-16 white m-b-15"></div>
        <div class="sk-load__bar mw-166 white m-b-15"></div>
        <div class="sk-load__bar mw-166 white"></div>
      </div>
    </div>
  </div>
</div>
