import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { Dictionary } from 'asap-team/asap-tools';
import type { IntroModel } from './intro.model';

@Component({ templateUrl: './intro-dialog.component.html' })
export class IntroDialogComponent extends SimpleModalComponent<IntroModel, boolean> implements IntroModel {

  icon: string;

  title: string;

  message: string;

  svgStyle: Dictionary = { fill: '#fff' };

}
