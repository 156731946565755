<div class="pay-more-range">
  <div class="pay-more-range__input">
    <div class="label" [style.left.%]="labelPosition" [style.marginLeft.px]="labelMLPosition">
      <span>{{ (count[range.value] || count[1]) | amount }}</span>
    </div>
    <input class="slider"
           type="range"
           [min]="0"
           [max]="countLength"
           [formControl]="range"
           [ngStyle]="sliderStyle"
           (click)="sliderClicked()"
           (input)="changeValue(range.value)"/></div>
  <ng-container *ngIf="!loading">
    <p class="form-value-text pay-more-range__text">You could save
      <b>{{ payData?.interest_savings | amount }}</b> on interest and potentially pay off <br/> your home
      <b>{{ humanizeSavingDuration(payData?.months_savings) }}</b> sooner! 👍
    </p>
  </ng-container>
  <div class="loading-dots loading-dots--lg" *ngIf="loading">
    <div class="loading-dots__item loading-dots__item--1"></div>
    <div class="loading-dots__item loading-dots__item--2"></div>
    <div class="loading-dots__item loading-dots__item--3"></div>
  </div>
</div>
