import { Component, Input } from '@angular/core';
import { getInitials } from 'asap-team/asap-tools';

import type { User } from '@core/types';

@Component({
  selector: 'avatar-placeholder',
  templateUrl: './avatar-placeholder.component.html',
  styleUrls: ['./avatar-placeholder.component.sass'],
})
export class AvatarPlaceholderComponent {

  @Input() user: User;

  getInitials(name: string): string {
    return getInitials(name);
  }

}
