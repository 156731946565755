<div class="wrap" *ngIf="!dataset">
  <div class="chart ghost">
    <div class="item item-future" *ngFor="let item of arrayElements">
      <div class="item-inner">
        <div class="interest"></div>
        <div class="principal"></div>
      </div>
    </div>
  </div>
</div>
<div class="wrap" *ngIf="dataset && dataset.length" @fadeInOut>
  <div class="legend">
    <div class="legend__item legend__item--principal-repaid">Principal Repaid</div>
    <div class="legend__item legend__item--interest-repaid">Interest Repaid</div>
    <div class="legend__item legend__item--principal-remaining">Principal Remaining</div>
    <div class="legend__item legend__item--interest-remaining">Interest Remaining</div>
  </div>
  <div class="chart" #chartElement>
    <div class="item"
         *ngFor="let set of dataset; trackBy: trackByFn; let idx = index"
         [ngClass]="getItemClass(set)"
         (mouseover)="mouseover($event, set, idx)"
         (mouseout)="mouseout()">
      <div class="item-inner">
        <div class="interest" [ngStyle]="getBarHeight(set, 'current_interest')"></div>
        <div class="principal" [ngStyle]="getBarHeight(set, 'current_principal')"></div>
      </div>
    </div>
    <div class="tip" #tooltip="">
      <div class="tip__title">Year {{tip?.index + 1 }} ({{ tip?.year }})</div>
      <div class="tip__legend">
        <div class="tip__legend-item tip__legend-item--interest"
             [class.tip__legend-item--future]="tip?.future">{{ tip?.interest_label }}
          :&#32;<span>{{ tip?.current_interest | amount }}</span></div>
        <div class="tip__legend-item tip__legend-item--principal"
             [class.tip__legend-item--future]="tip?.future">{{ tip?.principal_label }}
          :&#32;<span>{{ tip?.current_principal | amount }}</span></div>
      </div>
    </div>
  </div>
  <lpt-labels [dataset]="dataset" [chartElement]="chartElement"></lpt-labels>
</div>
