import { range } from 'lodash-es';

export const DOWN_PAYMENT_RANGE_VALUES: number[] = [
  ...range(5000, 105000, 5000),
  ...range(110000, 210000, 10000),
  ...range(225000, 325000, 25000),
  ...range(350000, 550000, 50000),
  ...range(600000, 1100000, 100000),
  ...range(1200000, 2200000, 200000),
  ...range(2300000, 2700000, 200000),
];
