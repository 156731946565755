import { Injectable } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';

// Types
import { Lead, Loan } from '@core/types';

// Consts
import { SIDEBAR_TAB_NAME } from '@consts/consts';
import { RIGHT_SIDEBAR_DEFAULTS } from '@consts/sidebar';

// Components
import { RightSidebarComponent } from '@commons/dialogs/right-sidebar/right-sidebar.component';

@Injectable({ providedIn: 'root' })
export class RightSidebarService {

  constructor(
    private simpleModalService: SimpleModalService,
  ) {}

  openHeaderSidebar(
    activeTab: string,
    lead: Lead,
    tabInitMode?: { type: string; loan?: Loan },
  ): void {
    this.simpleModalService.addModal(
      RightSidebarComponent,
      {
        payload: {
          activeTab,
          tabs: [
            {
              name: SIDEBAR_TAB_NAME.LOANS,
              title: 'My Loans',
              icon: '/assets/icons/sidebar-loan.svg',
              component: {
                load: (): any => import('@commons/sidebar/my-loans/my-loans.component')
                  .then((c: any) => c.MyLoansComponent),
              },
              disabled: false,
              payload: { lead, tabInitMode },
            },
            {
              name: SIDEBAR_TAB_NAME.HOME_DETAILS,
              title: 'Home Details',
              icon: '/assets/icons/sidebar-home.svg',
              component: {
                load: (): any => import('@commons/sidebar/home-details/home-details.component')
                  .then((c: any) => c.HomeDetailsComponent),
              },
              disabled: false,
              payload: { lead },
            },
            {
              name: SIDEBAR_TAB_NAME.SETTINGS,
              title: 'Account Settings',
              icon: '/assets/icons/sidebar-settings.svg',
              component: {
                load: (): any => import('@commons/sidebar/account-settings/account-settings.component')
                  .then((c: any) => c.AccountSettingsComponent),
              },
              disabled: false,
              payload: { lead },
            },
          ],
        },
        component: { load: (): any => import('@commons/lazy-tabs/lazy-tabs.component').then((c: any) => c.LazyTabsComponent) },
      },
      RIGHT_SIDEBAR_DEFAULTS,
    );
  }

}
