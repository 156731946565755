import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { each, every } from 'lodash-es';

// Consts
import { environment } from 'environments/environment';
import { GoogleEvents } from '@consts/enums';
import { TrackingScriptsService } from '../tracking/tracking-scripts.service';

declare global {
  interface Window {
    gtag: (command: string, ...params: any[]) => Promise<void>;
    dataLayer: any[];
  }
}

@Injectable({ providedIn: 'root' })
export class GAService {

  paramsStore: any = { dimension1: environment.name };

  private readonly bypassTracking: string = environment.analytics.bypassTracking;

  constructor(
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private trackingScriptsService: TrackingScriptsService,
  ) {}

  init(paramMap: ParamMap): void {
    if (this.bypassTracking) { return; }

    // Init GoogleAnalytics, GoogleTagManager, FacebookPixel, etc,,,
    this.trackingScriptsService.setupGoogleTagManager();
    this.angulartics2GoogleTagManager.startTracking();

    if (!paramMap) { return; }

    const requiredParams: string[] = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'cid',
    ];

    const isReqiuredParamsPresent: boolean = every(
      requiredParams,
      (param: string) => paramMap.has(param),
    );

    if (!isReqiuredParamsPresent) { return; }

    requiredParams.forEach((value: string) => {
      this.paramsStore[value] = paramMap.get(value);
    });

    const tag: string = paramMap.get('tag');

    if (tag) {
      this.paramsStore.dimension2 = tag;
    }

    each(this.paramsStore, (value: any, key: any) => {
      window.gtag('set', key, value);
    });
  }

  trackPageForUrl(url: string): void {
    if (!this.bypassTracking) {
      // (Tracking providers) Please, place calls for page view here.
      this.angulartics2GoogleTagManager.pageTrack(url);
    }
  }

  trackSimpleEvent(event: GoogleEvents, category?: string, label?: string): void {
    window.dataLayer.push({
      event,
      ...(category && { category }),
      ...(label && { label }),
    });
  }

}
