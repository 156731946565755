<div class="modal-container help">
  <div class="modal-content">
    <div class="modal-close" [inlineSVG]="'assets/icons/close_circle.svg'" (click)="close()"></div>
    <div class="modal-back" (click)="close()">
      <img src="/assets/components/my-loans/arrow_back.svg"/>
    </div>
    <div class="modal-body">
      <h2 class="m-t-40 m-b-48">How long does Conventional PMI?</h2>
      <p class="m-b-48 f-20-normal f-c-gull-gray">Private mortgage insurance is an insurance policy used in conventional
        loans that protects lenders from the risk of default and foreclosure and allows buyers who cannot make a
        significant down payment (or those who choose not to) to obtain
        mortgage financing at affordable rates.<br/><br/>terminated (when your loan balance is scheduled to reach 78
        percent of the original value of your home)<br/><br/>canceled at your request because your equity in the home
        reaches 20 percent
        of the purchase price or appraised value (your lender will approve a PMI cancelation only if you have adequate
        equity and have a good payment history)</p>
      <div class="help-table">
        <div class="help-col">
          <div class="help-col__title">Loan Term</div>
          <div class="help-col__value">15,20,25,30 years</div>
          <div class="help-col__value">15,20,25,30 years</div>
          <div class="help-col__value">15,20,25,30 years</div>
        </div>
        <div class="help-col">
          <div class="help-col__title">Downp.</div>
          <div class="help-col__value">0 – 20%</div>
          <div class="help-col__value">0 – 20%</div>
          <div class="help-col__value">0 – 20%</div>
        </div>
        <div class="help-col">
          <div class="help-col__title">LTV</div>
          <div class="help-col__value">0% — 78%</div>
          <div class="help-col__value">78% — 80%</div>
          <div class="help-col__value">80% — 100%</div>
        </div>
        <div class="help-col">
          <div class="help-col__title">Cacel condition</div>
          <div class="help-col__value">Insurance has to be canceled</div>
          <div class="help-col__value">Insurance can be canceled</div>
          <div class="help-col__value">Insurance can’t be canceled</div>
        </div>
      </div>
    </div>
  </div>
</div>
