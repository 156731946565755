import { Injectable } from '@angular/core';

// Consts
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class EnvProviderService {

  getEnvironment(): any { return environment; }

}
