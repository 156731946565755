import { Component, Input } from '@angular/core';

import type { User } from '@core/types';

@Component({
  selector: 'user-disclaimer',
  templateUrl: './user-disclaimer.component.html',
  styleUrls: ['./user-disclaimer.component.sass'],
})
export class UserDisclaimerComponent {

  @Input() user: User;

}
