import { DOCUMENT } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { fromEvent, Subject, merge, Observable } from 'rxjs';
import {
  takeUntil,
  mergeMap,
  map,
  distinctUntilChanged,
  debounceTime,
  filter,
} from 'rxjs/operators';

import type { DownPaymentData } from '@core/types';

// Consts
import { DOWN_PAYMENT_RANGE_VALUES } from '@consts/down-payment';

// Services
import { LeadService } from '@core/services/lead/lead.service';

type Range = { amount: number; coord: number };

@UntilDestroy()
@Component({
  selector: 'downpayment-slider',
  templateUrl: './downpayment-slider.component.html',
  styleUrls: ['./downpayment-slider.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownpaymentSliderComponent implements OnInit {

  @ViewChild('sliderContainer', { static: true }) private sliderContainer: ElementRef<HTMLDivElement>;

  @ViewChild('sliderThumb', { static: true }) private sliderThumb: ElementRef<HTMLDivElement>;

  @ViewChild('downpaymentRange', { static: true }) private downpaymentRange: ElementRef<HTMLDivElement>;

  @ViewChild('userEquityRange', { static: true }) private userEquityRange: ElementRef<HTMLDivElement>;

  @ViewChild('userDownpaymentLabel', { static: true }) private userDownpaymentLabel: ElementRef<HTMLDivElement>;

  @ViewChild('userEquityLabel', { static: true }) private userEquityLabel: ElementRef<HTMLDivElement>;

  @ViewChild('userMoneyAmountLabel', { static: true }) private userMoneyAmountLabel: ElementRef<HTMLDivElement>;

  private box: DOMRect;

  private outputDebouncer: Subject<string> = new Subject<string>();

  @Output() downpaymentAmount: EventEmitter<string> = new EventEmitter<string>();

  ranges: Range[] = [];

  downpayment_limit_min: number = 0;

  max: number = 2500000;

  downpayment_amount: number = 0;

  user_equity: number = 0;

  label: number = 0;

  isChangeEquityLabelAlign: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdRef: ChangeDetectorRef,
    private leadService: LeadService,
  ) {}

  ngOnInit(): void {
    this.subscribes();
    this.initPointerObserver();
  }

  private subscribes(): void {
    this
      .leadService
      .downPaymentData$
      .pipe(
        untilDestroyed(this),
        filter((response: DownPaymentData) => !!response),
      )
      .subscribe((response: DownPaymentData) => {
        const { downpayment_limit_min, downpayment_amount, user_equity } = response;

        this.downpayment_limit_min = downpayment_limit_min || 0;
        this.downpayment_amount = downpayment_amount || 0;
        this.user_equity = user_equity || 0;

        this.init();
      });

    this
      .outputDebouncer
      .pipe(
        distinctUntilChanged(),
        debounceTime(800),
        untilDestroyed(this),
      )
      .subscribe((value: string) => {
        this.downpaymentAmount.emit(`${value}`);
      });
  }

  private init(): void {
    this.box = this.sliderContainer.nativeElement.getBoundingClientRect();

    this.initRanges();
    this.updateEquityLabelAlignment();
    this.updateUserEquityLabel();
    this.updateThumbStyle(this.amountToPixels(this.downpayment_amount), false);
  }

  private initPointerObserver(): void {
    const sliderContainerElement: HTMLDivElement = this.sliderContainer.nativeElement;
    const { document } = this;
    const down$: Observable<Event> = merge(
      fromEvent(sliderContainerElement, 'mousedown', { capture: true }),
      fromEvent(sliderContainerElement, 'touchstart', { passive: false }),
    );
    const move$: Observable<Event> = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'touchmove', { passive: false }),
    );
    const up$: Observable<Event> = merge(
      fromEvent(document, 'mouseup'),
      fromEvent(document, 'touchend'),
    );

    down$
      .pipe(
        untilDestroyed(this),
        map((event: MouseEvent | TouchEvent) => {
          event.preventDefault();

          return event;
        }),
        mergeMap(() => move$
          .pipe(
            map((event: MouseEvent | TouchEvent) => {
              event.preventDefault();

              if ('clientX' in (event as MouseEvent)) {
                return (event as MouseEvent).clientX;
              }

              return (event as TouchEvent).touches[0].clientX;
            }),
            distinctUntilChanged(),
            takeUntil(up$),
          )),
      )
      .subscribe((clientX: number) => {
        this.moveThumbToCoordinate(clientX);
      });
  }

  private initRanges(): void {
    const defaultRange: number[] = [...DOWN_PAYMENT_RANGE_VALUES, this.user_equity].sort((a: number, b: number) => a - b);
    const closestIndex: number = defaultRange.findIndex((value: number) => value >= this.downpayment_limit_min);
    const range: number[] = defaultRange.slice(closestIndex);
    const fractionSize: number = this.box.width / range.length;
    const ranges: { amount: number; coord: number }[] = range.map((value: number, index: number) => {
      return { amount: value, coord: index * fractionSize };
    });

    this.ranges = [
      ...ranges,
      { coord: this.box.width, amount: this.max },
    ];
  }

  private updateEquityLabelAlignment(): void {
    this.isChangeEquityLabelAlign = this.amountToPixels(this.user_equity) > (this.box.width / 2);
  }

  private updateUserEquityLabel(): void {
    this.userEquityLabel.nativeElement.style.left = `${this.amountToPixels(this.user_equity)}px`;
  }

  private pixelsToAmount(currentCoord: number): number {
    const result: Range = this.ranges.find((range: Range) => range.coord >= currentCoord);

    if (!result) { return 0; }

    return result.amount;
  }

  private amountToPixels(currentValue: number): number {
    const result: Range = this.ranges.find((range: Range) => range.amount >= currentValue);

    if (!result) { return 0; }

    return result.coord;
  }

  private updateRangesStyles(width: number): void {
    this.updateUserEquityLabel();

    this.userEquityRange.nativeElement.style.width = `${width}px`;

    if (this.pixelsToAmount(width) > this.user_equity) {
      this.downpaymentRange.nativeElement.style.width = `${this.amountToPixels(this.user_equity)}px`;

      return;
    }

    this.downpaymentRange.nativeElement.style.width = `${width}px`;
  }

  private moveThumbToCoordinate(clientX: number): void {
    if (!this.box) { return; }

    const relativeCoorditate: number = clientX - this.box.left;

    // pointer x coord less than min limit coord
    if (relativeCoorditate <= 0) {
      this.updateThumbStyle(0, true);

      return;
    }

    // pointer x coord more than slider container width
    if (relativeCoorditate >= this.box.width) {
      this.updateThumbStyle(this.box.width, true);

      return;
    }

    // any other case
    const range: Range = this.ranges.find((item: Range) => item.coord >= relativeCoorditate);

    if (!range) {
      this.updateThumbStyle(relativeCoorditate, true);

      return;
    }

    this.updateThumbStyle(range.coord, true);
  }

  private updateThumbStyle(coord: number, isMakeRequest: boolean): void {
    const downpaymentAmount: number = this.pixelsToAmount(coord);

    this.label = downpaymentAmount;
    this.sliderThumb.nativeElement.style.left = `${coord}px`;

    this.updateRangesStyles(coord);

    if (!!downpaymentAmount && isMakeRequest) {
      this.outputDebouncer.next(`${downpaymentAmount}`);
    }

    this.cdRef.markForCheck();
  }

  get isShowUserDownpaymentLabel(): boolean {
    const currentLabelBox: DOMRect = this.userDownpaymentLabel.nativeElement.getBoundingClientRect();
    const userEquityLabelBox: DOMRect = this.userEquityLabel.nativeElement.getBoundingClientRect();

    return this.user_equity && this.downpayment_limit_min && (currentLabelBox.right <= userEquityLabelBox.left);
  }

  get isShowUserMoneyAmountLabel(): boolean {
    if (!this.user_equity) { return false; }

    const currentLabelBox: DOMRect = this.userMoneyAmountLabel.nativeElement.getBoundingClientRect();
    const userEquityLabelBox: DOMRect = this.userEquityLabel.nativeElement.getBoundingClientRect();

    return (this.label > this.user_equity) && (currentLabelBox.left >= userEquityLabelBox.right);
  }

  get userMoneyAmount(): number {
    return this.label - this.user_equity;
  }

}
