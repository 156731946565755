<div class="logo">
  <img [src]="lead.report_logo || 'assets/components/header/home_iq/logo.svg'"/>
</div>
<div class="account" *ngIf="lead">
  <div class="account__details">
    <div class="account__name">{{ lead?.owner_name }}</div>
    <div class="account__address">{{ getCorrectAddress(lead?.full_address) }}</div>
  </div>
  <div class="account__image" [class.pointer]="!isPreview" (click)="openSidebar()">
    <div class="account__avatar">{{ getInitials(lead?.owner_name) }}
      <div class="account__shevron" *ngIf="!isPreview" [inlineSVG]="'assets/components/header/chevron.svg'"></div>
    </div>
  </div>
</div>
<button class="settings-btn"
        *ngIf="!isPreview"
        [inlineSVG]="'assets/icons/settings.svg'"
        (click)="openSidebar()"></button>
