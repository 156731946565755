import { Component, Input, OnChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import type { Lead } from '@core/types';

// Consts
import { environment } from 'environments/environment';
import { TRACKING } from '@consts/analytics';

// Services
import { TrackingService } from '@core/helpers/tracking/tracking.service';

@Component({
  selector: 'send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.sass'],
})
export class SendReportComponent implements OnChanges {

  @Input() link: string;

  @Input() lead: Lead;

  token: string[];

  isUserActivate: boolean = true;

  constructor(
    @Inject(DOCUMENT) public document: any,
    private trackingService: TrackingService,
  ) {}

  ngOnChanges(): void {
    this.token = this.link.split('&token=');

    if (this.token[1] === '') {
      this.isUserActivate = false;
    }
  }

  redirect(): void {
    this.document.location.href = `${environment.soaUrl}/leads/invitation`;
  }

  track(): void {
    this.trackingService.track(TRACKING.viral_report_activated, true).subscribe();
  }

}
