import type { Dictionary } from 'asap-team/asap-tools';
import type {
  IVerifyStatus,
  ReportType,
  HomeValueCalcData,
  RequestedPropertyType,
} from '@core/types';

export const TOOLTIP: Dictionary = {
  DISCLAIMER_INFO: `All information displayed is for informational purposes only and is not
    an advertisement to extend consumer credit as defined by Section 12 CFR 1026.2
    Regulation Z.APR is based on a reasonably current index and margin from the Freddie
    mac primary mortgage market survey™ http://www.freddiemac.com/pmms/ All home valuations
    are estimates purchased from third-party data providers. Actual appraised value at the time
    of a transaction may vary from these estimates and no guarantee is expressed or implied
    as to your actual value or sales price. All Mortgage Products and programs are subject
    to borrower credit,income and property approval and not all borrowers will qualify.
    All examples of potential mortgage products shown on this site are estimates for illustration
    purposes only. Program terms, rates and conditions are subject to change at any time without notice.
    Equal Housing Opportunity.`,
};
export const TOOLTIP_CONFIG: any = {
  'show-delay': 200,
  'max-width': '550px',
  'tooltip-class': 'tooltip',
};
export const REPORT_TYPE: Dictionary<ReportType> = {
  SELLING: 'selling',
  REFINANCE: 'refinance',
  LINE: 'line',
};
export const BUY_ANOTHER_HOUSE: Dictionary<RequestedPropertyType> = {
  PRIMARY_HOME: 'primary_home',
  SECONDARY_HOME: 'secondary_home',
  RENTAL_PROPERTY: 'rental_property',
};
export const VERIFY_STATUS: IVerifyStatus = {
  UNVERIFIED: 'unverified',
  USER_REQUEST: 'user_request',
  VERIFIED: 'verified',
};
export const WARNING: Dictionary = { NO_NETWORK: 'No Internet Connection' };
export const ERROR: Dictionary = {};
export const DEFAULT_HOME_VALUE_CALC_DATA: HomeValueCalcData = {
  conditions: 'average',
  bedrooms: '0',
  bathrooms: '0',
  sq_ft: '0',
};
export const SIDEBAR_TAB_NAME: Dictionary = {
  SETTINGS: 'settings',
  LOANS: 'loans',
  HOME_DETAILS: 'home_details',
};
export const QUERY_PARAM: Dictionary = {
  PREVIEW: 'preview',
  VERSION: 'version',
  COMPLETE_URL: 'complete_url',
  ADDRESS_HASH: 'address_hash',
  REPORT_TYPE: 'report_type',
  EMAIL: 'email',
  TOKEN: 'token',
  UID: 'uid',
  LOAN_TERM: 'loan_term',
};
export const LOANS_GRADIENT: string[] = [
  'linear-gradient(to top, #e0c3fc, #8ec5fc)',
  'linear-gradient(to top, #fad0c4, #fad0c4, #ffd1ff)',
  'linear-gradient(to top, #ffecd2, #fcb69f)',
  'linear-gradient(to top, #fcdd7d, #ffa78d)',
  'linear-gradient(to top, #a1c4fd, #c2e9fb)',
  'linear-gradient(to top, #84fab0, #8fd3f4)',
  'linear-gradient(to top, #89f7fe, #66a6ff)',
];
export const LOAN_TYPES: { title: string; value: string }[] = [
  {
    title: 'FHA',
    value: 'fha',
  },
  {
    title: 'VA',
    value: 'va',
  },
  {
    title: 'Conventional',
    value: 'conventional',
  },
];
