<div class="modal-container intro max-770">
  <div class="modal-content">
    <div class="modal-close">
      <svg-icon src="assets/icons/close-outside.svg" [svgStyle]="svgStyle" (click)="close()"></svg-icon>
    </div>
    <div class="modal-body">
      <img class="intro__icon" [src]="icon" />
      <div class="intro__title">{{ title }}</div>
      <div class="intro__message">{{ message }}</div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-outline" type="button" (click)="close()">Got it</button>
    </div>
  </div>
</div>
