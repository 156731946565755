import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

import type { Dictionary } from 'asap-team/asap-tools';

@Component({
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.sass'],
})
export class VideoDialogComponent extends SimpleModalComponent<null, null> {

  svgStyle: Dictionary = { fill: '#fff' };

}
