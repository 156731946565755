<div class="report"
     *ngIf="lead"
     [ngClass]="{ 'viral-container': isShow(lead, 'viral-sidebar'), 'updating': (reportUpdating | async) }">
  <app-header [lead]="lead"></app-header>
  <div class="container">
    <equity-tracker *ngIf="isShow(lead, 'equity-tracker')" [lead]="lead"></equity-tracker>
    
    <any-loans *ngIf="isShow(lead, 'any-loans')" [lead]="lead"></any-loans>
    
    <primary-loans *ngIf="isShow(lead, 'primary-loans')" [lead]="lead"></primary-loans>
    
    <transaction-estimator *ngIf="isShow(lead, 'transaction-estimator')"
                           [lead]="lead"
                           [fees]="fees"></transaction-estimator>
    
    <report-loading *ngIf="!fees"></report-loading>
    
    <loan-payment-tracker *ngIf="isShow(lead, 'loan-payment-tracker')"
                          [lead]="lead"
                          [insuranceInfo]="insuranceInfo"
                          [payData]="extraPrincipalData"></loan-payment-tracker>
    
    <refinance-estimator *ngIf="isShow(lead, 'refinance-estimator')"
                         [lead]="lead"
                         [insuranceInfo]="insuranceInfo"
                         [data]="interestAddsUpData"></refinance-estimator>
    
    <rent-estimator *ngIf="isShow(lead, 'rent-estimator')"
                    [lead]="lead"
                    [isShowRentInfo]="isShow(lead, 'zillow')"
                    [rentInfo]="rentInfo"
                    [isShowAirbnbInfo]="isShow(lead, 'airbnb')"
                    [airbnbInfo]="airbnbInfo"></rent-estimator>
    
    <buy-another-house *ngIf="isShow(lead, 'buy-another-house')" [lead]="lead" [fees]="fees"></buy-another-house>
    
    <sms-subscribe *ngIf="isShow(lead, 'sms-subscribe')"
                   [lead]="lead"
                   (newLead)="updateSmsSubscribe($event)"></sms-subscribe>
    
    <idx-link-banner *ngIf="lead?.idx_link" [idx_link]="lead?.idx_link"></idx-link-banner>
    
    <p class="m-t-80 m-b-32 f-c-firefly f-30-600 text-center">Your <i>my</i>HomeIQ report is provided by</p>
    <div class="user-info__wrapper">
      <user-info *ngIf="lead.agent" [user]="lead?.agent"></user-info>
      <div class="user-info__divider m-t-40 m-b-40" *ngIf="lead.agent && lead.lender"></div>
      <user-info *ngIf="lead.lender" [user]="lead?.lender"></user-info>
    </div>
    
    <user-disclaimer *ngIf="lead.lender" [user]="lead?.lender"></user-disclaimer>
    
    <user-disclaimer *ngIf="lead.agent" [user]="lead?.agent"></user-disclaimer>
    
    <div class="footer__divider"></div>
    
    <ng-container *ngIf="!isPropertyDigest">
      <subscribe-to-digest *ngIf="isShow(lead, 'subscribe-to-digest')" [lead]="lead"></subscribe-to-digest>
    </ng-container>
    
    <viral-activate *ngIf="isShow(lead, 'viral-sidebar')" [link]="activateLink"></viral-activate>
    
  </div>
  
  <app-footer [lead]="lead" [class.property]="isHavePropertyClass"></app-footer>
  
</div>

<ng-container *ngIf="!lead">
  <loading-screen></loading-screen>
</ng-container>
