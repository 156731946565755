import { Component, Input } from '@angular/core';

import type { Lead, IExtraPrincipal } from '@core/types';

@Component({
  selector: 'principal-paydown-forecaster',
  templateUrl: './principal-paydown-forecaster.component.html',
  styleUrls: ['./principal-paydown-forecaster.component.sass'],
})
export class PrincipalPaydownForecasterComponent {

  @Input() lead: Lead;

  @Input() payData: IExtraPrincipal;

}
