<div class="certificate">
  <div class="certificate__title">Click below to receive your instant pre-qualification certificate</div>
  <div class="certificate__button-block">
    <a class="btn-link certificate__button"
       [target]="downloadLink ? '_self' : '_blank'"
       (click)="downloadButtonClicked($any($event))"
       [href]="downloadLink || buyerIQLink">
      Download your certificate
    </a>
  </div>
</div>
