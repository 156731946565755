export function getCorrectAddress(address: string): string {
  if (!address) { return ''; }

  const splitted: string[] = address.split(',');

  if (splitted.length > 3) {
    return `${splitted[0]},${splitted[1]}`;
  }

  return splitted[0];
}
