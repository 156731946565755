import { UrlMatchResult, UrlSegment } from '@angular/router';
import { UnsubscribeEmailType } from '@components/unsubscribe/enums/unsubscribe-email-type.enum';
import { HttpParams } from '@angular/common/http';

export const MONTHLY_DIGEST_UNSUBSCRIBE_PATH_MATCHER = (url: UrlSegment[]): UrlMatchResult => {
  const [uid, segment] = url;
  const unsubscribeType: string = new HttpParams({ fromString: globalThis.location.search }).get('email_type');

  if (url.length === 2 && segment.path === 'unsubscribe' && unsubscribeType === UnsubscribeEmailType.monthlyDigest) {
    return {
      consumed: url,
      posParams: { uid },
    };
  }

  return null;
};
