<div class="card mortgage-insurance">
  <div class="card__content text-center" [ngSwitch]="state">
    <div class="mortgage-insurance__content--notavailable" *ngIf="isLoanRequired">
      <div class="mortgage-insurance__title m-b-40">
        <h2 class="m-b-16">Did you know that you may qualify to reduce <br/>your monthly payment?</h2>
        <h4>Conventional loan is required</h4>
      </div>
    </div>
    <div class="mortgage-insurance__content--available" *ngIf="!isLoanRequired">
      <div class="mortgage-insurance__title m-b-40">
        <h2 *ngSwitchCase="'below'">Has your mortgage payment <br/>been reduced by
          <span>{{ insuranceData?.insurance_amount | amount }}</span>/month?</h2>
        <h2 *ngSwitchCase="'near'">Did you know that you may qualify to reduce <br/>your monthly payment by
          <span>{{ insuranceData?.insurance_amount | amount }}</span>/month?</h2>
        <h2 *ngSwitchCase="'above'">Your monthly payment includes
          <br/><span>{{ insuranceData?.insurance_amount | amount }}</span>/month for mortgage insurance</h2>
      </div>
      <div class="mortgage-insurance__info">
        <div class="mortgage-insurance__icon m-b-16" *ngSwitchCase="'below'">🎉</div>
        <h4 *ngSwitchCase="'below'">Because your <a (click)="openDialog()">loan to value ratio</a> falls below 78%<br/>you
          should <span>no longer have to pay for mortgage insurance</span>.</h4>
        <h4 *ngSwitchCase="'near'">Because your <a (click)="openDialog()">loan to value ratio</a> is near 80%<br/>you
          can request to remove your mortgage insurance</h4>
        <h4 *ngSwitchCase="'above'">Once your <a (click)="openDialog()">loan to value ratio</a> falls below 80%<br/>you
          will qualify to remove your mortgage insurance</h4>
      </div>
      <a class="mortgage-insurance__pmi-edit" *ngIf="isShowPmiEdit" (click)="pmiEdit()">I don’t pay mortgage insurance
        anymore</a>
      <div class="slider">
        <div class="slider-value slider-value--loan">
          <div class="caption-text f-c-east-bay">{{ insuranceData?.loan_balance | formatNumber: '2' : true }}</div>
          <div class="f-14-normal f-c-gull-gray">Loan balance</div>
        </div>
        <div class="slider-limit" [ngStyle]="sliderLimitStyle"></div>
        <div class="slider-progress" [ngStyle]="sliderProgressStyle">
          <span class="f-20-600 color-white">{{ insuranceData?.loan_to_value }}%</span>
          <div class="slider-progress-point" [class]="state" [tooltip]="stateConfig?.tooltip">
            <i [inlineSVG]="'/assets/icons/icon-question.svg'"></i></div>
        </div>
        <div class="slider-value slider-value--home">
          <div class="caption-text f-c-east-bay">{{ insuranceData?.home_value | formatNumber: '2' : true }}</div>
          <div class="f-14-normal f-c-gull-gray">Home value</div>
        </div>
      </div>
      <p class="verify-loan text f-c-east-bay" *ngIf="!isPreview && !lead?.loan_verified">
        To ensure accuracy, please verify your loan information. <a (click)="openSidebar()">Verify loan</a>
      </p>
    </div>
    <no-loan *ngIf="isLoanRequired" [reportId]="insuranceData?.id"></no-loan>
  </div>
  <ng-template hideInPreview>
    <send-question *ngIf="!isLoanRequired"
                   [userId]="lead.id"
                   [user]="lead.owner"
                   [preparedQuestions]="preparedQuestions"
                   [title]="'mortgage_insurance'">
      <verify-home-value *ngIf="isShowVerifyHomeValue"
                         [leadDetails]="lead"
                         title="Request an updated home value report to validate the loan to value ratio"></verify-home-value>
    </send-question>
  </ng-template>
</div>
