<p class="text-lg text-center">If you don’t want to rent your entire home you can turn unused rooms into possible
  cashflow</p>
<div class="airbnb-rent-estimator__content">
  <div class="airbnb-rent-estimator__content--item">
    <h4 class="title">Avg. Daily Rate</h4>
    <div class="value number number--md color-primary">{{ airbnbInfo?.adr | amount }}</div>
  </div>
  <div class="airbnb-rent-estimator__content--item">
    <h4 class="title">Occupancy</h4>
    <div class="value number number--md">{{ formatOccupancy(airbnbInfo?.occupancy * 100) }}%</div>
  </div>
  <div class="airbnb-rent-estimator__content--item">
    <h4 class="title">Annual revenue</h4>
    <div class="value number number--md color-primary">{{ airbnbInfo?.revenue | amount }}</div>
  </div>
</div>
<rent-info [rentInfo]="airbnbInfo"></rent-info>
