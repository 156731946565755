import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { fadeInOut, percent } from 'asap-team/asap-tools';

import type { LoanYear } from '@core/types';

@Component({
  selector: 'lpt-graph',
  templateUrl: './lpt-graph.component.html',
  styleUrls: ['./lpt-graph.component.sass'],
  animations: [fadeInOut()],
})
export class LoanPaymentTrackerGraphComponent {

  @ViewChild('tooltip', { static: false }) tooltip: ElementRef;

  @ViewChild('chartElement', { static: false }) chartElement: ElementRef;

  @Input() dataset: LoanYear[];

  tip: {
    index: number;
    year: number;
    interest_label: string;
    principal_label: string;
    current_interest: number;
    current_principal: number;
    future: boolean;
  } = null;

  arrayElements: any[] = new Array(30).fill({});

  constructor(
    private renderer: Renderer2,
  ) {}

  getItemClass(set: LoanYear): { 'item-future': boolean; 'item-past': boolean } {
    return {
      'item-future': set.future,
      'item-past': !set.future,
    };
  }

  getBarHeight(set: LoanYear, valueName: string): { transform: string } {
    const value: number = 0.01 * percent(set[valueName], (set.current_interest + set.current_principal), 2);

    return { transform: `scaleY(${value})` };
  }

  trackByFn(index: number): number {
    return index;
  }

  mouseover(event: MouseEvent, set: LoanYear, index: number): void {
    const barRect: DOMRect = (event.target as HTMLInputElement).getBoundingClientRect() as DOMRect;
    const containerRect: DOMRect = (this.chartElement.nativeElement as HTMLInputElement).getBoundingClientRect() as DOMRect;
    const shift: number = (barRect.left - containerRect.left) + (barRect.width / 2);
    const prefix: string = set.future ? 'Future' : 'Paid';

    this.tip = {
      index,
      ...set,
      interest_label: `${prefix} Interest`,
      principal_label: `${prefix} Principal`,
    };

    this.renderer.setStyle(this.tooltip.nativeElement, 'visibility', 'visible');
    this.renderer.setStyle(this.tooltip.nativeElement, 'opacity', '1');
    this.renderer.setStyle(this.tooltip.nativeElement, 'left', `${shift}px`);
  }

  mouseout(): void {
    this.renderer.setStyle(this.tooltip.nativeElement, 'visibility', 'hidden');
    this.renderer.setStyle(this.tooltip.nativeElement, 'opacity', '0');
    this.tip = null;
  }

}
