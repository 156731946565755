import { Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { find } from 'lodash-es';
import { fadeOut } from 'asap-team/asap-tools';

import type { Lead, Loan } from '@core/types';

// Consts
import { LOANS_GRADIENT, SIDEBAR_TAB_NAME } from '@consts/consts';
import { LOANS_TOASTS } from '@consts/enums';

// Services
import { LoansService } from '@core/services/loans/loans.service';
import { RightSidebarService } from '@core/helpers/right-sidebar/right-sidebar.service';

@UntilDestroy()
@Component({
  selector: 'primary-loans',
  templateUrl: './primary-loans.component.html',
  styleUrls: ['./primary-loans.component.sass'],
  animations: [fadeOut()],
})
export class PrimaryLoansComponent {

  @Input() lead: Lead;

  action$: Subscription;

  isShowBlock: boolean = true;

  constructor(
    private loansService: LoansService,
    private rightSidebarService: RightSidebarService,
    private toastr: ToastrService,
  ) {}

  get primaryLoan(): Loan {
    return find(this.lead.transactions, { primary: true });
  }

  getGradient(index: number): { backgroundImage: string } {
    return { backgroundImage: LOANS_GRADIENT[index % LOANS_GRADIENT.length] };
  }

  editLoan(loan: Loan): void {
    this
      .rightSidebarService
      .openHeaderSidebar(SIDEBAR_TAB_NAME.LOANS, this.lead, { loan, type: 'edit' });
  }

  verifyLoans(): void {
    this.action$ = this
      .loansService
      .verifyLoans(this.lead?.id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.lead.loan_verified = true;
          this.isShowBlock = false;
          this.toastr.success(LOANS_TOASTS.VERIFY);
        },
      );
  }

}
