import { FormErrors } from 'asap-team/asap-tools';

export const accountSettingsErrors: FormErrors = {
  owner_name: [
    {
      name: 'required',
      text: 'This field is required',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'maxlength',
      text: 'is too long (maximum is 150 characters)',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  phone: [
    {
      name: 'invalidPhone',
      text: 'Phone is invalid',
      rules: ['touched', 'dirty'],
    },
    {
      name: 'api_error',
      text: '',
      rules: ['touched', 'dirty'],
    },
  ],
  global: [],
};
