import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dictionary, fadeInOut, isPermitted } from 'asap-team/asap-tools';

import type { Lead, InsuranceInfo, InterestAddsUp, MortgageCheckup } from '@core/types';

// Consts
import {
  TOOLTIP,
  TOOLTIP_CONFIG,
  QUERY_PARAM,
  SIDEBAR_TAB_NAME,
} from '@consts/consts';
import { PREPARED_QUESTIONS } from '@consts/prepared-questions';
import {
  PERMITTED_ACTION,
  BLOCK,
  REFINANCE_OPTIONS_RESTRICTIONS, GoogleEvents,
} from '@consts/enums';

// Services
import { HelpersService } from '@core/helpers/helpers.service';
import { RightSidebarService } from '@core/helpers/right-sidebar/right-sidebar.service';
import { GAService } from '@core/helpers/ga/ga.service';

@Component({
  selector: 'refinance-estimator',
  templateUrl: './refinance-estimator.component.html',
  styleUrls: ['./refinance-estimator.component.sass'],
  animations: [fadeInOut()],
})
export class RefinanceEstimatorComponent {

  @Input() set lead(value: Lead) {
    this._lead = value; // eslint-disable-line
    this.setViewRestriction();
  }

  get lead(): Lead {
    return this._lead; // eslint-disable-line
  }

  @Input() data: InterestAddsUp;

  @Input() insuranceInfo: InsuranceInfo;

  private _lead: Lead;

  tooltip: any = TOOLTIP;

  tooltipOptions: Dictionary<any> = TOOLTIP_CONFIG;

  isShowForm: boolean = false;

  preparedQuestions: string[] = PREPARED_QUESTIONS.interest_adds;

  viewRestriction: string = '';

  constructor(
    private route: ActivatedRoute,
    private helpersService: HelpersService,
    private rightSidebarService: RightSidebarService,
    private gaService: GAService,
  ) {}

  setViewRestriction(): void {
    if (this.isLenderRequired) {
      this.viewRestriction = 'lender';
    } else if (this.isLoanRequired) {
      this.viewRestriction = 'loan';
    } else if (this.isRefiUnlockRequired) {
      this.viewRestriction = 'locked';
    } else {
      this.viewRestriction = '';
    }
  }

  get isRefinanceViewLimited(): boolean {
    return this.helpersService.getBlockState(this.lead, BLOCK.REFINANCE_OPTIONS).blurred;
  }

  private checkRefinanceRestriction(restriction: string): boolean {
    const isRequired: boolean | undefined = this.lead.blocks[
      BLOCK.REFINANCE_OPTIONS
    ]?.requirements?.some(
      (checkedRestriction: string) => checkedRestriction === restriction,
    );

    return !!isRequired;
  }

  get isLenderRequired(): boolean {
    return this.checkRefinanceRestriction(
      REFINANCE_OPTIONS_RESTRICTIONS.LENDER_REQUIRED,
    );
  }

  get isLoanRequired(): boolean {
    return this.checkRefinanceRestriction(
      REFINANCE_OPTIONS_RESTRICTIONS.LOAN_REQUIRED,
    );
  }

  get isRefiUnlockRequired(): boolean {
    return this.checkRefinanceRestriction(
      REFINANCE_OPTIONS_RESTRICTIONS.REFI_UNLOCK_REQUIRED,
    );
  }

  get isPreview(): boolean {
    return this.route.snapshot.queryParamMap.has(QUERY_PARAM.PREVIEW);
  }

  get refinanceOptionsLink(): string {
    return `/leads/${this.lead.id}/mortgage_checkups`;
  }

  get showRefinanceOptionsButton(): boolean {
    return isPermitted(
      this.lead.permitted_actions,
      PERMITTED_ACTION.DOWNLOAD_REFINANCE_OPTIONS,
    );
  }

  get refinanceOptionsQueryParams(): Dictionary {
    return { loan_term: '30' };
  }

  getLabelStyle(item: MortgageCheckup): Dictionary<boolean> {
    return {
      'color-danger': this.checkNegativeNumber(item.interest_saved),
      'color-primary': !this.checkNegativeNumber(item.interest_saved),
    };
  }

  checkNegativeNumber(value: number): boolean {
    return value < 0;
  }

  getAbsoluteValue(value: number): number {
    if (!value) {
      return 0;
    }

    return Math.abs(value);
  }

  getIcon(value: number): string {
    return value >= 0 ? '/assets/icons/check.svg' : '/assets/icons/uncheck.svg';
  }

  openSidebar(): void {
    if (this.isPreview) {
      return;
    }

    this.rightSidebarService.openHeaderSidebar(
      SIDEBAR_TAB_NAME.LOANS,
      this.lead,
    );

    this.gaService.trackSimpleEvent(GoogleEvents.LOAN_DETAILS, 'refinance');
  }

}
