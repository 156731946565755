import { Component } from '@angular/core';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';

// Components
import { PmiHelpDialogComponent } from './pmi-help-dialog/pmi-help-dialog.component';
import { ConventionalPmiHelpDialogComponent } from './conventional-pmi-help-dialog/conventional-pmi-help-dialog.component';
import { LtvHelpDialogComponent } from './ltv-help-dialog/ltv-help-dialog.component';

@Component({
  templateUrl: './mortgage-help-dialog.component.html',
  styleUrls: ['./mortgage-help-dialog.component.sass'],
})
export class MortgageHelpDialogComponent extends SimpleModalComponent<any, any> {

  constructor(
    private simpleModalService: SimpleModalService,
  ) {
    super();
  }

  getInfo(type: 'pmi' | 'conventional' | 'ltv'): void {
    switch (type) {
      case 'pmi':
        this.simpleModalService.addModal(PmiHelpDialogComponent);
        break;

      case 'conventional':
        this.simpleModalService.addModal(ConventionalPmiHelpDialogComponent);
        break;

      case 'ltv':
        this.simpleModalService.addModal(LtvHelpDialogComponent);
        break;

      default:
        break;
    }
  }

}
