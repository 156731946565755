import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

import type { ReportType } from '@core/types';

// Consts
import { QUERY_PARAM } from '@consts/consts';
import { ROUTE } from '@consts/routes';

// Services
import { LeadService } from '@core/services/lead/lead.service';

@Injectable()
export class ReportResolve implements Resolve<any> {

  constructor(
    private router: Router,
    private leadService: LeadService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const uid: string = route.params.id;
    const { hash } = route.params;
    const type: ReportType = route.queryParamMap.get(QUERY_PARAM.REPORT_TYPE) as ReportType;

    return this
      .leadService
      .getReportFileLink({
        uid, hash, type,
      })
      .pipe(
        catchError(() => {
          this.router.navigate([ROUTE.alias.NOT_FOUND]);

          return EMPTY;
        }),
      );
  }

}
