import { CancelMortgageStateConfig } from '@core/types';

export const CANCEL_MORTGAGE_STATE: CancelMortgageStateConfig = {
  below: {
    tooltip: 'When you are below 78% of  LTV ratio and have Conventional loan type, your mortgage insurance has to be canceled. If you still pay insurance, then write to the lender and they may cancel it.',
    limit_position: '78%',
    progress_style: 'linear-gradient(to left, #2681ff, #43d2ff)',
  },
  near: {
    tooltip: 'When you are near 78% of LTV ratio and have Conventional loan type, your mortgage insurance can be canceled. So you should request to remove your insurance. There is a good chance that the lender cancels it.',
    limit_position: 'calc(80% - 16px)',
    progress_style: 'linear-gradient(to left, #FFA926 10.16%, #031D61 23.46%, #125DC3 100%)',
  },
  above: {
    tooltip: 'When you have Conventional loan type and are above 80% of LTV ratio, you can’t remove your insurance. Watch when your LTV is going down to 80%.',
    limit_position: 'calc(80% - 16px)',
    progress_style: 'linear-gradient(to left, #c70039, #031d61, #125dc3)',
  },
};
