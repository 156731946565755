import { defaultSimpleModalOptions } from 'ngx-simple-modal';

export const RIGHT_SIDEBAR_DEFAULTS: any = {
  ...defaultSimpleModalOptions,
  ...{
    closeOnEscape: true,
    closeOnClickOutside: true,
    wrapperDefaultClasses: 'sidebar fade',
    wrapperClass: 'in',
  },
};
