<iq-button class="m-b-24"
           label="View home value calculator"
           size="32"
           color="blue"
           [style]="'outline'"
           [class.active]="isOpen"
           (onClick)="toggle()"></iq-button>
<div class="calculator" [hidden]="isOpen">
  <p class="calculator-title text--lg text-center">Adjusting the condition or characteristics below may change your
    home’s estimated value.</p>
  <div class="calculator-range">
    <ul class="calculator-range__header list-inline">
      <li class="calculator-range__label caption-text text-left" [ngClass]="getRangeHeaderClass(0)">
        <p class="calculator-range__title">Poor</p>
        <p class="calculator-range__hint">Home condition</p>
      </li>
      <li class="calculator-range__label caption-text text-center" [ngClass]="getRangeHeaderClass(1)">
        <p class="calculator-range__title">Average</p>
        <p class="calculator-range__hint">Home condition</p>
      </li>
      <li class="calculator-range__label caption-text text-right" [ngClass]="getRangeHeaderClass(2)">
        <p class="calculator-range__title">Superior</p>
        <p class="calculator-range__hint">Home condition</p>
      </li>
    </ul>
    <div>
      <input class="calculator-range__input"
             type="range"
             min="0"
             max="2"
             (click)="rangeInputClicked()"
             [formControl]="range"
             [ngStyle]="rangeInputStyle"
             (input)="setLineStyle(range.value)"/>
    </div>
    <div class="calculator-range__counters">
      <calc-count-item [title]="'Living area'"
                       [propName]="'sq_ft'"
                       [min]="0"
                       [max]="50000"
                       [step]="100"
                       [initValue]="lead.home_sq_ft"
                       (emittedValue)="updateValues($event)"></calc-count-item>
      <div class="calculator-range__divider"></div>
      <calc-count-item [title]="'Bedroom'"
                       [propName]="'bedrooms'"
                       [min]="0"
                       [max]="50"
                       [step]="1"
                       [initValue]="lead.bedrooms"
                       (emittedValue)="updateValues($event)"></calc-count-item>
      <div class="calculator-range__divider"></div>
      <calc-count-item [title]="'Bathroom'"
                       [propName]="'bathrooms'"
                       [min]="0"
                       [max]="50"
                       [step]="1"
                       [initValue]="lead.bathrooms"
                       (emittedValue)="updateValues($event)"></calc-count-item>
    </div>
  </div>
  <div class="calculator-info text-center">
    <h4>Your adjusted home value</h4>
    <div class="calculator-info__value">
      <div class="number number--lg color-primary">{{ home_value | amount }}</div>
      <div class="calculator-info__adjusted color-primary"
           *ngIf="home_value_shift"
           [class.color-danger]="home_value_shift < 0">
        <span *ngIf="home_value_shift > 0">&#43;</span>
        {{ getHomeValueShift | number : '1.0-2' }}k
      </div>
    </div>
  </div>
</div>
