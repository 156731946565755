import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { pickBy, identity, every, isNil } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { filter, take, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { BaseHttpService, Dictionary } from 'asap-team/asap-tools';

// Consts
import { environment } from 'environments/environment';

type TrackingParams = {
  // ID from lead response
  lead_uid?: string;
  // ID from url params
  user_uid?: string;
  event: string;
  campaign_name?: string;
  source?: string;
  tag?: string;
};

@Injectable({ providedIn: 'root' })
export class TrackingService {

  private lead_uid: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private user_uid: BehaviorSubject<string> = new BehaviorSubject(null);

  private viral_params: BehaviorSubject<Dictionary<any>> = new BehaviorSubject(null);

  lead_uid$: Observable<string> = this
    .lead_uid
    .asObservable()
    .pipe(
      filter((value: string) => !isNil(value)),
      distinctUntilChanged(),
    );

  user_uid$: Observable<string> = this
    .user_uid
    .asObservable()
    .pipe(
      filter((value: string) => !isNil(value)),
      distinctUntilChanged(),
    );

  viral_params$: Observable<Dictionary<any>> = this
    .viral_params
    .asObservable()
    .pipe(
      filter((value: Dictionary<any>) => !isNil(value)),
      distinctUntilChanged(),
    );

  constructor(
    private http: BaseHttpService,
  ) {}

  init({ lead_uid, user_uid, paramMap }: { lead_uid?: string; user_uid?: string; paramMap?: ParamMap }): void {
    this.lead_uid.next(lead_uid);
    this.user_uid.next(user_uid);

    if (paramMap) {
      const required: string[] = [
        'source',
        'campaign_name',
      ];

      const isReqiuredParamsPresent: boolean = every(
        required,
        (param: string) => paramMap.has(param),
      );

      if (!isReqiuredParamsPresent) { return; }

      this.viral_params.next({
        source: paramMap.get('source'),
        campaign_name: paramMap.get('campaign_name'),
      });
    }
  }

  track(payload: TrackingParams, isViral: boolean, isBypass?: boolean): Observable<any> {
    if (isViral) {
      return combineLatest([
        this.user_uid$,
        this.viral_params$,
      ])
        .pipe(
          take(1),
          switchMap(([user_uid, currentParams]: [string, TrackingParams]) => {
            const params: Dictionary<string> = pickBy(
              {
                ...payload,
                user_uid,
                ...currentParams,
              },
              identity,
            );

            return this.http.post(`${environment.api}/tracking`, null, { params });
          }),
        );
    }

    if (isBypass) {
      const params: Dictionary<string> = pickBy({ ...payload }, identity);

      return this.http.post(`${environment.api}/tracking`, null, { params });
    }

    return this
      .lead_uid$
      .pipe(
        take(1),
        switchMap((lead_uid: string) => {
          const params: Dictionary<string> = pickBy(
            {
              ...payload,
              lead_uid,
            },
            identity,
          );

          return this.http.post(`${environment.api}/tracking`, null, { params });
        }),
      );
  }

}
