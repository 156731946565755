import momentMini from 'moment-mini';
import { SimpleModalService } from 'ngx-simple-modal';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import type { IVerifyStatus, Lead } from '@core/types';

// Consts
import { VERIFY_STATUS } from '@consts/consts';
import { INTRO_VERIFY } from '@consts/intro';

// Components
import { IntroDialogComponent } from '@commons/dialogs/intro-dialog/intro-dialog.component';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

// Animations

@Component({
  selector: 'verify-home-value',
  templateUrl: './verify-home-value.component.html',
  styleUrls: ['./verify-home-value.component.sass'],
})
export class VerifyHomeValueComponent implements OnInit {

  @Input() leadDetails: Lead;

  @Input() title: string;

  private isPropertyDigest: boolean = !this.leadService.isDigestTypeOfLead;

  VERIFY_STATUS: IVerifyStatus = VERIFY_STATUS;

  requestAction$: Subscription;

  loadingState: any = {};

  constructor(
    private leadService: LeadService,
    private simpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    if (this.isPropertyDigest) {
      this.leadDetails.id = this.route.snapshot.params.uid;
    }
  }

  get checkNextReportTime(): boolean {
    if (!this.leadDetails.home_value_asked_at) { return false; }

    const currentTime: momentMini.Moment = momentMini();
    const timeStore: momentMini.Moment = momentMini(this.leadDetails.home_value_asked_at);

    return currentTime.diff(timeStore, 'h') < 24;
  }

  checkStatus(status: string): boolean {
    return this.leadDetails.home_value_verification_status === status;
  }

  onSentValue(): void {
    const verified: boolean = this.checkStatus(VERIFY_STATUS.VERIFIED);

    this.gaService.trackSimpleEvent(GoogleEvents.REQUEST_AVM, verified ? 'home_value' : 'net-sheet');

    if (this.isPropertyDigest) {
      this.simpleModalService.addModal(IntroDialogComponent, INTRO_VERIFY);

      return;
    }

    this.loadingState = { loading: true };
    this.requestAction$ = this
      .leadService
      .verifyHomeValue(this.leadDetails.id)
      .subscribe(
        () => {
          this.leadDetails.home_value_verification_status = this.VERIFY_STATUS.USER_REQUEST;
          this.leadDetails.home_value_asked_at = new Date().toISOString();

          timer(2000, 1000)
            .pipe(
              take(2),
            )
            .subscribe((value: number) => {
              if (!value) {
                this.loadingState = { loaded: true };

                return;
              }

              if (value === 1) {
                this.loadingState = {};
              }
            });
        },
      );
  }

}
