import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: [
    '../../../commons/header/header.component.sass',
    '../equity-tracker/equity-tracker.component.sass',
    '../loan-payment-tracker/loan-payment-tracker.component.sass',
    '../refinance-estimator/refinance-estimator.component.sass',
    '../loan-payment-tracker/principal-paydown-forecaster/principal-paydown-forecaster.component.sass',
    '../rent-estimator/monthly-rent-estimator/monthly-rent-estimator.component.sass',
    '../rent-estimator/airbnb-rent-estimator/airbnb-rent-estimator.component.sass',
    '../../../commons/rent-info/rent-info.component.sass',
    './loading-screen.component.sass',
  ],
})
export class LoadingScreenComponent implements OnInit {

  progress: number = 0;

  duration: number = 20000;

  loadingCountdown: number;

  timeStart: number = Date.now();

  title: string = 'Your report is loading…';

  ngOnInit(): void {
    requestAnimationFrame(this.frameLoop);
  }

  frameLoop = (): void => {
    const timing: number = (Date.now() - this.timeStart) / this.duration;

    if (timing > 1) {
      this.setProgress(1);
    } else {
      requestAnimationFrame(this.frameLoop);
      this.setProgress(this.easeOutQuad(timing));
    }
  };

  easeOutQuad(timing: number): number {
    return timing * (2 - timing);
  }

  setProgress(timing: number): void {
    const progress: number = Math.round(timing * 100);

    this.progress = progress;
  }

}
