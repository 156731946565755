import { Dictionary } from 'asap-team/asap-tools';

export const INTRO_VERIFY: Dictionary = {
  icon: '/assets/components/intro/verify.svg',
  title: 'Verify home value',
  message: 'When your client send request, you will get it in your SOA Labs admin panel. There you can verify home value, and your client will get notified.',
};
export const INTRO_LOAN: Dictionary = {
  icon: '/assets/components/intro/loan.svg',
  title: 'Loan update',
  message: 'Your client can add, remove or update their loan information if it is inaccurate or missing.',
};
export const INTRO_ASK: Dictionary = {
  icon: '/assets/components/intro/ask.svg',
  title: 'Ask a question',
  message: 'Your clients can ask you a question directly from digest interface. You will get an email notification about new question from client.',
};
