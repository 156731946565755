import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteExtrasStateResolve } from 'asap-team/asap-tools';

// Components
import { MainComponent } from '@components/main/main.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { UnsubscribeComponent } from '@components/unsubscribe/unsubscribe.component';
import { DownloadReportComponent } from '@components/download-report/download-report.component';
import { UnsubscribeFromViralComponent } from '@components/unsubscribe-from-viral/unsubscribe-from-viral.component';
import { ConfirmHomeownerEmailComponent } from '@components/confirm-homeowner-email/confirm-homeowner-email.component';
import { DownloadMortgageCheckupCertificateComponent } from '@components/download-mortgage-checkup-certificate/download-mortgage-checkup-certificate.component';

// Resolvers
import { HomeResolve } from '@core/resolvers/home/home.resolve';
import { ReportResolve } from '@core/resolvers/report/report.resolve';
import { LeadResolve } from '@core/resolvers/lead/lead.resolve';
import { MortgageCheckupCertificateResolve } from '@core/resolvers/mortgage-checkup-certificate/mortgage-checkup-certificate.resolve';

import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    resolve: { home: HomeResolve },
    component: AppComponent,
  },
  {
    path: 'not-found',
    pathMatch: 'full',
    component: NotFoundComponent,
    resolve: { state: RouteExtrasStateResolve },
  },
  {
    path: 'leads/:id/mortgage_checkups',
    component: DownloadMortgageCheckupCertificateComponent,
    resolve: { certificate: MortgageCheckupCertificateResolve },
  },
  {
    path: 'leads/:id/reports/:hash',
    component: DownloadReportComponent,
    resolve: {
      report: ReportResolve,
      lead: LeadResolve,
    },
  },
  {
    path: 'confirm_email',
    component: ConfirmHomeownerEmailComponent,
  },
  {
    path: ':uid/unsubscribe',
    component: UnsubscribeComponent,
    data: { unsubscribeFromEmail: true },
  },
  {
    path: 'viral-unsubscribe/:token',
    component: UnsubscribeFromViralComponent,
    data: { unsubscribeFromViralEmail: true },
  },
  {
    path: ':uid',
    component: MainComponent,
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
