import {
  Component,
  ComponentRef,
  Injector,
  Input,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { RentInfo } from '@core/types';

@UntilDestroy()
@Component({
  selector: 'monthly-rent-estimator',
  templateUrl: './monthly-rent-estimator.component.html',
  styleUrls: ['./monthly-rent-estimator.component.sass'],
})
export class MonthlyRentEstimatorComponent {

  @ViewChildren('chartContainer', { read: ViewContainerRef, emitDistinctChangesOnly: true }) private chartContainer: QueryList<ViewContainerRef>;

  @Input() rentInfo: RentInfo;

  constructor(
    private injector: Injector,
  ) {}

  private renderComponent(container: ViewContainerRef): void {
    import('@components/main/rent-estimator/monthly-rent-estimator/zillow-chart/zillow-chart.component')
      .then((m: any) => {
        const componentRef: ComponentRef<unknown> = container.createComponent(m.ZillowChartComponent, null, this.injector);

        (componentRef.instance as any).rentInfo = this.rentInfo;
      })
      .catch(() => {});
  }

  ngAfterViewInit(): void {
    this.renderComponent(this.chartContainer.first);
    this
      .chartContainer
      .changes
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((container: any) => {
        if (!container.first) { return; }

        this.renderComponent(this.chartContainer.first);
      });
  }

}
