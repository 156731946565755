export enum BLOCK {
  EQUITY = 'equity',
  CASH_FROM_HOME = 'cash_from_home',
  EXTRA_PRINCIPAL = 'extra_principal',
  REFINANCE_OPTIONS = 'refinance_options',
  BUY_ANOTHER_HOUSE = 'buy_another_house',
  BUY_ANOTHER_HOUSE_STEPPER = 'buy_another_house_stepper',
  RENT_OPPORTUNITIES = 'rent_opportunities',
  MORTGAGE_INSURANCE = 'mortgage_insurance',
  HOME_IMPROVEMENT = 'home_improvement',
}
