import momentMini from 'moment-mini';
import { last } from 'lodash-es';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import type {
  HomeValueChartArguments,
  HomeValueChartData,
  ResponseFees,
  Fees,
  SellingReport,
  FeesSubtotal,
  RefinanceReport,
  LineReport,
  FeesCommissionItem,
  HomeValueInfo,
  Lead,
  BlockPermittedType,
  LeadFinancingHistories,
  FeesItem,
} from '@core/types';

@Injectable({ providedIn: 'root' })
export class HelpersService {

  constructor(
    private route: ActivatedRoute,
  ) {}

  getBlockState(lead: Lead, blockName: string): { visible: boolean; blurred: boolean } {
    const block: BlockPermittedType = lead.blocks[blockName];

    if (!block.enabled) {
      if (!block.requirements) {
        return {
          visible: false,
          blurred: false,
        };
      }

      if (!this.route.snapshot.queryParamMap.has('preview')) {
        return {
          visible: false,
          blurred: false,
        };
      }

      return {
        visible: true,
        blurred: true,
      };
    }

    return {
      visible: true,
      blurred: false,
    };
  }

  getLeadFinancingHistoriesDataset({ data, valueForecast, lineColor, dashLineColor }: HomeValueChartArguments): HomeValueChartData {
    const datasets: any = [
      {
        data: [],
        colors: [],
        pointBorderColor: [],
        pointRadius: [],
        pointHoverRadius: [],
        pointBorderWidth: 3,
        pointHoverBackgroundColor: '#fff',
      },
      {
        data: [],
        colors: [],
        borderDash: [3, 7],
      },
    ];

    let gross_equity: any = [];
    let labels: any = [];
    let colors: any = [];
    let pointRadius: any = [];
    let pointBorderColor: any = [];
    let dashColors: any = [];

    data.forEach(
      (value: LeadFinancingHistories) => {
        gross_equity = [...gross_equity, value.gross_equity];
        labels = [...labels, momentMini.monthsShort(value.month - 1)];
        colors = [...colors, lineColor];
        pointRadius = [...pointRadius, 4];
      },
    );

    labels = [...labels, momentMini.monthsShort(last(data).month)];
    pointBorderColor = [...colors, dashLineColor];
    colors = [...colors, 'transparent'];
    pointRadius = [...pointRadius.slice(0, -1), 8, 5];
    dashColors = colors.map((value: any) => (value === 'transparent' ? dashLineColor : 'transparent'));

    datasets[0].data = gross_equity;
    datasets[0].colors = colors;
    datasets[0].pointBorderColor = pointBorderColor;
    datasets[0].pointRadius = pointRadius;
    datasets[0].pointHoverRadius = pointRadius;
    datasets[1].data = [...gross_equity, valueForecast];
    datasets[1].colors = dashColors;

    return { labels, datasets };
  }

  getLeadReport(data: ResponseFees): Fees {
    return {
      id: data.id,
      address_hash: data.address_hash,
      selling: {
        type: 'selling',
        home_value: data.home_value,
        loan_balance: data.loan_balance,
        closing_fees_amount: data.selling.closing_fees_amount,
        net_equity: data.selling.net_equity,
        lead_fees: data.selling.fees,
        user_fees: data.selling.commissions,
        disclaimer: data.selling.disclaimer,
      },
      refinance: {
        type: 'refinance',
        home_value: data.home_value,
        loan_balance: data.loan_balance + (data.refinance?.closing_fees_amount || data.cash_out?.closing_fees_amount),
        net_equity: data.refinance?.net_equity || data.cash_out?.net_equity,
        lead_fees: data.refinance?.fees || data.cash_out?.fees,
        user_fees: data.refinance?.commissions || data.cash_out?.commissions,
        disclaimer: data.refinance?.disclaimer || data.cash_out?.disclaimer,
      },
      line: {
        type: 'line',
        home_value: data.home_value,
        loan_balance: data.loan_balance,
        closing_fees_amount: data.line_of_equity.closing_fees_amount,
        net_equity: data.line_of_equity.net_equity,
      },
      permitted_actions: data.permitted_actions,
    };
  }

  calculateFeesSubtotal({ lead_fees, user_fees }: SellingReport | RefinanceReport | LineReport): FeesSubtotal {
    const total: FeesSubtotal = {
      sumOther: 0,
      sumBuyer: 0,
      sumSeller: 0,
      sumUserFees: 0,
    };

    if (!user_fees && !user_fees) { return null; }

    if (lead_fees) {
      // eslint-disable-next-line no-restricted-syntax
      for (const group of lead_fees) {
        group.fees.forEach((item: FeesItem) => {
          total.sumOther += item.other_amount ? +item.other_amount : 0;
          total.sumBuyer += item.buyer_amount ? +item.buyer_amount : 0;
          total.sumSeller += item.seller_amount ? +item.seller_amount : 0;
        });
      }
    }

    if (user_fees) {
      user_fees.commissions.map((item: FeesCommissionItem): number => {
        total.sumUserFees += item.value;

        return total.sumUserFees;
      });
    }

    return total;
  }

  getHomeValueInfo(data: Lead): HomeValueInfo {
    if (!data) { return null; }

    return {
      gross_equity: data.gross_equity,
      growth_equity: data.growth_equity,
      home_value: data.home_value,
      chart: data.lead_financing_histories,
      current_diff_valuation: data.current_diff_valuation,
    };
  }

}
