import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

@Component({
  selector: 'calc-count-item',
  templateUrl: './calc-count-item.component.html',
  styleUrls: ['./calc-count-item.component.sass'],
})
export class CalcCountItemComponent implements OnInit {

  @Input() title: string;

  @Input() propName: 'bedrooms' | 'bathrooms' | 'sq_ft';

  @Input() min: number = 0;

  @Input() max: number = 10;

  @Input() step: number = 1;

  @Input() initValue: number;

  @Output() emittedValue: EventEmitter<{ key: string; value: string }> = new EventEmitter<{ key: string; value: string }>();

  value: number;

  payload: number;

  constructor(
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.value = this.initValue;
    this.payload = this.min;
  }

  get isIncrease(): boolean {
    const maxPoint: number = this.initValue + this.max;

    return this.value >= maxPoint;
  }

  get isDecrease(): boolean {
    return this.value <= this.initValue;
  }

  decrease(): void {
    this.value -= this.step;
    this.payload -= this.step;
    this.emittedValue.emit({ key: this.propName, value: `${this.payload}` });
    this.gaService.trackSimpleEvent(GoogleEvents.USE_IMPROVEMENT_CALC, 'home_value');
  }

  increase(): void {
    this.value += this.step;
    this.payload += this.step;
    this.emittedValue.emit({ key: this.propName, value: `${this.payload}` });
    this.gaService.trackSimpleEvent(GoogleEvents.USE_IMPROVEMENT_CALC, 'home_value');
  }

}
