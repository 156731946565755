<button class="button"
        #btn
        [type]="type"
        [ngClass]="getButtonClass()"
        [disabled]="disabled"
        (click)="onClick.emit($event)"
        [promiseBtn]="busy$">
  <span class="button-label m-auto">{{ label }}</span>
  <div class="button-busy">
    <div class="busy__i busy__i--1"></div>
    <div class="busy__i busy__i--2"></div>
    <div class="busy__i busy__i--3"></div>
  </div>
</button>
