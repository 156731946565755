import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QUERY_PARAM } from '@consts/consts';

@Directive({ selector: '[hideInPreview]' })
export class HideInPreviewModeDirective implements OnInit {

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.has(QUERY_PARAM.PREVIEW)) {
      this.viewContainerRef.clear();
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
