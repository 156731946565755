<div class="loan-icon">
  <img src="/assets/components/my-loans/icon_money-bag.svg"/>
  <div class="loan-primary" *ngIf="loan.primary"></div>
</div>
<div class="loan-amount">{{ loan.loan_amount | amount }}</div>
<p class="loan-info">
  {{ formatLoanType(loan.loan_type) }}<br/>Fixed {{ loan.loan_term }} years
  <span>at</span> {{ loan.interest_rate }}% interest
  rate<br/><span>Loan Date:</span> {{ loan.loan_date | date: 'MMM yyyy' }}
</p>
<div class="loan-insurance" *ngIf="loan.insurance_amount">
  <div class="loan-insurance__icon"></div>
  I pay <b>{{ loan.insurance_amount | amount }}</b> monthly mortgage insurance (PMI)
</div>
<div class="loan-verified" *ngIf="loan.primary && !lead.loan_verified">
  <div class="loan-verified__title">Are the numbers above correct?</div>
  <div class="loan-verified__actions">
    <button class="btn btn-outline" type="button" [promiseBtn]="action$" (click)="verifyLoan()">Yes</button>
    <button class="btn btn-outline" type="button" (click)="editLoan()">No</button>
  </div>
</div>
<button class="btn loan-action-btn"
        *ngIf="!loan.primary && isShowPrimaryBtn"
        type="button"
        [promiseBtn]="action$"
        (click)="setPrimary()">
  Set as primary
</button>
<button class="btn loan-action-btn"
        *ngIf="loan.primary && !loan.insurance_present"
        type="button"
        (click)="editInsurance()">
  I pay mortgage insurance
</button>
<button class="btn loan-edit-btn" type="button" (click)="editLoan()">
  <img src="/assets/components/my-loans/icon_edit.svg"/>
</button>
