<div class="modal-container intro">
  <div class="modal-content">
    <div class="modal-close" [inlineSVG]="'assets/icons/close_circle.svg'" (click)="close()"></div>
    <div class="modal-body">
      <h2 class="intro__title">Is this is your correct cell phone number?</h2>
      <form [formGroup]="form">
        <div class="input-group">
          <div class="icon" [inlineSVG]="'assets/icons/call.svg'"></div>
          <input class="form-control"
                 required="required"
                 type="phone"
                 formControlName="phone"
                 mask="(000) 000-0000"
                 placeholder="(000) 000-0000"/>
          <div class="invalid-tooltip" *ngIf="!form.valid">Phone is incorrect</div>
        </div>
      </form>
      <div class="disclaimer-text text-left">By clicking below, you hereby consent to receive calls and texts
        from {{ disclaimerNames }} which may be dialed using automated technology, at the phone number listed above. You
        may opt out and cancel this authorization at any time and you
        are not required to consent in order to use the services of {{ disclaimerNames }}</div>
    </div>
    <div class="modal-footer">
      <iq-button type="button"
                 color="orange"
                 label="Yes! Let me see the report"
                 [disabled]="!form.valid"
                 [promiseBtn]="action"
                 (onClick)="confirm()"></iq-button>
    </div>
  </div>
</div>
