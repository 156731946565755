import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { Lead } from '@core/types';

// Component
import { VerifyPhoneDialogComponent } from '@commons/dialogs/verify-phone-dialog/verify-phone-dialog.component';

// Services
import { SimpleModalService } from 'ngx-simple-modal';
import { timer } from 'rxjs';
import { GAService } from '@core/helpers/ga/ga.service';
import { GoogleEvents } from '@consts/enums';

const SHOW_VERIFY_NUMBER_DIALOG_DELAY: number = 20000;

@UntilDestroy()
@Component({
  selector: 'sms-subscribe',
  templateUrl: './sms-subscribe.component.html',
  styleUrls: ['./sms-subscribe.component.sass'],
})
export class SmsSubscribeComponent implements OnInit {

  @Input() lead: Lead;

  @Output() newLead: EventEmitter<Lead> = new EventEmitter<Lead>();

  private verifyPhoneModalIsOpened: boolean = false;

  constructor(
    private simpleModalService: SimpleModalService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    timer(SHOW_VERIFY_NUMBER_DIALOG_DELAY)
      .subscribe(
        () => !this.verifyPhoneModalIsOpened && this.verifyNumber(),
      );
  }

  verifyNumber(place?: 'sticky_bar' | 'block'): void {
    if (place) {
      this.gaService.trackSimpleEvent(GoogleEvents.GET_UPDATES, 'subscribe', place);
    }

    this.verifyPhoneModalIsOpened = true;

    this
      .simpleModalService
      .addModal(VerifyPhoneDialogComponent, {
        phoneNumber: this.lead.phone,
        uid: this.lead.id,
        disclaimerNames: this.disclaimerNames,
      })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((result: Lead) => {
        if (result) {
          this.newLead.emit(result);
        }
      });
  }

  get disclaimerNames(): string {
    const agentName: string = this.lead.agent?.name;
    const lenderName: string = this.lead.lender?.name;
    const companyName: string = this.lead.lender?.company_name;

    if (companyName) { return companyName; }

    if (agentName && lenderName) {
      return `${agentName} and ${lenderName}`;
    }

    return agentName || lenderName;
  }

}
