import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import type { Lead, Loan } from '@core/types';

// Consts
import { LOANS_TOASTS } from '@consts/enums';

// Services
import { LoansService } from '@core/services/loans/loans.service';

@UntilDestroy()
@Component({
  selector: 'loan-item',
  templateUrl: './loan-item.component.html',
  styleUrls: ['./loan-item.component.sass'],
})
export class LoanItemComponent {

  @Input() lead: Lead;

  @Input() loan: Loan;

  @Input() isShowPrimaryBtn: boolean;

  @Output() emitEdit: EventEmitter<Loan> = new EventEmitter();

  action$: Subscription;

  constructor(
    private loansService: LoansService,
    private toastr: ToastrService,
  ) {}

  formatLoanType(type: string): string {
    if (!type) { return ''; }

    const loanType: string = type.toLowerCase();

    return loanType === 'fha' || loanType === 'va' ? `${loanType.toUpperCase()} financing` : `${loanType[0].toUpperCase()}${loanType.substr(1)} financing`;
  }

  verifyLoan(): void {
    this.action$ = this
      .loansService
      .verifyLoans(this.lead?.id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.lead.loan_verified = true;
          this.toastr.success(LOANS_TOASTS.VERIFY);
        },
      );
  }

  editLoan(): void {
    this.emitEdit.emit(this.loan);
  }

  editInsurance(): void {
    this.editLoan();
    this.loansService.emitInsuranceEdit(true);
  }

  setPrimary(): void {
    this.action$ = this
      .loansService
      .setPrimaryLoan(this.lead.id, this.loan.uid)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.toastr.success(LOANS_TOASTS.MARK_AS_PRIMARY);
        },
      );
  }

}
