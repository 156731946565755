import { Component } from '@angular/core';
import { LeadService } from '@core/services/lead/lead.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeEmailType } from '@components/unsubscribe/enums/unsubscribe-email-type.enum';

@UntilDestroy()
@Component({
  selector: '',
  templateUrl: './monthly-digest-unsubscribe.component.html',
  styleUrls: ['./monthly-digest-unsubscribe.component.sass'],
})
export class MonthlyDigestUnsubscribeComponent {

  isSubscribed: boolean = true;

  isLoading: boolean = false;

  constructor(
    private leadService: LeadService,
    private route: ActivatedRoute,
  ) {
  }

  unsubscribeUserByUpdateEmailSettings(): void {
    this.isLoading = true;

    const { uid } = this.route.snapshot.params;

    this
      .leadService
      .unsubscribeByUpdateEmailSettings(uid, UnsubscribeEmailType.monthlyDigest)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(() => {
        this.isSubscribed = !this.isSubscribed;
      });
  }

}
