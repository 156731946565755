<div class="modal-container help">
  <div class="modal-content">
    <div class="modal-close" [inlineSVG]="'assets/icons/close_circle.svg'" (click)="close()"></div>
    <div class="modal-body">
      <div class="help-item">
        <h2>Help</h2>
      </div>
      <div class="help-item" (click)="getInfo('pmi')">
        <h3>What is the PMI?</h3>
        <img src="/assets/icons/dropdown-arrow-grey.svg"/></div>
      <div class="help-item" (click)="getInfo('conventional')">
        <h3>How long does Conventional PMI?</h3>
        <img src="/assets/icons/dropdown-arrow-grey.svg"/></div>
      <div class="help-item" (click)="getInfo('ltv')">
        <h3>What is the LTV ratio?</h3>
        <img src="/assets/icons/dropdown-arrow-grey.svg"/></div>
    </div>
  </div>
</div>
