import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { EMAIL_PATTERN } from 'asap-team/asap-tools';

import type { Lead } from '@core/types';

// Consts
import { COMMON_TOAST } from '@consts/enums';
import { changeEmailDialogErrors } from '@consts/form-errors';

// Services
import { LeadService } from '@core/services/lead/lead.service';

import { TypedFormGroup } from '@core/types/form-group-config.type';
import type { ChangeEmailDialogModel } from './change-email-dialog.model';

@UntilDestroy()
@Component({
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.sass'],
})
export class ChangeEmailDialogComponent
  extends SimpleModalComponent<ChangeEmailDialogModel, boolean>
  implements ChangeEmailDialogModel, OnInit {

  lead: Lead;

  form: FormGroup;

  formErrors: any = changeEmailDialogErrors;

  action$: Subscription;

  constructor(
    private fb: FormBuilder,
    private leadService: LeadService,
    private toastr: ToastrService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group<TypedFormGroup<Lead>>(
      { email: [this.lead?.email, [Validators.required, Validators.pattern(EMAIL_PATTERN)]] },
    );
  }

  save(): void {
    this
      .leadService
      .changeHomeownerEmail(this.lead.id, this.form.value)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.toastr.success(COMMON_TOAST.EMAIL_SENT);
        this.result = true;
        this.close();
      });
  }

}
