import { SimpleModalService } from 'ngx-simple-modal';
import { Component, OnInit } from '@angular/core';
import {
  Router,
  RouterEvent,
  NavigationStart,
  ActivatedRoute,
  ParamMap,
  NavigationEnd,
} from '@angular/router';

// Services
import { GAService } from '@core/helpers/ga/ga.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    private gaService: GAService,

  ) {}

  ngOnInit(): void {
    // set analytics campaign params
    this.route.queryParamMap
      .subscribe((paramMap: ParamMap) => {
        this.gaService.init(paramMap);
      });

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.simpleModalService.removeAll();
      }

      if (event instanceof NavigationEnd) {
        this.gaService.trackPageForUrl(event.urlAfterRedirects);
      }
    });
  }

}
