import { Component, Input } from '@angular/core';

import type { User } from '@core/types';
import { USER_ROLE } from '@consts/enums';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass'],
})
export class UserInfoComponent {

  @Input() user: User;

  USER_ROLE: any = USER_ROLE;

}
