import { OnInit, Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPermitted } from 'asap-team/asap-tools';

import type { Observable, Subscription } from 'rxjs';
import type {
  Fees,
  RentInfo,
  Lead,
  AirbnbInfo,
  InsuranceInfo,
  InterestAddsUp,
  IExtraPrincipal,
  Blocks,
} from '@core/types';

// Consts
import { TRACKING } from '@consts/analytics';
import { PERMITTED_ACTION, BLOCK } from '@consts/enums';
import { QUERY_PARAM } from '@consts/consts';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { ThemeService } from '@core/helpers/theme/theme.service';
import { TrackingService } from '@core/helpers/tracking/tracking.service';
import { DigestDataService } from '@core/helpers/digest-data/digest-data.service';
import { HelpersService } from '@core/helpers/helpers.service';

@UntilDestroy()
@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass'],
})
export class MainComponent implements OnInit {

  lead: Lead;

  fees: Fees;

  interestAddsUpData: InterestAddsUp;

  extraPrincipalData: IExtraPrincipal;

  rentInfo: RentInfo;

  airbnbInfo: AirbnbInfo;

  insuranceInfo: InsuranceInfo;

  scrollSubscription$: Subscription;

  isPreview: boolean;

  viralVersion: string;

  activateLink: string;

  reportUpdating: Observable<boolean> = this.digestDataService.reportUpdating$;

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadService,
    private digestDataService: DigestDataService,
    private trackingService: TrackingService,
    private themeService: ThemeService,
    private helpersService: HelpersService,
  ) {}

  ngOnInit(): void {
    this.checkQueryParams();
    this.themeService.set();
    this.trackingService.track(TRACKING.viral_report_open, true).subscribe();
    this.getFullReport();
    this.subscribes();
  }

  private subscribes(): void {
    this
      .digestDataService
      .report$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (blocks: Blocks) => {
          [
            this.lead,
            this.interestAddsUpData,
            this.extraPrincipalData,
            this.rentInfo,
            this.airbnbInfo,
            this.insuranceInfo,
            this.fees,
          ] = blocks;
        },
      );
  }

  private checkQueryParams(): void {
    this.isPreview = this.route.snapshot.queryParamMap.has(QUERY_PARAM.PREVIEW);
    this.viralVersion = this.route.snapshot.queryParamMap.get(QUERY_PARAM.VERSION);
    this.activateLink = this.route.snapshot.queryParamMap.get(QUERY_PARAM.COMPLETE_URL);
  }

  private getFullReport(): void {
    const uid: string = this.route.snapshot.paramMap.get(QUERY_PARAM.UID);
    const address_hash: string = this.route.snapshot.queryParamMap.get(QUERY_PARAM.ADDRESS_HASH);

    this
      .digestDataService
      .getReportData({ uid, address_hash })
      .pipe(
        tap(() => {
          window.scrollTo(0, 0);

          if (!this.isPreview) {
            this.trackingService.init({ user_uid: uid, lead_uid: this.lead.id });
            this.trackDigestUpdate();
          }

          this.trackingService.track(TRACKING.viral_report_viewed, true).subscribe();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  get isPropertyDigest(): boolean {
    return !this.leadService.isDigestTypeOfLead;
  }

  get isHavePropertyClass(): boolean {
    return this.isPropertyDigest || this.lead ? !this.lead.digest_subscribed_sms : false;
  }

  trackDigestUpdate(): void {
    if (this.isPropertyDigest) { return; }

    this
      .leadService
      .updateDigestViews(this.lead.id)
      .pipe(
        untilDestroyed(this),
      )
      .subscribe();
  }

  isShow(lead: Lead, param: string): boolean {
    if (!lead) { return false; }

    switch (param) {
      case 'viral-sidebar': {
        return !!this.activateLink;
      }
      case 'equity-tracker': {
        return this.helpersService.getBlockState(lead, BLOCK.EQUITY).visible;
      }
      case 'any-loans': {
        return lead.alert_no_loans && !this.isPreview;
      }
      case 'primary-loans': {
        return lead.alert_no_primary_loan && !this.isPreview;
      }
      case 'transaction-estimator': {
        return !!this.fees && this.helpersService.getBlockState(lead, BLOCK.CASH_FROM_HOME).visible;
      }
      case 'mortgage-insurance': {
        return this.helpersService.getBlockState(lead, BLOCK.MORTGAGE_INSURANCE).visible;
      }
      case 'loan-payment-tracker': {
        return this.helpersService.getBlockState(lead, BLOCK.EXTRA_PRINCIPAL).visible;
      }
      case 'refinance-estimator': {
        return this.helpersService.getBlockState(lead, BLOCK.REFINANCE_OPTIONS).visible;
      }
      case 'buy-another-house': {
        return this.helpersService.getBlockState(lead, BLOCK.BUY_ANOTHER_HOUSE).visible;
      }
      case 'rent-estimator': {
        return this.helpersService.getBlockState(lead, BLOCK.RENT_OPPORTUNITIES).visible;
      }
      case 'zillow': {
        return !!this.rentInfo?.rent_amount && !!this.rentInfo?.rent_high && !!this.rentInfo?.rent_low;
      }
      case 'airbnb': {
        return !!this.airbnbInfo?.adr;
      }
      case 'sms-subscribe': {
        return !lead.digest_subscribed_sms && !this.activateLink && !this.isPreview;
      }
      case 'subscribe-to-digest': {
        return !!lead && isPermitted(lead.permitted_actions, [PERMITTED_ACTION.SUBSCRIBE, PERMITTED_ACTION.UNSUBSCRIBE]);
      }
      default: {
        return false;
      }
    }
  }

  updateSmsSubscribe({ digest_subscribed_sms }: Lead): void {
    this.lead.digest_subscribed_sms = digest_subscribed_sms;
  }

}
