import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

import type { Lead } from '@core/types';

// Consts
import { ROUTE } from '@consts/routes';

// Services
import { LeadService } from '@core/services/lead/lead.service';

@Injectable()
export class LeadResolve implements Resolve<any> {

  constructor(
    private router: Router,
    private leadService: LeadService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Lead> {
    const uid: string = route.params.id;

    return this
      .leadService
      .getLead({ uid })
      .pipe(
        catchError(() => {
          this.router.navigate([ROUTE.alias.NOT_FOUND]);

          return EMPTY;
        }),
      );
  }

}
