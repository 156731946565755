import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Database, ref, objectVal } from '@angular/fire/database';

// Consts
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class FirebaseService {

  constructor(
    private db: Database,
  ) { }

  reportStatusObserver(uid: string): Observable<boolean> {
    return objectVal(ref(this.db, `${environment.firebaseCollection}/leads/${uid}/processing`));
  }

}
