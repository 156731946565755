import { catchError } from 'rxjs/operators';
import {
  Component, Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import { Subscription, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { APIErrorResponse, Dictionary } from 'asap-team/asap-tools';

import type {
  Fees, FeesSubtotal, Lead, LineReport, RefinanceReport, ReportType, SellingReport,
} from '@core/types';

// Consts
import { TRACKING } from '@consts/analytics';
import { REPORT_TYPE } from '@consts/consts';
import { PREPARED_QUESTIONS } from '@consts/prepared-questions';
import { COMMON_TOAST, GoogleEvents, PERMITTED_ACTION, USER_ROLE } from '@consts/enums';

// Pipes
import { CurrencyPipe } from '@angular/common';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { TrackingService } from '@core/helpers/tracking/tracking.service';
import { HelpersService } from '@core/helpers/helpers.service';
import { GAService } from '@core/helpers/ga/ga.service';

@UntilDestroy()
@Component({
  selector: 'transaction-estimator',
  templateUrl: './transaction-estimator.component.html',
  styleUrls: ['./transaction-estimator.component.sass'],
  providers: [CurrencyPipe],
})
export class HomeReportComponent implements OnInit, OnChanges {

  @Input() fees: Fees;

  @Input() lead: Lead;

  report: SellingReport | RefinanceReport | LineReport;

  dateDownload: string;

  REPORT_TYPE: Dictionary<ReportType> = REPORT_TYPE;

  currentReportType: ReportType = REPORT_TYPE.SELLING;

  isShowDisclaimer: boolean = false;

  total: FeesSubtotal = {
    sumOther: 0,
    sumBuyer: 0,
    sumSeller: 0,
    sumUserFees: 0,
  };

  action: Subscription;

  countChart: number = 200;

  imgFolder: string;

  get preparedQuestions(): string[] {
    if (this.currentReportType === REPORT_TYPE.LINE) {
      return PREPARED_QUESTIONS.report_line_tab;
    }

    const role: string = this.currentReportType === REPORT_TYPE.SELLING ? 'seller' : 'lender';

    return [
      'Can you help me understand all the fees?',
      `Are the ${role} fees negotiable?`,
    ];
  }

  constructor(
    private toastr: ToastrService,
    private leadService: LeadService,
    private trackingService: TrackingService,
    private helpersService: HelpersService,
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.setReportType();
    this.setUrls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fees) {
      this.setReportType();
    }
  }

  private setUrls(): void {
    const { home_value_verified_at } = this.lead;

    this.dateDownload = home_value_verified_at || (new Date()).toString();
    this.imgFolder = 'assets/components/transaction-estimator/home_iq';
  }

  public trackTabClick(type: ReportType): void {
    // eslint-disable-next-line default-case
    switch (type) {
      case REPORT_TYPE.SELLING: {
        this.trackingService.track(TRACKING.client_tab_sell_house_clicked, false).subscribe();
        this.gaService.trackSimpleEvent(GoogleEvents.SELL_TAB, 'net-sheet');
        break;
      }
      case REPORT_TYPE.REFINANCE: {
        this.trackingService.track(TRACKING.client_tab_refinance_clicked, false).subscribe();
        this.gaService.trackSimpleEvent(GoogleEvents.REFI_TAB, 'net-sheet');
        break;
      }
      case REPORT_TYPE.LINE: {
        this.trackingService.track(TRACKING.client_tab_line_of_equity_clicked, false).subscribe();
        this.gaService.trackSimpleEvent(GoogleEvents.EQUITY_TAB, 'net-sheet');
        break;
      }
    }
  }

  private resetSubtotal(): void {
    Object.keys(this.total).forEach((key: string) => {
      this.total[key] = 0;
    });
  }

  get isNegativeNetEquity(): boolean {
    return this.report?.net_equity < 0;
  }

  setReportType(): void {
    const type: any = this.lead.owner.role === USER_ROLE.agent ? REPORT_TYPE.SELLING : REPORT_TYPE.REFINANCE;

    this.switchReportTypeTo(type);
  }

  switchReportTypeTo(type: ReportType): void {
    this.currentReportType = type;
    this.resetSubtotal();
    this.report = this.fees[this.currentReportType];

    if (type !== REPORT_TYPE.LINE) {
      this.total = this.helpersService.calculateFeesSubtotal(this.fees[this.currentReportType]);
    }
  }

  ofType(type: ReportType): boolean {
    return this.currentReportType === type;
  }

  getDownloadLink(type: ReportType): string {
    return `/leads/${this.fees.id}/reports/${this.fees.address_hash}?report_type=${type}`;
  }

  showDisclaimer({ disclaimer }: SellingReport | RefinanceReport | LineReport): void {
    this.countChart = disclaimer.length;
    this.isShowDisclaimer = true;
  }

  isShowDownloadLink(type: ReportType): boolean {
    switch (type) {
      case REPORT_TYPE.SELLING: {
        return this.leadService.isDigestTypeOfLead
          && !!~this.fees.permitted_actions.indexOf(PERMITTED_ACTION.DOWNLOAD_SELLING_REPORT);
      }
      case REPORT_TYPE.REFINANCE: {
        return this.leadService.isDigestTypeOfLead
          && !!~this.fees.permitted_actions.indexOf(PERMITTED_ACTION.DOWNLOAD_REFINANCE_REPORT);
      }
      default: {
        return false;
      }
    }
  }

  askFees(): void {
    this.action = this
      .leadService
      .verifyFees(this.fees.id)
      .pipe(
        untilDestroyed(this),
        catchError((error: APIErrorResponse) => {
          this.toastr.warning(COMMON_TOAST.SOMETHING_WRONG);

          return throwError(() => error);
        }),
      )
      .subscribe(() => {
        this.toastr.success(COMMON_TOAST.SUCCESS);

        const event: GoogleEvents = !this.report?.lead_fees ? GoogleEvents.NO_FEES : GoogleEvents.REQUEST_FEES;

        const label: string = this.ofType(REPORT_TYPE.REFINANCE) ? 'refi_tab' : 'sell-tab';

        this.gaService.trackSimpleEvent(event, 'net-sheet', label);
      });
  }

  downloadButtonClicked(event: MouseEvent & { target: { href: string }; preventDefault: () => void }): void {
    event.preventDefault();

    const label: string = this.ofType(REPORT_TYPE.REFINANCE) ? 'refi_tab' : 'sell-tab';

    this.gaService.trackSimpleEvent(GoogleEvents.DOWNLOAD_FEES, 'net-sheet', label);

    const url: string = event.target.href;

    setTimeout(() => {
      window.location.href = url;
    }, 300);
  }

}
