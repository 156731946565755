<div class="container">
  <div class="loading-dots loading-dots--lg" *ngIf="isLoading; else unsubscribeTemplate">
    <div class="loading-dots__item loading-dots__item--1"></div>
    <div class="loading-dots__item loading-dots__item--2"></div>
    <div class="loading-dots__item loading-dots__item--3"></div>
  </div>

 <ng-template #unsubscribeTemplate>
   <div *ngIf="isSubscribed" class="unsubscribe-reason">
     <h3 class="subscribe__title subscribe__title-inside">Do you want to unsubscribe?</h3>
     <button class="btn unsubscribe-user" type="button" (click)="unsubscribeUserByUpdateEmailSettings()">Unsubscribe me</button>
   </div>
   <div *ngIf="!isSubscribed">
     <h3>You have been unsubscribed</h3>
   </div>
 </ng-template>
</div>
