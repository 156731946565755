<h3 class="verify-title text-center">{{ title }}</h3>
<div class="verify-button-block">
  <button class="verify-btn btn btn-primary btn-center"
          type="button"
          (click)="onSentValue()"
          [promiseBtn]="requestAction$"
          [disabled]="checkNextReportTime && checkStatus(VERIFY_STATUS.USER_REQUEST)"
          [ngClass]="loadingState">
    <span class="verify-button-text">{{ checkStatus(VERIFY_STATUS.VERIFIED) ? 'Reverify my home’s value!' : 'Verify my home’s value!' }}</span>
    <div class="verify-btn-spinner">
      <img src="/assets/icons/black-spinner.svg"/>
    </div>
    <div class="verify-btn-check">
      <img src="/assets/icons/black-check.svg"/>
    </div>
  </button>
</div>
<div class="verify-caption caption-text text-center" *ngIf="checkStatus(VERIFY_STATUS.USER_REQUEST)">
  <i [inlineSVG]="'assets/icons/check.svg'"></i>You’ve requested a home verification
  on {{ leadDetails.home_value_asked_at | date:"MMM d, y" }}
</div>
<div class="verify-caption caption-text text-center" *ngIf="checkStatus(VERIFY_STATUS.VERIFIED)">
  <i [inlineSVG]="'assets/icons/check.svg'"></i>Verified by {{ leadDetails?.agent?.name }}
  on {{ leadDetails.home_value_verified_at | date:"MMM d, y" }}
</div>
