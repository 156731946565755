import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  AfterViewInit,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { take } from 'rxjs/operators';

// Consts
import { TRACKING } from '@consts/analytics';

// Services
import { TrackingService } from '@core/helpers/tracking/tracking.service';

@Component({
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.sass'],
})
export class DownloadReportComponent implements AfterViewInit {

  @ViewChild('link', { static: true }) element: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private location: Location,
    private trackingService: TrackingService,
  ) {}

  ngAfterViewInit(): void {
    this.initDownload(this.activatedRoute.snapshot.data.report);
  }

  private initDownload(url: string): void {
    if (!url) { return; }

    this.renderer.setAttribute(this.element.nativeElement, 'href', url);
    this.renderer.setAttribute(this.element.nativeElement, 'download', `${url}`);
    this.trackingService.init({ lead_uid: this.activatedRoute.snapshot.data.lead.id });
    this
      .activatedRoute
      .queryParamMap
      .pipe(
        take(1),
      )
      .subscribe(() => {
        this.trackingService.track(TRACKING.client_download_pdf_clicked, false).subscribe();

        setTimeout(() => this.element.nativeElement.click(), 1000);
      });
  }

  back(): void {
    this.location.back();
  }

}
