import { keys } from 'lodash-es';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type { Lead } from '@core/types';

// Consts
import { UNSUBSCRIBE_REASON } from '@consts/enums';

// Services
import { LeadService } from '@core/services/lead/lead.service';
import { APIErrorResponse } from 'asap-team/asap-tools';
import { TypedFormGroup } from '@core/types/form-group-config.type';

@UntilDestroy()
@Component({
  templateUrl: './unsubscribe-from-viral.component.html',
  styleUrls: ['./unsubscribe-from-viral.component.sass'],
})
export class UnsubscribeFromViralComponent implements OnInit {

  lead: Lead;

  form: FormGroup;

  isLoading: boolean = false;

  isSubscribed: boolean = true;

  auth_token: string;

  UNSUBSCRIBE_REASON: any = UNSUBSCRIBE_REASON;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private leadService: LeadService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.auth_token = this.route.snapshot.params.token;
    this.form = this.fb.group<TypedFormGroup<{ reason: string }>>({ reason: [UNSUBSCRIBE_REASON.NOT_INTERESTED, [Validators.required]] });
  }

  get unsubscribeReasons(): string[] {
    return keys(this.UNSUBSCRIBE_REASON);
  }

  unsubscribeFromDigest(): void {
    const unsubscribe_reason: any = this.form.get('reason').value;

    this.isLoading = true;
    this
      .leadService
      .unsubscribeFromViral({ unsubscribe_reason, auth_token: this.auth_token })
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        () => {
          this.isLoading = false;
          this.isSubscribed = !this.isSubscribed;
        },
        (error: APIErrorResponse) => {
          this.toastr.warning(error.error ? error.error.errors[0].detail : error.statusText);
          this.isLoading = false;
        },
      );
  }

}
