<div class="card">
  <div class="card__content">
    <h2 class="card__content-title text-center" *ngIf="checkState('monthly')">Estimated rental value</h2>
    <h2 class="card__content-title text-center" *ngIf="checkState('airbnb')">Daily rental with Airbnb</h2>
    <div class="btn-tabs" *ngIf="isShowRentInfo && isShowAirbnbInfo">
      <button class="btn-tabs__item" [class.active]="checkState('monthly')" (click)="setState('monthly')">
        Long term rental
      </button>
      <button class="btn-tabs__item" [class.active]="checkState('airbnb')" (click)="setState('airbnb')">
        Daily rental with Airbnb
      </button>
    </div>
    <monthly-rent-estimator *ngIf="isShowRentInfo && checkState('monthly')"
                            [rentInfo]="rentInfo"></monthly-rent-estimator>
    <airbnb-rent-estimator *ngIf="isShowAirbnbInfo && checkState('airbnb')"
                           [airbnbInfo]="airbnbInfo"></airbnb-rent-estimator>
  </div>
  <ng-template hideInPreview>
    <send-question [userId]="lead.id"
                   [user]="lead.owner"
                   [eventCategory]="'rent'"
                   [title]="'airbnb'"
                   [preparedQuestions]="preparedQuestions"></send-question>
  </ng-template>
</div>
