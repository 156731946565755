import {
  Component,
  ViewChild,
  ViewContainerRef,
  Injector,
  ComponentRef,
  OnInit,
} from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

// Consts
import { RightSidebarModel } from './right-sidebar.model';

@Component({
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.sass'],
})
export class RightSidebarComponent
  extends SimpleModalComponent<RightSidebarModel, any>
  implements RightSidebarModel, OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

  payload: any;

  component: { load: () => Promise<any> };

  constructor(
    private injector: Injector,
  ) {
    super();
  }

  ngOnInit(): void {
    this.resolveComponent(this.component);
  }

  private async createComponent(componentModule: { load: () => Promise<any> }): Promise<void> {
    if (!componentModule) { return; }

    this.container.clear();

    const componentRef: ComponentRef<unknown> = this.container.createComponent(await componentModule.load(), null, this.injector);

    (componentRef.instance as any).payload = this.payload;
  }

  private resolveComponent(component: any): void {
    if (!component) {
      console.log('no component factory provided for sidebar content');
    }

    this.createComponent(component);
  }

}
