import { range } from 'lodash-es';

export const MONTHLY_SPENDINGS: number[] = [
  ...range(500, 6100, 100),
  ...range(6250, 10000, 250),
  ...range(10000, 15000, 500),
  ...range(15000, 21000, 1000),
  ...range(22000, 52000, 2000),
  ...range(55000, 100000, 5000),
  ...range(100000, 210000, 10000),
  ...range(200000, 550000, 50000),
];
