<div class="slider" #sliderContainer>
  <div class="slider-main">
    <div class="slider-user-equity" #userEquityRange></div>
    <div class="slider-active" #downpaymentRange [hidden]="!user_equity"></div>
  </div>
  <div class="slider-thumb" #sliderThumb>
    <div class="slider-thumb-label" *ngIf="!label">$0</div>
    <div class="slider-thumb-label" *ngIf="label">
      {{ label | formatNumber: '1' : true }}
    </div>
  </div>
  <div class="slider-labels m-t-16">
    <div class="slider-label slider-label--min"
         #userDownpaymentLabel
         [class.hidden]="!isShowUserDownpaymentLabel">
      <div class="f-13-600 f-c-east-bay">{{ downpayment_limit_min | formatNumber: '1' : true }}</div>
      <div class="f-12-normal f-c-gull-gray">Minimum<br/>down</div>
    </div>
    <div class="slider-label slider-label--user-equity"
         #userEquityLabel
         [class.hidden]="!user_equity"
         [class.align-right]="isChangeEquityLabelAlign">
      <div class="labels f-13-600 f-c-east-bay">{{ user_equity | formatNumber: '1' : true }}</div>
      <div class="labels f-12-normal f-c-gull-gray">Your&nbsp;equity<br/>maximum</div>
    </div>
    <div class="slider-label slider-label--extra"
         #userMoneyAmountLabel
         [class.hidden]="!isShowUserMoneyAmountLabel">
      <div class="f-13-600 f-c-clear-blue">+{{ userMoneyAmount | formatNumber: '1' : true }}</div>
      <div class="f-12-normal f-c-gull-gray">
        Your&nbsp;own<br/>money
      </div>
    </div>
    <div class="slider-label slider-label--no-equity" *ngIf="!user_equity">
      <div class="f-12-normal f-c-gull-gray">
        You have no equity in your home yet.<br/>Downpayment amount is expected to be your own money
      </div>
    </div>
  </div>
</div>
