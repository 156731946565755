<div class="loading-dots loading-dots--lg" *ngIf="isLoading">
  <div class="loading-dots__item loading-dots__item--1"></div>
  <div class="loading-dots__item loading-dots__item--2"></div>
  <div class="loading-dots__item loading-dots__item--3"></div>
</div>
<div *ngIf="!isLoading">
  <div *ngIf="!isSubscribed">
    <h2>Want to get <i>my</i>HomeIQ monthly digest?</h2>
    <span class="subscribe__link color-primary" (click)="subscribeToDigest()">Subscribe to digest</span>
  </div>
  <div *ngIf="isSubscribed">
    <div *ngIf="!showReasonForm">
      <div class="caption-text">
        If you wish to stop receiving this report click
        <span class="subscribe__link color-primary" (click)="showReason()">Unsubscribe</span>
      </div>
    </div>
    <div class="unsubscribe-reason" *ngIf="showReasonForm">
      <h2 class="unsubscribe-reason__title">Why do you want to unsubscribe?</h2>
      <form class="unsubscribe-reason__form" [formGroup]="form">
        <div class="unsubscribe-reason__radio" *ngFor="let item of getUnsubscribeReasons(); let i=index">
          <input class="reason"
                 id="{{ 'reason' + i }}"
                 type="radio"
                 name="reason"
                 formControlName="reason"
                 [value]="UNSUBSCRIBE_REASON[item]"/>
          <label for="{{ 'reason' + i }}">{{ UNSUBSCRIBE_REASON[item] }}</label>
        </div>
        <iq-button class="block"
                   type="button"
                   color="orange"
                   label="Confirm Unsubscribe"
                   (click)="unsubscribeFromDigest()"></iq-button>
        <button class="btn-link" type="button" (click)="showReason()">Cancel</button>
      </form>
    </div>
  </div>
</div>
