import { Dictionary } from 'asap-team/asap-tools';

export const THEME: Dictionary<Dictionary<string>> = {
  home_iq: {
    'main-font-family': 'Mukta, sans-serif',
    'secondary-font-family': 'Figtree, sans-serif',
    'main-gradient': 'linear-gradient(232deg, #125dc3, #021656)',
    'primary-color': '#2681ff',
    'primary-color-60pc': 'rgb(38, 129, 255, 0.6)',
    'primary-color-30pc': 'rgb(38, 129, 255, 0.3)',
    'primary-color-20pc': 'rgb(38, 129, 255, 0.2)',
    'primary-color-10pc': 'rgb(38, 129, 255, 0.1)',
    'light-font': '#406080',
    'light-font-70pc': 'rgba(64, 96, 128, 0.7)',
    'light-font-50pc': 'rgba(64, 96, 128, 0.5)',
    'dark-font': '#0d2033',
    'legend-font': '#93a5b8',
    'header-background': '#fff',
    'element-background': '#eff4f7',
    'icon-background': '#cce1ff',
    'btn-color': '#0d2033',
    'btn-color-30pc': 'rgba(13, 32, 51, 0.3)',
    'btn-background': '#ffa926',
    'btn-background-border': '#2681ff',
    'btn-background-80pc': 'rgba(255, 169, 38, 0.8)',
    'btn-background-30pc': 'rgba(255, 169, 38, 0.3)',
  },
};

export const CHART_THEME: Dictionary<Dictionary<string>> = {
  home_iq: {
    'main-gradient-start': '#125dc3',
    'main-gradient-end': '#021656',
    'secondary-gradient-start': '#2681ff',
    'secondary-gradient-end': '#43d2ff',
  },
};
