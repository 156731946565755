import {
  Component, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import type {
  BuyAnotherHouse, Fees, Lead, RequestedPropertyType, User,
} from '@core/types';

import { GAService } from '@core/helpers/ga/ga.service';

// Consts
import { BUY_ANOTHER_HOUSE } from '@consts/consts';
import { BLOCK, GoogleEvents } from '@consts/enums';
import { PREPARED_QUESTIONS } from '@consts/prepared-questions';

// Components
import { BuyAnotherHouseInfoComponent } from './buy-another-house-info/buy-another-house-info.component';

@UntilDestroy()
@Component({
  selector: 'buy-another-house',
  templateUrl: './buy-another-house.component.html',
  styleUrls: ['./buy-another-house.component.sass'],
})
export class BuyAnotherHouseComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

  @Input() lead: Lead;

  @Input() fees: Fees;

  BUY_ANOTHER_HOUSE: any = BUY_ANOTHER_HOUSE;

  requested_property: RequestedPropertyType;

  buyAnotherHouseInfo: BuyAnotherHouse;

  preparedQuestions: string[] = PREPARED_QUESTIONS.buy_another_house;

  constructor(
    private gaService: GAService,
  ) {}

  ngOnInit(): void {
    this.changePropertyType(BUY_ANOTHER_HOUSE.PRIMARY_HOME, true);
  }

  get isLenderRequired(): boolean {
    return !!this.lead?.blocks[BLOCK.BUY_ANOTHER_HOUSE_STEPPER]?.requirements;
  }

  get user(): User {
    return this.lead?.lender ?? this.lead?.agent;
  }

  ofType(type: RequestedPropertyType): boolean {
    return this.requested_property === type;
  }

  changePropertyType(type: RequestedPropertyType, bypassSendEvent: boolean = false): void {
    if (!bypassSendEvent) {
      this.sendEvents(type);
    }

    if (type === this.requested_property) { return; }

    this.container.clear();
    this.requested_property = type;

    const component: ComponentRef<BuyAnotherHouseInfoComponent> = this.container.createComponent(BuyAnotherHouseInfoComponent);

    component.instance.lead = this.lead;
    component.instance.fees = this.fees;
    component.instance.requested_property = this.requested_property;

    component
      .instance
      .emitHouseInfo
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (data: BuyAnotherHouse) => {
          this.buyAnotherHouseInfo = data;
        },
      );
  }

  private sendEvents(type: RequestedPropertyType): void {
    switch (type) {
      case 'primary_home':
        this.gaService.trackSimpleEvent(GoogleEvents.NEW_HOME_TAB, 'affordability');
        break;
      case 'rental_property':
        this.gaService.trackSimpleEvent(GoogleEvents.INVEST_TAB, 'affordability');
        break;
      case 'secondary_home':
        this.gaService.trackSimpleEvent(GoogleEvents.SECOND_HOME_TAB, 'affordability');
        break;
      default:
        break;
    }
  }

}
