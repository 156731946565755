<div class="card">
  <div class="card__content text-center">
    <h2 class="loans__title buy-another-house__title">If you want to buy another house, how much can you afford?</h2>
    <div class="buy-another-house__row">
      <div class="buy-another-house__col buy-another-house__col--1"
           [class.active]="ofType(BUY_ANOTHER_HOUSE.PRIMARY_HOME)"
           (click)="changePropertyType(BUY_ANOTHER_HOUSE.PRIMARY_HOME)">
        <div class="buy-another-house__col--title">Trading up<br/>to a new home</div>
      </div>
      <div class="buy-another-house__col buy-another-house__col--2"
           [class.active]="ofType(BUY_ANOTHER_HOUSE.RENTAL_PROPERTY)"
           (click)="changePropertyType(BUY_ANOTHER_HOUSE.RENTAL_PROPERTY)">
        <div class="buy-another-house__col--title">Buying an<br/>investment property</div>
      </div>
      <div class="buy-another-house__col buy-another-house__col--3"
           [class.active]="ofType(BUY_ANOTHER_HOUSE.SECONDARY_HOME)"
           (click)="changePropertyType(BUY_ANOTHER_HOUSE.SECONDARY_HOME)">
        <div class="buy-another-house__col--title">Purchasing a<br/>second house</div>
      </div>
    </div>
    <ng-template #container></ng-template>
  </div>
  <send-question class="m-t-35"
                 *hideInPreview
                 [userId]="lead.id"
                 [user]="user"
                 [eventCategory]="'affordability'"
                 [preparedQuestions]="preparedQuestions"
                 title="buy_another_house">
    <buyer-qualification-certificate *ngIf="!isLenderRequired"
                                     [lead]="lead"
                                     [houseInfo]="buyAnotherHouseInfo"></buyer-qualification-certificate>
  </send-question>
</div>
