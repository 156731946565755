import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import type { Lead } from '@core/types';

// Consts
import { ROUTE } from '@consts/routes';
import { QUERY_PARAM } from '@consts/consts';
import { COMMON_TOAST } from '@consts/enums';

// Services
import { LeadService } from '@core/services/lead/lead.service';

@UntilDestroy()
@Component({
  templateUrl: './confirm-homeowner-email.component.html',
  styleUrls: ['./confirm-homeowner-email.component.sass'],
})
export class ConfirmHomeownerEmailComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    if (
      !this.route.snapshot.queryParamMap.has(QUERY_PARAM.EMAIL)
      && !this.route.snapshot.queryParamMap.has(QUERY_PARAM.TOKEN)
    ) {
      this.router.navigate([ROUTE.alias.NOT_FOUND]);
    }

    this
      .leadService
      .confirmHomeownerEmail(
        this.route.snapshot.queryParamMap.get(QUERY_PARAM.EMAIL),
        this.route.snapshot.queryParamMap.get(QUERY_PARAM.TOKEN),
      )
      .pipe(
        untilDestroyed(this),
      )
      .subscribe(
        (lead: Lead) => {
          if (!lead) {
            this.router.navigate([ROUTE.alias.NOT_FOUND], { state: { errorMessage: COMMON_TOAST.LINK_EXPIRED } });
          }

          this.toastr.success(COMMON_TOAST.EMAIL_CONFIRMED);
          this.router.navigate([`/${lead.id}`]);
        },
        () => {
          this.router.navigate([ROUTE.alias.NOT_FOUND], { state: { errorMessage: COMMON_TOAST.LINK_EXPIRED } });
        },
      );
  }

}
